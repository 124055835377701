var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[(_vm.lan)?[_c('img',{staticClass:"footer-logo",attrs:{"src":require("../../../public/static/img/logo.png"),"alt":""}}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"ip-come",on:{"click":_vm.detailsRight}},[_vm._v(" 系统入口 ")]),_c('div',{staticClass:"come-top",class:{
        'come-top-show': _vm.showBtnA,
        'come-top-hidden': _vm.hiddenBtn,
      },on:{"click":_vm.comeTop}}),(_vm.detailShow)?_c('div',{staticClass:"details-bg"},[_c('div',{staticClass:"details-box"},[_vm._m(2),_c('img',{staticClass:"icon-close",attrs:{"src":require("../../../public/static/img/icon-close.png"),"alt":""},on:{"click":_vm.detailsClose}})])]):_vm._e(),_c('div',{staticClass:"sidebar"},[_c('img',{staticClass:"sidebar-img1",attrs:{"src":require("../../../public/static/img/边栏-在线报名.png"),"alt":""},on:{"click":function($event){return _vm.comeMyself(`/information/apply`)}}}),_c('img',{staticClass:"sidebar-img2",attrs:{"src":require("../../../public/static/img/边栏-实时资讯.png"),"alt":""},on:{"click":function($event){return _vm.comeMyself(`/information/message`)}}})])]:[_c('img',{staticClass:"footer-logo",attrs:{"src":require("../../../public/static/img/logo.png"),"alt":""}}),_vm._m(3),_vm._m(4),_c('div',{staticClass:"ip-come en",on:{"click":_vm.detailsRight}},[_vm._v(" System Portal ")]),_c('div',{staticClass:"come-top",class:{
        'come-top-show': _vm.showBtnA,
        'come-top-hidden': _vm.hiddenBtn,
      },on:{"click":_vm.comeTop}}),(_vm.detailShow)?_c('div',{staticClass:"details-bg"},[_c('div',{staticClass:"details-box-en"},[_vm._m(5),_c('img',{staticClass:"icon-close",attrs:{"src":require("../../../public/static/img/icon-close.png"),"alt":""},on:{"click":_vm.detailsClose}})])]):_vm._e(),_c('div',{staticClass:"sidebar"},[_c('img',{staticClass:"sidebar-img1",attrs:{"src":require("../../../public/static/img/reference.png"),"alt":""},on:{"click":function($event){return _vm.comeMyself(`/information/apply`)}}}),_c('img',{staticClass:"sidebar-img2",attrs:{"src":require("../../../public/static/img/online.png"),"alt":""},on:{"click":function($event){return _vm.comeMyself(`/information/message`)}}})])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-address"},[_c('p',[_vm._v("网站版权归辰美所有")]),_c('p',[_vm._v("广东省佛山市南海区三山新城疏港路26号辰美艺术学校")]),_c('p',{staticClass:"footer-txt"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("备案号：粤ICP备19030818号")])]),_c('p',{staticClass:"footer-phone"},[_vm._v(" 联系电话：0757-86796295 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-icon"},[_c('div',{staticClass:"footer-wx"},[_c('img',{staticClass:"wx-img",attrs:{"src":require("../../../public/static/img/footer-wx.png"),"alt":""}}),_c('p',[_vm._v("关注我们")]),_c('img',{staticClass:"wx-code",attrs:{"src":require("../../../public/static/img/footer-code.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"oa-box"},[_c('div',{staticClass:"oa"},[_c('a',{attrs:{"href":"https://sisa.managebac.cn/login","target":"_blank"}},[_c('img',{attrs:{"src":"https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg","alt":""}}),_c('p',[_vm._v("ManageBac")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-address"},[_c('p',[_vm._v("© Stellart International School of Arts (SISA) 2023. All rights reserved")]),_c('p',[_vm._v("SISA, No.26 Shugang Road, Guicheng,Nanhai District, Foshan City, Guangdong Province")]),_c('p',{staticClass:"footer-txt"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("粤ICP备19030818号")])]),_c('p',{staticClass:"footer-phone"},[_vm._v("Phone :0757-86796295")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-icon"},[_c('div',{staticClass:"footer-wx"},[_c('img',{staticClass:"wx-img",attrs:{"src":require("../../../public/static/img/footer-wx.png"),"alt":""}}),_c('p',[_vm._v("Follow Us")]),_c('img',{staticClass:"wx-code",attrs:{"src":require("../../../public/static/img/footer-code.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"oa-box"},[_c('div',{staticClass:"oa"},[_c('a',{attrs:{"href":"https://sisa.managebac.cn/login","target":"_blank"}},[_c('img',{attrs:{"src":"https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg","alt":""}}),_c('p',[_vm._v("ManageBac")])])])])
}]

export { render, staticRenderFns }