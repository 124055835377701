<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/planningbg.c0f4821.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<p class="title50">{{lan?'国外音乐名校简介':'Iversea music colleges'}} </p>
						<p class="title50 bottom46">Introduction of Overseas School</p>
						<!-- <p class="text18R">对于全世界的商科院校而言，目前国际上存在三大权威认证，分别是国际商学院协会AACSB、欧洲管理发展基金会EFMD和工商管理硕士协会AMBA。通过认证就意味着学校项目适时更新，符合商界需求，也意味着优秀的师资力量和学位的质量保证。</p>
						<p class="title30 title-lineS bottom56">英国23家获得“三大权威认证”的商学院（排名不分先后）</p> -->
						<div class="flex" v-if="false">
							<p class="text18R flex1">
								
								1.伦敦商学院
								<br>
								
								2.伦敦城市大学卡斯商学院
								<br>
								
								3.帝国理工大学商学院
								<br>
								
								4.华威大学商学院
								<br>
								
								5.克兰菲尔德大学管理学院
								<br>
								
								6.思克莱德大学商学院
								<br>
								
								7.阿什里奇商学院
								<br>
								
								8.阿斯顿大学商学院
								<br>
								
								9.伯明翰大学商学院
								<br>
								
								10.布拉福德大学管理学院
								<br>
								
								11.杜伦大学商学院
								<br>
								
								12.爱丁堡大学商学院
								<br>
							</p>
							<p class="text18R flex1">

								13.格拉斯哥大学亚当斯密商学院
								<br>
								
								14.雷丁大学亨利商学院
								<br>
								
								15.兰卡斯特大学管理学院
								<br>
								
								16.拉夫堡大学商学院
								<br>
								
								17.曼彻斯特大学商学院
								<br>
								
								18.利兹大学商学院
								<br>
								
								19.谢菲尔德大学管理学院
								<br>
								
								20.赫尔大学商学院
								<br>

								21.巴斯大学管理学院
								<br>
								
								22.欧洲商学院（伦敦分校）
								<br>
								
								23.纽卡斯尔大学商学院
								<br>

							</p>
						</div>

						<p class="title30 title-lineS bottom56">{{lan?'国外音乐名校介绍':'Introduction of overseas music schools'}}</p>
						<div class="item-box flex">
							<img src="../../../public/static/img/foreignCommercial-img1.png" class="img1" alt="">
							<div class="item-right">
								<p class="title22">{{lan?'1.美国印第安纳大学雅各布音乐学院':'(1) Jacob School of Music, Indiana University, USA'}}</p>
								<p class="text18R">{{lan?'在美国综合类院校音乐系排行榜中位列第一，是世界上最著名的音乐学府之一。坐落在旗舰级校区布鲁明顿（Bloomington）校区，设有本科院与研究生院。超过1600名学生就读于此，学院很多专业被U.S. News Online（美国大学查询网站）评为第1位, 学生们亦曾获得多项殊荣，其声望在美国本土甚至可以和大家熟知的新英格兰音乐学院、伯克利音乐学院比肩。':`It ranks first in the ranking of music departments of American comprehensive colleges and universities, and is one of the most famous music institutions in the world. Located on the flagship Bloomington campus, the campus houses the undergraduate and graduate schools. More than 1,600 students study here, many of the college's majors are ranked first by U.S. News Online The students have won a number of awards, and its reputation in the United States can e be familiar with the New England Conservatory of Music, Berklee College of Music.`}}</p>
							</div>
						</div>
						<div class="item-box flex">
							<img src="../../../public/static/img/foreignCommercial-img2.png" class="img1" alt="">
							<div class="item-right">
								<p class="title22">{{lan?'2.瑞士日内瓦高等音乐学院':`(2) Ecole Conservatoire Superieure de Geneva, Switzerland`}}</p>
								<p class="text18R">{{lan?'瑞士日内瓦高等音乐学院是我国教育部认可的瑞士高等艺术院校。作为欧洲建校历史最悠久的学院之一，该校创立于1835年，前身为日内瓦音乐学院，后改名为日内瓦高等国立音乐学院。所属地区为瑞士日内瓦法语区，著名音乐家李斯特、利帕蒂是在此任教的最著名代表。瑞士西部高等专业学院共与国外大学签署了100多项合作协议，这些专门的教育交流计划可实现师生的国际性流动。':`Ecole Conservatoire de Geneva, Switzerland is a Swiss art school recognized by the Ministry of Education of our country. As one of the oldest colleges in Europe, the school was founded in 1835 as the Geneva Conservatory, which was later renamed the Geneva National Conservatory of Music. The area belongs to the French-speaking area of Geneva, Switzerland, and the famous musicians Liszt and Lipati are the most famous representatives who teach here. In total, more than 100 cooperation agreements have been signed with oversea universities, and these specialized educational exchange programs enable the international mobility of teachers and students.`}}</p>
							</div>
						</div>
						<div class="item-box flex">
							<img src="../../../public/static/img/foreignCommercial-img3.png" class="img1" alt="">
							<div class="item-right">
								<p class="title22">{{lan?'3.美国伯克利音乐学院':`(3) Berklee College of Music`}}</p>
								<p class="text18R">{{lan?'美国伯克利音乐学院是一所美国顶尖的现代音乐学院，是全世界规模最大且最顶尖的独立现代音乐学院。伯克利音乐学院为美国一流的现代音乐学院，培养了众多格莱美获奖人才。伯克利音乐学院不仅是音乐天才的摇篮，也是全世界音乐家的圣地。在其学生、教师和校友中，有283位获格莱美奖，89位获拉丁格莱美奖，5位获奥斯卡奖，19位获艾美奖。':`Berklee College of Music is a top modern music college in the United States, and the largest and top independent modern music college in the world. Berklee College of Music is the leading modern music college in the United States and has trained many Grammy award-winning talents. Berklee College of Music is not only a cradle of musical talent, but also a mecca for musicians from all over the world. Among its students, faculty, and alumni, 283 have won Grammy Awards, 89 have won Latin Grammy Awards, 5 have won Oscars, and 19 have won Emmy Awards.`}}</p>
							</div>
						</div>
						<div class="item-box flex">
							<img src="../../../public/static/img/foreignCommercial-img4.png" class="img1" alt="">
							<div class="item-right">
								<p class="title22">{{lan?'4.美国洛杉矶音乐学院':`(4) Los Angeles Conservatory of Music`}}</p>
								<p class="text18R">{{lan?'洛杉矶音乐学院（LACM）曾获得美国音乐学院协会National Association of Schools of Music（NASM）认证，并得到了TheBestSchools.org的认可，获得了“美国20大音乐学校之一”的称号。洛杉矶音乐学院提供了音乐表演、音乐制作（音乐工程与录音、视觉多媒体配乐），作曲（歌曲写作）以及音乐商务专业的本科学士学位、副学士学位及文凭证书等专业课程。':`The Los Angeles School of Music (LACM) has been accredited by the National Association of Schools of Music (NASM) and is recognized by TheBestSchools.org as "One of the Top 20 Music Schools in America." The Los Angeles Conservatory of Music offers programs in music performance, music production (music engineering and recording, visual multimedia scoring), composition (song writing), and music business bachelor's degrees, associate's degrees, and diploma certificates.`}}</p>
							</div>
						</div>
						<div class="item-box flex">
							<img src="../../../public/static/img/foreignCommercial-img5.png" class="img1" alt="">
							<div class="item-right">
								<p class="title22">{{lan?'5.英国皇家音乐学院':`(5) Royal Academy of Music`}}</p>
								<p class="text18R">{{lan?'英国皇家音乐学院（伦敦），英文名称“Royal College of Music”（简称RCM）是一所有悠久历史的著名音乐学院。拥有来自全球60多个国家的800个本科生和研究生在这个令人兴奋的环境里学习，他们很多毕业后成为了未来的优秀演奏家、指挥家和作曲家。每年，RCM都会迎来当今国际乐坛最为活跃的音乐家们：世界闻名的抒情女高音Kiri Te Kanawa基莉·特·卡纳瓦，钢琴巨星郎朗，指挥大师Bernard Haitink博纳德·海廷克都会定期回到RCM与学生们进行交流。':`The Royal College of Music (London) is a famous music college with a long history. 800 undergraduate and graduate students from more than 60 countries study in this exciting environment, and many of them graduate to become outstanding performers, conductors and composers of the future. Every year, RCM welcomes some of the most active musicians in international music today: world-renowned lyric soprano Kiri Te Kanawa, piano superstar Lang Lang, conductor Bernard Haitink regularly return to RCM to communicate with students.`}}</p>
							</div>
						</div>
						<div class="item-box flex">
							<img src="../../../public/static/img/foreignCommercial-img6.png" class="img1" alt="">
							<div class="item-right">
								<p class="title22">{{lan?'6.英国皇家北方音乐学院':`(6) Royal Northern College of Music, UK`}}</p>
								<p class="text18R">{{lan?'英国北方皇家音乐学院（简称RNCM）是一所世界领先的音乐学院，坐落在英国曼彻斯特市中心，是英国皇家音乐学院联合委员会ABRSM成员之一。多年来，RNCM都是英国以及世界各地艺术家首选的表演场地和音乐学府。2013年，由英国学联组织学生选举其为英国最受学生青睐的音乐学院，2014年由英国政府REF评选其为英国最好的音乐学院，学校的科研成果达到英国第一，世界领先水准。':`The Royal Northern College of Music (RNCM) is a world-leading music college located in the heart of Manchester, United Kingdom, and is a member of ABRSM, the Joint Board of the Royal Schools of Music. For many years, the RNCM has been the performance venue and music institution of choice for artists in the UK and around the world. In 2013, it was elected by the students of the British Federation of Students as the most favored music college in the UK, and in 2014 it was selected by the British government REF as the best music college in the UK. The research achievements of the school reached the first in the UK and the world's leading level.`}}</p>
							</div>
						</div>
						<div class="item-box flex">
							<img src="../../../public/static/img/foreignCommercial-img7.png" class="img1" alt="">
							<div class="item-right">
								<p class="title22">{{lan?'7.苏格兰皇家音乐学院':`(7) Royal Scottish Academy of Music`}}</p>
								<p class="text18R">{{lan?'苏格兰皇家音乐学院不仅是英国皇家音乐学院联合委员会的成员，而且已经成为苏格兰的国际音乐戏剧学术中心，在国际上享有极高的声誉，一直是无数爱好音乐和戏剧的学子梦寐以求的圣地。其音乐系被Guardian Newspaper评为音乐教育第一，戏剧系被评为第五。音乐系毕业生就业前景名列第一，戏剧系与Bristol并列第二。':`The Royal Scottish Academy of Music is not only a member of the Joint Committee of the Royal Colleges of Music, but also has become Scotland's international academic center of music and drama. It enjoys a high international reputation, and has been a dream school for countless students who love music and drama. Its music department was ranked number one in music education by the Guardian Newspaper, and its drama department was ranked number five. The Department of Music graduates ranked first for job prospects, while the department of Drama shared second place with Bristol.`}}</p>
							</div>
						</div>
						<div class="item-box flex">
							<img src="../../../public/static/img/foreignCommercial-img8.png" class="img1" alt="">
							<div class="item-right">
								<p class="title22">{{lan?'8.英国伦敦市政厅音乐戏剧学院':`(8) Guildhall School of Music and Drama, London, UK`}}</p>
								<p class="text18R">{{lan?'英国市政厅音乐及戏剧学院成立于1880年，坐落在伦敦的心脏地带的巴比肯艺术中心（Barbican Centre），被英国2013及2014年《卫报》大学指南（Guardian University Guide 2013、2014）评为第一专业机构。市政厅音乐及戏剧学院在所有学科的教学和研究方向有着举足轻重的的影响力。毕业生持续在各自专业领域中获得最高水平的成就。':`The Guildhall School of Music and Drama was founded in 1880 and is located in the Barbican Centre in the heart of London. Ranked as the number one professional institution in the UK by the Guardian University Guide 2013 and 2014. The Guildhall School of Music and Drama has a significant influence on teaching and research in all disciplines. Graduates continue to achieve the highest levels of achievement in their respective fields of expertise.`}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'foreignCommercial',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				tip:4,
				tipNumber:2,
        lan: 1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
    created(){
      const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
        // 英文版
				this.lan = 0
				this.tipNumber = 1
			}else if(language == 'en'&&newLanguage=='cn'){
        // 中文版
				this.lan = 1
				this.tipNumber = 2
			}
    },
		mounted() {
      const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
        // 英文版
				this.lan = 0
				this.tipNumber = 2
			}else if(language == 'en'&&newLanguage=='cn'){
        // 中文版
				this.lan = 1
				this.tipNumber = 3
			}
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeIndex(){
				this.$router.push("/");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .87rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.title50 {
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .22rem;
	}
	.bottom46 {
		margin-bottom: .46rem;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R {
		font-size: .18rem;
		text-align: justify;
	}
	.bottom56 {
		margin-bottom: .56rem;
	}
	.title-lineS {
		border-bottom: 1px solid #0e183f;
		padding-bottom: .06rem;
		display: inline-block;
	}
	.title30, .title30M {
		font-size: .3rem;
		line-height: .3rem;
		margin-top: 1rem;
	}
	.title30, .title40 {
		font-weight: 700;
		color: #0e183f;
	}
	.flex {
	  display: flex;
	}
	.flex1, .item-right {
	    flex: 1;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R {
		font-size: .18rem;
		text-align: justify;
	}
	.item-box{
	    margin-bottom: .59rem;
	}
	.img1 {
	    width: 3.29rem;
	    height: 2.6rem;
	    margin-right: .15rem;
	}
	.title22 {
		font-size: .22rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .32rem;
	}
</style>
