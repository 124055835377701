<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<!-- <div class="header-lan" style="cursor: pointer;">
						<span  @click="comeMyself(`/indexCN`)">•&nbsp;中文</span>&nbsp;/&nbsp;<span class="active">ENG</span>
						<span  @click="changeLanguage()">•&nbsp;中文</span>&nbsp;/&nbsp;<span class="active">ENG</span>
					</div> -->
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="middle">
			<div class="banner w">
				<div class="swiper mySwiper">
				      <div class="swiper-wrapper">
				       <div v-for="(item,i) in indexList" :key="i" @click="comeMyself(item.attributes.link)" class="swiper-slide">
									<a ><img :src="item.attributes.image.data!=null ? BaseURL+item.attributes.image.data.attributes.url : '' " alt=""></a>
								</div>
				        <!-- <div @click="comeMyself(`/about/presidentWords`)" class="swiper-slide">
									<a href=""><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/300dea5ebb422628d25c9fb6739d0d4a.jpg" alt=""></a>
								</div>
								<div @click="comeMyself(`/about/presidentWords`)" class="swiper-slide">
									<a href=""><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/300dea5ebb422628d25c9fb6739d0d4a.jpg" alt=""></a>
								</div>
								<div @click="comeMyself(`/about/presidentWords`)" class="swiper-slide">
									<a href=""><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/300dea5ebb422628d25c9fb6739d0d4a.jpg" alt=""></a>
								</div> -->
				        <!-- <div @click="comeMyself(`/about/presidentWords`)" class="swiper-slide">
									<a href=""><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/d41d641e9eb77bb5c3ebd856087eadd2.jpg" alt=""></a>
								</div>
								<div @click="comeMyself(`/about/presidentWords`)" class="swiper-slide">
									<a href=""><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/d41d641e9eb77bb5c3ebd856087eadd2.jpg" alt=""></a>
								</div>
								<div @click="comeMyself(`/about/presidentWords`)" class="swiper-slide">
									<a href=""><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/d41d641e9eb77bb5c3ebd856087eadd2.jpg" alt=""></a>
								</div> -->
				      </div>
				      <div class="swiper-button-next"></div>
				      <div class="swiper-button-prev"></div>
							 <!-- <div class="swiper-pagination"></div> -->
				    </div>
					
			</div>
			<contentLan v-if="!lan"></contentLan>
			<contentCN v-if="lan"></contentCN>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import {  getIndex,getIndexCN } from "../../api/api.js"
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import contentLan from '../commponet/contentLan.vue'
	import contentCN from "../commponet/contentCN.vue"
	import foot from "../commponet/foot.vue"
	// import Swiper from "../../../node_modules/swiper/swiper-bundle.min.js"
	import Swiper from "../../api/swiper.min.js"
	// import SwiperSlide from "vue-awesome-swiper"
	export default{
		name:'index',
		data(){
			return{
			
				detailShow:false,
				showBtn:false,
				hiddenBtn:true,
				indexList:[],
				BaseURL:process.env.VUE_APP_BASEURL,
				tip:0,
				lan:0,
			}
		},
		components: {
		  headerList,
			headerLan,
			contentLan,
			contentCN,
      foot
		},
		mounted() {
		
			
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
				this.lan = 0
				this.getSwiperIndex()
			}else if(language == 'en'&&newLanguage=='cn'){
				this.lan = 1
				this.getSwiperCNIndex()
			}
			console.log(this.lan)
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			// changeLanguage(){
			// 	window.localStorage.setItem("NewLanguage",'cn')
			// 	location.reload();
			// },
			getSwiperIndex(){
				getIndex().then(res=>{
					
					this.indexList = res.data.data
					console.log(this.BaseURL+this.indexList[0].attributes.image.data.attributes.url,'dagiu')
					this.timer = setTimeout(()=>{
					  this.myswiper()
					},100);

					
				})
			},
			getSwiperCNIndex(){
				getIndexCN().then(res=>{
					
					this.indexList = res.data.data
					
					this.timer = setTimeout(()=>{
					  this.myswiper()
					},100);
			
					
				})
			},
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},	
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			myswiper(){
				var swiper = new Swiper(".mySwiper", {
				  spaceBetween: 20,
				  centeredSlides: true,
				  autoplay: {
				    delay: 2500,
				    disableOnInteraction: false,
				  },
				  pagination: {
				    el: ".swiper-pagination",
				    clickable: true,
				  },
				  navigation: {
				    nextEl: ".swiper-button-next",
				    prevEl: ".swiper-button-prev",
						
				  },      
			 });	

			},
			swiperCon(){
				var swiper = new Swiper('.swiper-container', {
				      slidesPerView: 6,
				      spaceBetween: 30,
					  // centeredSlides: true,
					  // loop: true,
				      pagination: {
				        el: '.swiper-pagination',
				        clickable: true,
				      },
							navigation: {
							  nextEl: ".ture-right-button",
							  prevEl: ".ture-left-botton",
								disabledClass: 'my-button-disabled',
							},
							on:{
								transitionEnd:()=>{
									this.active = swiper.activeIndex;
									console.log(this.active)
								}
							}
				    });
						 var lis = document.querySelectorAll(".swiper-slide");
						 var list = document.getElementsByClassName("swiper-container ")[0];						 
						 lis.forEach(item => {
						     item.onmouseover = () => {
									 var x = list.childNodes[0].childNodes[this.active];
						        //先全部清空选中，再给当前添加选中
						        lis.forEach(item => item.classList.remove('swiper-slide-active'));
										console.log(x)
										// console.log(item.classList)
						        item.classList.add('swiper-slide-active');
						     }
						 })
						 lis.forEach(item => {
						     lis.forEach(item => {
						         item.onmouseout = () => {
												var x = list.childNodes[0].childNodes[this.active];
						            //先全部清空选中，再给当前添加选中
						            lis.forEach(item => item.classList.remove('swiper-slide-active'));
												x.classList.add('swiper-slide-active');
						         }
						     })
						 })
					// swiper.progress;
					// var pe = document.getElementById('progressEffect');
					// pe.onchange = function(){ 
					//     effect = this.value
					// 	swiper.update();	
					// }
			},
			// detailsRight(){
			// 	this.detailShow = true
			// },
			// detailsClose(){
			// 	this.detailShow = false
			// },
			// showbtn() {
			// 	let that = this;
			// 	let scrollTop =
			// 			window.pageYOffset ||
			// 			document.documentElement.scrollTop ||
			// 			document.body.scrollTop;
			// 	that.scrollTop = scrollTop;
			// 	if (scrollTop > 350) {
			// 			this.showBtn = true;
			// 			this.hiddenBtn = false;
			// 	} else {
			// 			this.showBtn = false;
			// 			this.hiddenBtn = true;
			// 	}
			// },
			// comeTop(){
			// 	 var timer = setInterval(function () {
			// 	                    let osTop =
			// 	                        document.documentElement.scrollTop || document.body.scrollTop;
			// 	                    let ispeed = Math.floor(-osTop / 5);
			// 	                    document.documentElement.scrollTop = document.body.scrollTop =
			// 	                        osTop + ispeed;
			// 	                    this.isTop = true;
			// 	                    if (osTop === 0) {
			// 	                        clearInterval(timer);
			// 	                    }
			// 	                }, 30);         
			// }
			
		}
	}
</script>

<style scoped>
	@import url("../../../node_modules/swiper/swiper-bundle.css");
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}

.banner{
	width: 100%;
	height: 9.21rem;
}

	.swiper-slide-active .teacher-introduce .teacher-img img{
		padding: .15rem;
		border-radius: 50%;
		cursor: pointer;
		z-index: 10;
		border: .01rem solid #939ab1;
		/* border: 1px solid #000; */
		/* opacity: 0.2; */
		transition: 0.6s;
		margin-bottom: 30px;
		transform: scale(1.5);
		position: relative;
		z-index: 10;
	}
	.swiper {
	    width: 100%;
	    /* height: 100%; */
			overflow: hidden; 
	}
	.banner .swiper-slide {
	    text-align: center;
	    font-size: 18px;
	    background: #fff;
	    /* Center slide text vertically */
	    display: -webkit-box;
	    display: -ms-flexbox;
	/*    display: -webkit-flex;
	    display: flex; */
	    -webkit-box-pack: center;
	    -ms-flex-pack: center;
	    -webkit-justify-content: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    -webkit-align-items: center;
	    align-items: center;
			/* height: 9.21rem; */
	}
	.banner .swiper-wrapper{
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
	}
	.banner .swiper-slide img {
	    width: 100%;
			height: 100%;
			margin-top: 1.23rem;
			display: flex;
			object-fit: cover;
			height: 9.21rem;
	}


.middle{
	margin: 0;
	padding: 0;
	/* width: 100%; */
	/* height: ; */
}
.banner img{
	width: 100%;
	height: 9.21rem;
	margin-top: 1.23rem;
	display: flex;
}

	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt a{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt a:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.sidebar{
		width: 1.08rem;
		height: 2.16rem;
		position: fixed;
		right: 0;
		bottom: 2rem;
		z-index: 99;
		flex-direction: column;
		display: flex;
	}
	.sidebar img{
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
	}
	.need-line{
		top: 3rem;
		left: 0;
		-webkit-line-clamp: 11;
		letter-spacing: -.01rem;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.sidebar-img1{
		cursor: pointer;
	}
	.sidebar-img2{
		cursor: pointer;
	}

</style>
