<template>
	<div class="info">
		<div class="w info-item">
			<div class="school-introduce">学校介绍</div>
			<div class="school-img"><img src="../../../public/static/img/item01-img3.png" alt=""></div>
			<div class="school-pic"><img src="../../../public/static/img/item01-img2.jpg" alt=""></div>
			<div class="school-icon"><img src="../../../public/static/img/item01-img1.png" alt=""></div>
			<div class="school-txt">
				<!-- 辰美艺术学校（简称“辰美”）是一所具有国际化特色的民办中等艺术专业学校，由实力雄厚的全球顶级基金公司——基汇资本主办，是佛山市南海区重点招商引资教育项目。辰美的前身为“辰美国际艺术教育”，创办于2020年9月，短短三年时间已发展成为大湾区艺术教育的标杆学校。2023年3月，辰美由单一国际升学方向的国际课程教育中心升级发展为提供高中阶段学历教育且具有国内外双轨升学特色的民办全日制中等艺术学校。学校开设音乐学院、艺术与设计学院、商学院三大学院，面向广东省乃至全国招收相关专业的初中毕业生。辰美以“根植中国，融通世界”为办学初心，致力于培养具有全球胜任力的中国艺术创新人才和商业领袖。 -->
				辰美（国际）艺术学校创办于2020年9月，是湾区国际艺术教育领军学校，亦是广佛一所具有国际化特色的民办中等艺术专业学校，由实力雄厚的全球顶级基金公司基汇资本投资创办。辰美的前身为“辰美国际艺术教育”。2023年3月，辰美由国际单轨升学的国际高中升级发展为具有国内外双轨升学特色的高中阶段学历教育学校。辰美以“根植中国，融通世界”为办学初心，致力于培养具有全球胜任力的中国艺术创新人才。2024年3月28日，辰美荣膺京领2024中国国际化学校特色榜的两大重量级奖项——“艺术特色十大名校”（名列第一）和“科研特色百强校”，在中国国际化教育领域再次赢得权威认可，办学实力与国际认可度再创新高。
		 </div>
		</div>
		<div class="school-team w">
			<div class="team-title">
				<div class="title-img">
					<img src="../../../public/static/img/teacherteam.png" alt="">
				</div>
				<div class="team-txt">
					教师团队
				</div>
			</div>
			<div class="teacher">
				
					<!-- Swiper -->
					<div class="swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide ">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/5eb46a467ed1daa6e3a1538ca51a9843.png" alt="">
									</div>
									<p class="teacher-name">
										黄秋艳
									</p>
									<p class="teacher-work">
										校长/校委会主席
									</p>
									<p class="describe">
										南海区优秀校长，广东省国际教育促进会副会长，广东省国际艺术教育专业委员会主任。英国教育文化处颁发的华南地区中英校际交流金奖获得者。获得广州日报颁发的“广佛教育代言人”称号。创办多所广佛地区知名中小学和国际学校。
									</p>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/767451d11d9f78893d8b03b71bd3750a.png" alt="">
									</div>
									<p class="teacher-name">
										陈泽芳 
									</p>
									<p class="teacher-work">
										荣誉校长
									</p>
									<p class="describe">
										特级校长、全国管理创新典范校长，2018年全国最具影响力国际学校校长，广东省留学服务协会国际化学校专委会副主任，华南师范大学教师教育学部兼职教授，华南师范大学附属外国语学校创校校长。
									</p>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/4ec084b79d36782bc1058975a7d4b9c2.png" alt="">
									</div>
									<p class="teacher-name">
										Roy White
									</p>
									<p class="teacher-work">
										顾问校长
									</p>
									<p class="describe">
									国际学校理事会（CIS）委员，曾任职于英国学校基金会，中国香港著名国际学校的创校校长，有英国、荷兰、北欧等多个国家国际学校校长任职经验，CIS对他领导的每所学校的创新愿景高度赞赏。
									</p>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/8263460627f530a84488dbc5eed8deab.png" alt="">
									</div>
									<p class="teacher-name">
										张浩然
									</p>
									<p class="teacher-work">
										常务副校长
									</p>
									<p class="describe">
										北京外国语学院硕士，广东省青少年人工智能专业委员会副主任，南海区信息技术学科带头人，南海区信息技术金牌教练，佛山市首批创客导师，参与南海外国语学校等多所民办国际化学校的创办。</p>
								</div>
							</div>
							<!-- <div class="swiper-slide" v-if="false">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/159fde1da611a42af5d616daf6f6a6d9.png" alt="">
									</div>
									<p class="teacher-name">
										施德王
									</p>
									<p class="teacher-work">
										副校长、艺术顾问
									</p>
									<p class="describe">
										前广美附中国际艺术部学术校长，英国爱德思考试局认可阅卷员和审阅员。获得伦敦艺术大学切尔西学院室内空间设计研究生学位，曾共同设计伦敦艺术大学在华研究生预备课程，有9年国际艺术教育管理经验，包括艺术预科及艺术高中经验，多年来培养超过1000名学生升读海外艺术名校。
									</p>
								</div>
							</div> -->
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/00f058c64ff277f7f6a0b8a5113f684a.png" alt="">
									</div>
									<p class="teacher-name">
										李雅靖
									</p>
									<p class="teacher-work">
										资源拓展总监
									</p>
									<p class="describe">
										（毕业于美国加州大学圣地亚哥分校，获得管理科学学士（拉丁荣誉毕业）、商学和心理学双副学士、康奈尔大学市场战略证书，曾就职于美国咨询行业和香港风投行业，有英国、美国、中国香港多所大学的丰富教育资源。</p>
								</div>
							</div>
							<!-- <div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220801/0b7a97e239bb433f2b33839ccda82d14.jpg" alt="">
									</div>
									<p class="teacher-name">
										Martin Bills
									</p>
									<p class="teacher-work">
										音乐学院院长
									</p>
									<p class="describe">
										英国索尔福德大学音乐学士和哈德斯菲尔德大学作曲硕士。不仅是屡获殊荣的词曲作者和作曲家，还是一位获奖艺术家。专业作品包括为剧院、电影和当代舞蹈团体创作的音乐作品。
									</p>
								</div>
							</div> -->
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20210719/fc1300635e31c3b9dc6af82d08f5d65b.png" alt="">
									</div>
									<p class="teacher-name">
										杨熙
									</p>
									<p class="teacher-work">
										教研处主任/商科总监
									</p>
									<p class="describe">
										毕业于澳大利亚科廷大学和香港大学，研究生学历，主修会计和金融。澳大利亚注册会计师，曾就职国际金融服务机构。加入辰美前，在中国和澳洲高校任教并担任课程内审员及教学主管副院长，持有澳洲高等教育教师资格、国际文凭组织颁发的国际文凭教师证书、剑桥大学考试委员会英语教学能力证书。
									</p>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/8285f45fed7b8da89f06044dc00c20fe.png" alt="">
									</div>
									<p class="teacher-name">
										Brian Reverman
									</p>
									<p class="teacher-work">
										艺术顾问
									</p>
									<p class="describe">
										美国戴顿大学艺术学士，俄亥俄州立大学艺术硕士，拥有30多年经验的艺术家和教育家，“理想主义艺术教师实用指南”系列丛书作者YouTube上ISB艺术史频道的制片人，Inspire Citizens视觉艺术顾问，亚洲地区艺术教育家协会(亚洲地区艺术教师联盟)管理者。</p>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/9da91f58cd585a177ce895edadd68b79.png" alt="">
									</div>
									<p class="teacher-name">
										Colin Kay
									</p>
									<p class="teacher-work">
										教育顾问
									</p>
									<p class="describe">
										毕业于剑桥大学，拥有国际教学领导专业MBA学位，英国皇家艺术学会的成员，曾担任多年英国高中校长，现任布里斯托学院信托基金会教育顾问、巴斯大学教育领导专业研究生课程的副讲师。</p>
								</div>
							</div>
				
						</div>
						
						<div class="ture-left-botton"></div>
						<div class="ture-right-button"></div>
					</div>	
			</div>
			<img class="more-img" src="../../../public/static/img/more-black.png" @click="comeMyself(`/about/architectureAndTeam`)" alt="">
		</div>	
		<div class="object w">
			<div class="object-box">
				<img class="object-icon" src="../../../public/static/img/item03-img1.png" alt="">
				<img class="object-title-icon" src="../../../public/static/img/item03-img3.png" alt="">
				<img class="object-teacher-img" src="../../../public/static/img/item03-img2.jpg" alt="">
				<div class="object-title">
					课程介绍
				</div>
				<div class="object-content">
					
辰美丰富多元的课程体系、小班制个性化的教学支持，保障学生全面发展、高质量成长的同时，为学生提供多元的升学路径，辰美学子既可以参加普通高考进入国内211、985、双一流大学、音乐艺术专业院校，也可以参加高职高考3+x实现对口单招进入艺术、音乐专业院校或高职院校，更可以凭借受国际认证的校内成绩与作品集、语言成绩申请美国、英国、澳大利亚、日本、东南亚等国家和地区的艺术名校。   
				</div>
			</div>
			<div class="school-object-list" v-if="false">
				<div class="sisa-object" @click="comeMyself(`/teaching/sixCollegesList`)">
					<img class="sisa-img" src="../../../public/static/img/item03-img4.png" alt="">
					<div class="sisa">
						<div class="sisa-title">
							Art and Design
						</div>
						<div class="sisa-content">艺术学院</div>
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
				<div class="sisa-object" @click="comeMyself(`/teaching/commercial`)">
					<img class="sisa-img" src="../../../public/static/img/item03-img5.png" alt="">
					<div class="sisa">
						<div class="sisa-title">Music Performance</div>
						<div class="sisa-content">商学院</div>
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
				<div class="sisa-object" @click="comeMyself(`/planning/service`)">
					<img class="sisa-img" src="../../../public/static/img/item03-img6.png" alt="">
					<div class="sisa">
						<div class="sisa-title">UNIVERSITY COUNSELING</div>
						<div class="sisa-content">升学</div>
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
			</div>
			<div class="object-more">
				<img class="object-more-img" src="../../../public/static/img/more-white.png" @click="comeMyself(`/teaching/sixColleges`)" alt="">
			</div>
		</div>
		<div class="school-style w">
			<div class="school-style-box">
				<img class="style-img" src="../../../public/static/img/item04-img2.png" alt="">
				<img class="style-title-icon" src="../../../public/static/img/item04-img3.png" alt="">
				<img class="style-icon" src="../../../public/static/img/item04-img1.png" alt="">
				<div class="school-style-title">校园风采</div>
				<div class="school-style-txt need-line">
		 辰美拥有集开放性、多元化、艺术感为一体的校园环境，完善的专业场室、运动设施、生活配套，使辰美成为学子们学习和成长的梦想校园。学校配备了平面设计工作坊、3D设计工作坊、服装设计工作坊、雕塑大师工作坊等在内的三十几间艺术设计与制作专业教学场室，配备了录音室、音乐制作室、乐器练习室和两个大型音乐表演厅在内的三十几间音乐表演专业教学场室，基汇资本遍及全球的企业为辰美学子提供实习实践基地，辰美给学生创设了一个完美的艺术培养和实践基地。          
				</div>
			</div>
			<div class="school-object-list">
				<div class="sisa-object" @click="comeMyself(`/information/rules`)">
					<img class="sisa-img" src="../../../public/static/img/item03-img4.png" alt="">
					<div class="sisa">
						<div class="sisa-title">
							Admissions and Prospect
						</div>
						<div class="sisa-content">招生简章</div>
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
				<div class="sisa-object" @click="comeMyself(`/life/campusNews`)">
					<img class="sisa-img" src="../../../public/static/img/item03-img5.png" alt="">
					<div class="sisa">
						<div class="sisa-title">News</div>
						<div class="sisa-content">校园动态</div>
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
				<div class="sisa-object" @click="comeMyself(`/life/TVstation`)">
					<img class="sisa-img" src="../../../public/static/img/item03-img6.png" alt="">
					<div class="sisa">
						<div class="sisa-title">Videos</div>
						<div class="sisa-content">原创视频</div>
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
			</div>
			<div class="object-more" @click="comeMyself(`/life/campusNews`)">
				<img class="object-more-img" src="../../../public/static/img/more-white.png" alt="">
			</div>
		</div>
		<div class="greet-link">
			<p class="greet-title">辰美欢迎您</p>
			<img class="greet-title-icon" src="../../../public/static/img/item05-img1.png" alt="">
			<p class="greet-txt">
				
	 辰美只等你来！点击下方链接
	 <br />了解关于辰美的招生介绍、入学方式、报名等资讯艺术
 
			</p>
			<img class="greet-message" src="../../../public/static/img/icon-more2G.png" @click="comeMyself(`/information/rules`)" alt="">
			<div class="greet-my">
				<img src="../../../public/static/img/icon-more3G.png" @click="comeMyself(`/contact/Consultation`)" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from "../../api/swiper.min.js"
	export default{
		name:'contentCN',
		data(){
			return{
				active:0,
			}
		},
		mounted() {
			this.swiperCon()
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},	
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			swiperCon(){
				var swiper = new Swiper('.swiper-container', {
				      slidesPerView: 6,
				      spaceBetween: 30,
					  // centeredSlides: true,
					  // loop: true,
				      pagination: {
				        el: '.swiper-pagination',
				        clickable: true,
				      },
							navigation: {
							  nextEl: ".ture-right-button",
							  prevEl: ".ture-left-botton",
								disabledClass: 'my-button-disabled',
							},
							on:{
								transitionEnd:()=>{
									this.active = swiper.activeIndex;
									console.log(this.active)
								}
							}
				    });
						 var lis = document.querySelectorAll(".swiper-slide");
						 var list = document.getElementsByClassName("swiper-container ")[0];						 
						 lis.forEach(item => {
						     item.onmouseover = () => {
									 var x = list.childNodes[0].childNodes[this.active];
						        //先全部清空选中，再给当前添加选中
						        lis.forEach(item => item.classList.remove('swiper-slide-active'));
										console.log(x)
										// console.log(item.classList)
						        item.classList.add('swiper-slide-active');
						     }
						 })
						 lis.forEach(item => {
						     lis.forEach(item => {
						         item.onmouseout = () => {
												var x = list.childNodes[0].childNodes[this.active];
						            //先全部清空选中，再给当前添加选中
						            lis.forEach(item => item.classList.remove('swiper-slide-active'));
												x.classList.add('swiper-slide-active');
						         }
						     })
						 })
					// swiper.progress;
					// var pe = document.getElementById('progressEffect');
					// pe.onchange = function(){ 
					//     effect = this.value
					// 	swiper.update();	
					// }
			},
		}
	}
</script>

<style>
	
	.w{
		width: 14.41rem;
		margin: 0 auto;
	}
	.info{
		width: 100%;
		height: auto;
	}
	.info-item{
		position: relative;
		width: 14.41rem;
		height: 6.22rem;
		background-color: white;
		margin-top: 2.5rem;
		margin-bottom: 1.27rem;
	}
	.school-introduce{
		position: absolute;
		left: 0;
		font-weight: 400;
		color: #3e3a39;
		top: .02rem;
		font-size: .4rem;
		line-height: .4rem;
	}
	.school-img{
		position: absolute;
		width: 5.08rem;
		position: absolute;
		left: .02rem;
		top: .58rem;
		z-index: 2;
	}
	.school-pic{
		width: 10.15rem;
		height: 5.7rem;
		position: absolute;
		right: 0;
		bottom: .52rem;
		z-index: 1;
	}
	.school-img img{
		width: 100%;
		height: 100%;
	}
	.school-pic img{
		width: 100%;
		height: 100%;
	}
	.school-txt{
		position: absolute;
		width: 3.6rem;
		font-size: .16rem;
		line-height: .28rem;
		letter-spacing: -.01rem;
		text-align: justify;
		top: 3.05rem;
		left: 0;
		font-weight: 400;
		color: #3e3a39;
	}
	.school-icon{
		width: 1.05rem;
		height: auto;
		position: absolute;
		right: -.52rem;
		bottom: 0;
		z-index: 2;
	}
	.team-title{
		width: 100%;
		margin-bottom: .35rem;
	}
	.school-team{
		height: 8.66rem;
		margin-bottom: 4.38rem;
		position: relative;
	}
	.title-img img{
		display: block;
		margin: 0 auto;
		width: 3.68rem;
	
	}
	.team-txt{
		font-size: .4rem;
		padding: 0;
		margin-top: .1rem;
		text-align: center;
	}
	.teacher{
		margin: 0 .34rem;
		padding: .57rem .41rem 0;
		overflow: hidden;
	}
	.teacher-introduce{
		width: 100%;
		height: 100%;
	}
	.teacher-name{
		font-size: .16rem;
		font-weight: 700;
		padding: 0;
		color: #1f2c5c;
		opacity: 1;
	}
	.teacher-work{
		font-size: .16rem;
		font-weight: 700;
		padding: 0;
		color: #1f2c5c;
		opacity: 1;
	}
	.teacher .describe{
		font-size: .14rem;
		line-height: .24rem;
		width: 2.44rem;
		margin: .06rem -.3rem;
		height: auto;
		font-weight: 400;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 11;
		-webkit-box-orient: vertical;
		text-align: justify;
		opacity: 0;
		transition: all .4s;
		-webkit-transition: all .4s;
		border-top: 1px solid #bab9b9;
		border-bottom: 1px solid #bab9b9;
		padding: 2px 0;
	}
	/* .teacher-introduce:hover .describe{
		opacity: 1;
	} */
	/* .teacher-introduce:hover .teacher-img img{
		padding: .15rem;
		border-radius: 50%;
		cursor: pointer;
		z-index: 10;
		border: .01rem solid #939ab1;
		transition: 1s;
		margin-bottom: 30px;
		transform: scale(1.5);
	} */
	.ture-right-button{
		    background: url(../../../public/static/img/icon-right.png) no-repeat top/100% auto;
		    width: .39rem;
		    height: 1.14rem;
		    position: absolute;
		    top: 2.05rem;
		    right: -.1rem;
	}
	.teacher .ture-left-botton{
		background: url(../../../public/static/img/icon-left.png) no-repeat top/100% auto;
		    width: .39rem;
		    height: 1.14rem;
		    position: absolute;
		    top: 2.05rem;
		    left: -.1rem;
	
	}
	.swiper {
	    width: 100%;
	    /* height: 100%; */
			overflow: hidden; 
	}
	.banner .swiper-slide {
	    text-align: center;
	    font-size: 18px;
	    background: #fff;
	    /* Center slide text vertically */
	    display: -webkit-box;
	    display: -ms-flexbox;
	/*    display: -webkit-flex;
	    display: flex; */
	    -webkit-box-pack: center;
	    -ms-flex-pack: center;
	    -webkit-justify-content: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    -webkit-align-items: center;
	    align-items: center;
	}
	.banner .swiper-wrapper{
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
	}
	.banner .swiper-slide img {
	    width: 100%;
	        height: 9.21rem;
	        margin-top: 1.23rem;
	        display: flex;
	}
	    .swiper-container {
	      width: 100%;
	      height: auto;
				/* padding:90px 0; */
	      margin-left: auto;
	      margin-right: auto;
				/* overflow: hidden; */
	    }
	  .swiper-container  .swiper-slide {
	      text-align: center;
	      font-size: 18px;
	      background: #fff;
	/* 			width: 121.975px !important;
				margin-right: 42.83px !important; */
	      /* Center slide text vertically */
	       display: -webkit-box;
	            display: -ms-flexbox;
	            display: -webkit-flex;
	            display: flex;
	            -webkit-box-pack: center;
	            -ms-flex-pack: center;
	            -webkit-justify-content: center;
	            justify-content: center;
	            -webkit-box-align: center;
	            -ms-flex-align: center;
	       /*     -webkit-align-items: center;
	            align-items: center; */
	    }
		.teacher  img{
			width: 1.33rem;
			height: 1.33rem;
			border-radius: 50%;
			/* transition: all 0.5s; */
	
			/* align-items: center; */
	
		}
		.more-img{
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 8rem;
			width: 1.22rem;
			margin: auto;
			display: block;
			cursor: pointer;
		}
		.swiper-slide-active .teacher-introduce .teacher-img img{
			padding: .15rem;
			border-radius: 50%;
			cursor: pointer;
			z-index: 10;
			border: .01rem solid #939ab1;
			/* border: 1px solid #000; */
			/* opacity: 0.2; */
			transition: 0.6s;
			margin-bottom: 30px;
			transform: scale(1.5);
			position: relative;
			z-index: 10;
		}
		.swiper-slide-active .describe{
			opacity: 1;
		}
		.my-button-disabled{
			opacity: 0;
		}
		.object-box{
			width: 14.41rem;
			height: 6.65rem;
			margin-bottom: 1.19rem;
			position: relative;
		}
		.object-icon{
			width: 1.05rem;
			height: auto;
			position: absolute;
			left: -.52rem;
			bottom: 0;
			z-index: 2;
		}
		.object-teacher-img{
			width: 9.19rem;
			height: 6.13rem;
			position: absolute;
			left: 0;
			bottom: .52rem;
			z-index: 1;
		}
		.object-title-icon{
			width: 7.22rem;
			position: absolute;
			right: .02rem;
			top: .75rem;
			z-index: 2;
		}
		.object-title{
			position: absolute;
			right: 0;
			top: .18rem;
			font-size: .4rem;
			line-height: .4rem;
			font-weight: 400;
	    color: #fff;
		}
		.object-content{
			position: absolute;
			right: 0;
			font-weight: 400;
			color: #fff;
			width: 4.6rem;
			font-size: .16rem;
			line-height: .28rem;
			text-align: justify;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			top: 3.3rem;
		}
		.sisa-object{
			cursor: pointer;
			position: relative;
		}
		.school-object-list{
			display: flex;
			justify-content: space-between;
		}
		.sisa-img{
			width: 4.59rem!important;
			height: 4.59rem!important;
			object-fit: cover;
		}
		.sisa{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			height: 1rem;
			z-index: 3;
			align-items: center;
			display: flex;
			flex-direction: column;
		}
		.sisa-title{
			font-weight: 700;
			font-size: .28rem;
			line-height: .46rem;
			border-bottom: 2px solid #fff;
		}
		.sisa-content{
			font-weight: 400;
			font-size: .2rem;
			line-height: .28rem;
			margin-top: .27rem;
		}
		.sisa-title,.sisa-content{
			color: #fff;
			transition: all .3s;
			-webkit-transition: all .3s;
		}
		.sisa-mask{
			position: absolute;
			top: 0;
			width: 4.59rem;
			height: 4.59rem;
			opacity: 0;
			background-color: rgba(0,0,0,.4);
			z-index: 2;
		}
		.sisa-object:hover .sisa-mask{
			transition: 0.6s;
			opacity: 1;
		}
		.sisa-object:hover .sisa-content{
			transition: 0.6s;
			font-size: .27rem;
		}
		.sisa-object:hover .sisa-title{
			transition: 0.6s;
			font-size: .32rem;
		}
		.object-more{
			margin-top: .89rem;
			width: 1.22rem;
			position: relative;
			left: 44%;
		}
		.object-more-img{
			position: absolute;
			width: 1.22rem;
			margin: auto;
			display: block;
			cursor: pointer;
		}
		.object{
			width: 14.41rem;
			margin: auto auto 3.04rem;
		}
		.school-style-box{
			width: 14.41rem;
			height: 6.22rem;
			margin-bottom: 1.19rem;
			position: relative;
		}
		.style-img{
			width: 10.14rem;
			height: 5.7rem;
			position: absolute;
			right: 0;
			bottom: .52rem;
			z-index: 1;
		}
		.style-title-icon{
			width: 8.89rem;
			position: absolute;
			left: .02rem;
			top: .59rem;
			z-index: 2;
		}
		.style-icon{
			width: 1.05rem;
			height: auto;
			position: absolute;
			right: -.52rem;
			bottom: 0;
			z-index: 2;
		}
		.school-style-title{
			top: .01rem;
			left: 0;
			position: absolute;
			right: 0;
			font-weight: 400;
			color: #fff;
			font-size: .4rem;
			line-height: .4rem;
		}
		.school-style-txt{
			position: absolute;
			right: 0;
			font-weight: 400;
			color: #fff;
			top: 3rem;
			left: 0;
			-webkit-line-clamp: 11;
			letter-spacing: -.01rem;
			width: 3.6rem;
			font-size: .16rem;
			line-height: .28rem;
			text-align: justify;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
		}
		.school-style{
			margin-bottom: 7.68rem;
		}
		.greet-link{
			padding-bottom: 7.77rem;
		}
		.greet-title{
			font-size: .4rem;
			line-height: .4rem;
			font-weight: 400;
			color: #3e3a39;
			margin-bottom: .21rem;
			text-align: center;
		}
		.greet-title-icon{
			width: 4.54rem;
			height: auto;
			display: block;
			margin: auto auto .9rem;
		}
		.greet-message{
			width: 1.22rem;
			left: 7.8rem;
			position: absolute;
			margin: auto;
			display: block;
			cursor: pointer;
		}
		.greet-my{
			left: 10.18rem;
			width: 1.22rem;
			cursor: pointer;
			position: relative;
		}
		.greet-my img{
			position: absolute;
			width: 1.22rem;
			margin: auto;
			display: block;
			cursor: pointer;
		}
</style>
<style scoped>
	.greet-txt{
		font-size: .2rem;
		font-weight: 400;
		color: #3e3a39;
		line-height: .28rem;
		text-align: center;
		margin-bottom: .45rem;
	}
</style>