<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/recruitbg.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<p class="title50">
							{{lan?'奖学金制度':`Scholarship system`}}
							<br>
							{{lan?'Scholarship System':``}}
						</p>
						<img src="../../../public/static/img/scholarship-img1.png" class="img" alt="">
						<p class="title30 title-lineS">{{lan?'星辰奖学金：辰美校务委员会为优秀新生特设的奖学金':`Star Scholarship: A scholarship offered by the Stellart School Management Committee for outstanding freshmen`}}</p>
						<p class="title22"><br>
							{{lan?'●所有在公开考试或辰美入学考试中成绩优异，且符合以下条件之一的新生可申请“星辰奖学金”，奖学金为人民币1万至60万元不等，最高额奖学金为全额奖学金，其奖励额度等同于学生在辰美就读期间的所有学费：':`● All new students who have achieved excellent results in the public examination or the SISA Entrance examination and meet one of the following criteria can apply for the "Star Scholarship", the scholarship range is 10,000 to 600,000 yuan, the maximum scholarship is a full scholarship, the amount of which is equal to the entire tuition fee during the student's study at SISA:`}}
						</p>
						<br>
						<br>
						<!-- <p class="title22">
							<br>
							New Student Scholarship
							          Students who achieve excellent results in our admission test and meet one of the following conditions can apply for our scholarship. The scholarship ranges from 10,000 to 500,000RMB. You have the chance to receive a full-ride scholarship, which is equal to the total tuition during your study at Stellart:
							<br>
							<br>
						</p> -->
						<p class="text18R">
							
							{{lan?'a. 在原就读学校综合成绩排名前5%（年级人数超过600人则放宽至10%）的学生；':`a. Students who are in the top 5% of their school's overall scores (up to 10% for grades over 600);`}}<br><br>

              {{lan?'b. 在全国或国际艺术类竞赛中获得一等奖或二等奖，或在教育主管部门举办的省级艺术类竞赛中获得一等奖的学生；':`b. Students who have won the first or second prize in a national or international art competition, or the first prize in a provincial art competition organized by an education authority;`}}<br><br>

              {{lan?'c. 获得国际、国内艺术名师推荐，且外语成绩优异（雅思5.5分及以上，或国内英语考试成绩达总分90%以上）的学生；':`c. Students recommended by international and domestic famous artists with excellent foreign language scores (IELTS 5.5 or above, or more than 90% of the total score in domestic English tests);`}}<br><br>

              {{lan?'d. 中考成绩优异，达到各属地重点高中投档线':`d. Excellent results in the senior high school entrance examination, reaching the key high schools in each territory`}}<br><br>

              {{lan?'注：符合以上条件的申请者可提供相关证明材料向辰美提出奖学金申请。具体奖学金等次、金额由辰美招生委员会和校务委员会决定。':`Note: Applicants who meet the above requirements can provide relevant supporting documents to SISA to apply for the scholarship. The specific scholarships and the amount are determined by the SISA Admission Committee and the school Council.`}}
							<br>
					
          
						</p>
						<!-- <p class="title30 title-lineS">{{lan?'名校升学奖学金':``}}</p>
						<p class="title22">{{lan?'成功考取世界排名前十艺术院校的学生，一次性奖励人民币3至10万元奖学金。':``}}</p>
						<p class="text18R">
							{{lan?'（注： 具体颁发条例由辰美国际艺术教育另行制定）':``}}
							<br>
						</p> -->
					</div>
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'scholarship',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				tip:5,
				tipNumber:1,
        lan: 1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		mounted() {
      const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
        // 英文版
				this.lan = 0
			}else if(language == 'en'&&newLanguage=='cn'){
        // 中文版
				this.lan = 1
			}
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeIndex(){
				this.$router.push("/");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .87rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.title50{
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .22rem;
	}
	.img {
		width: 100%;
		height: auto;
		margin: .6rem 0 0;
	}
	.title-lineS {
	  margin-bottom: .58rem;
	}
	.title-lineS {
		border-bottom: 1px solid #0e183f;
		padding-bottom: .06rem;
		display: inline-block;
	}
	.title30, .title30M {
		font-size: .3rem;
		line-height: .3rem;
		margin-top: 1rem;
	}
	.title30, .title40 {
		font-weight: 700;
		color: #0e183f;
	}
	.title22 {
		font-size: .22rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R {
	    font-size: .18rem;
	    text-align: justify;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R, .title22{
	  line-height: .36rem;
	}
</style>
