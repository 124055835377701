<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/planningbg.c0f4821.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<div class="search">
							<input type="text" v-model="needText" placeholder=" 请输入院校名称搜索" class="text14R">
							<img style="cursor: pointer;" src="../../../public/static/img/search-btn.035eb5f.png" @click="getNeedCollege" alt="搜索">
						</div>
						<div class="type-wrap">
							<div class="type-search-wrap">
								<div class="country type-search flex">
									<p class="text14B">国家：</p>
									<div class="type-height ">
										<p class="item-hover item-click item2" >不限</p>
										<p class="item-hover item2" >英国</p>
										<p class="item-hover item2">美国</p>
										<p class="item-hover item2">新加坡</p>
										<p class="item-hover item2" >法国</p>
										<p class="item-hover item2" >日本</p>
									</div>
									<button class="more-cut" @click="showCon" v-if="conShow">更多</button>
									<button class="more-cut" @click="hiddenCon" v-if="conHidden">收起</button>
								</div>
							</div>
							<div class="line">
								
							</div>
							<div class="type-search-wrap">
								<div class="country type-search flex">
									<p class="text14B">学历：</p>
									<div class="type-height ">
										<p class="item-hover item-click item1" >不限</p>
										<p class="item-hover item1">预科</p>
										<p class="item-hover item1">本科</p>
										<p class="item-hover item1">研究生</p>
									</div>
					<!-- 				<button class="more-cut">更多</button> -->
								</div>
							</div>
							<div class="line">
								
							</div>
							<div class="type-search-wrap">
								<div class="country type-search flex">
									<p class="text14B">专业：</p>
									<div class="type-height ">
										<p class="item-click item-hover item3" >不限</p>
										<p class="item-hover item3" >广告</p>
										<p class="item-hover item3" >娱乐设计</p>
										<p class="item-hover item3" >环境设计</p>
										<p class="item-hover item3" >电影</p>
										<p class="item-hover item3" >纯艺</p>
										<p class="item-hover item3" >平面设计</p>
										<p class="item-hover item3" >插画</p>
										<p class="item-hover item3" >交互设计</p>
										<p class="item-hover item3" >产品设计</p>
										<p class="item-hover item3" >视觉艺术</p>
										<p class="item-hover item3" >游戏艺术</p>
										<p class="item-hover item3" >建筑</p>
										<p class="item-hover item3" >室内设计</p>
										<p class="item-hover item3" >工业设计</p>
										<p class="item-hover item3" >服装设计</p>
										<p class="item-hover item3"  v-if="showTips">视觉传达</p>
										<p class="item-hover item3"  v-if="showTips">游戏设计(含预科)</p>
										<p class="item-hover item3"  v-if="showTips"> 游戏设计与开发</p>
										<p class="item-hover item3"  v-if="showTips">时尚管理与沟通</p>
										<p class="item-hover item3"  v-if="showTips">数字媒体制作</p>
										<p class="item-hover item3"  v-if="showTips">建筑技术</p>
										<p class="item-hover item3"  v-if="showTips">纺织品实际应用</p>
										<p class="item-hover item3"  v-if="showTips">产品设计工程</p>
										<p class="item-hover item3"  v-if="showTips">产品设计和工艺(含实习)</p>
										<p class="item-hover item3"  v-if="showTips">产品设计和工艺(实习与海外学习)</p>
										<p class="item-hover item3"  v-if="showTips">产品设计和工艺(海外学习)</p>
										<p class="item-hover item3"  v-if="showTips">产品设计和工艺</p>
										<p class="item-hover item3"  v-if="showTips"> 产品设计(含实习)</p>
										<p class="item-hover item3"  v-if="showTips">产品设计(实习与海外学习)</p>
										<p class="item-hover item3"  v-if="showTips">产品设计(海外学习)</p>
										<p class="item-hover item3"  v-if="showTips">国际时装 - 设计实践(充值)</p>
										<p class="item-hover item3"  v-if="showTips">国际时装 - 商业实践(充值)</p>
										<p class="item-hover item3"  v-if="showTips">插图与动画</p>
										<p class="item-hover item3"  v-if="showTips">纯艺和艺术史</p>
										<p class="item-hover item3"  v-if="showTips">艺术与策展</p>
										<p class="item-hover item3"  v-if="showTips">电影与媒体学(含实习)</p>
										<p class="item-hover item3"  v-if="showTips">时尚推广</p>
										<p class="item-hover item3"  v-if="showTips"> 时装设计和技术女装</p>
										<p class="item-hover item3"  v-if="showTips">时装设计和技术运动装</p>
										<p class="item-hover item3"  v-if="showTips"> 时装设计和技术男装</p>
										<p class="item-hover item3"  v-if="showTips"> 时装采购和销售</p>
										<p class="item-hover item3"  v-if="showTips"> 时装采购和销售</p>
										<p class="item-hover item3"  v-if="showTips"> 时尚艺术方向</p>
										<p class="item-hover item3"  v-if="showTips"> 数码媒体与传播学</p>
										<p class="item-hover item3"  v-if="showTips"> 电脑游戏技术</p>
										<p class="item-hover item3"  v-if="showTips">电脑动画制作及视觉效果</p>
										<p class="item-hover item3"  v-if="showTips"> 艺术史和策展</p>
										<p class="item-hover item3"  v-if="showTips">媒体与传达</p>
										<p class="item-hover item3"  v-if="showTips"> 动漫插画</p>
										<p class="item-hover item3"  v-if="showTips">纯艺与艺术史</p>
										<p class="item-hover item3"  v-if="showTips"> 时尚</p>
										<p class="item-hover item3"  v-if="showTips">媒体与传达预科(综合学位)</p>
										<p class="item-hover item3"  v-if="showTips">艺术史</p>
										<p class="item-hover item3"  v-if="showTips">游戏编程(综合学位)</p>
										<p class="item-hover item3"  v-if="showTips">游戏编程</p>
										<p class="item-hover item3"  v-if="showTips"> 美术与艺术史</p>
										<p class="item-hover item3"  v-if="showTips">纯艺术(拓展)</p>
										<p class="item-hover item3"  v-if="showTips">艺术管理</p>
										<p class="item-hover item3"  v-if="showTips">媒体与传达</p>
										<p class="item-hover item3"  v-if="showTips">国际时装管理和营销</p>
										<p class="item-hover item3"  v-if="showTips"> 国际时装商务</p>
										<p class="item-hover item3"  v-if="showTips"> 插画和平面</p>
										<p class="item-hover item3"  v-if="showTips">插画和动画</p>
										<p class="item-hover item3"  v-if="showTips">游戏技术</p>
										<p class="item-hover item3"  v-if="showTips">游戏艺术</p>
										<p class="item-hover item3"  v-if="showTips"> 纯艺术和插画</p>
										<p class="item-hover item3"  v-if="showTips"> 数字媒体</p>
										<p class="item-hover item3"  v-if="showTips"> 汽车与交通工具设计</p>
										<p class="item-hover item3"  v-if="showTips"> 广告营销</p>
										<p class="item-hover item3"  v-if="showTips">视觉效果</p>
										<p class="item-hover item3"  v-if="showTips">视频游戏数码艺术</p>
										<p class="item-hover item3"  v-if="showTips">媒体制作</p>
										<p class="item-hover item3"  v-if="showTips">视频游戏开发</p>
										<p class="item-hover item3"  v-if="showTips">景观建筑</p>
										<p class="item-hover item3"  v-if="showTips">视频游戏设计与制作</p>
										<p class="item-hover item3"  v-if="showTips">珠宝银器</p>
										<p class="item-hover item3"  v-if="showTips">纺织品设计：零售，商业和营销</p>
										<p class="item-hover item3"  v-if="showTips">珠宝与对象</p>
										<p class="item-hover item3"  v-if="showTips">纺织品设计：印刷和表面图案设计</p>
										<p class="item-hover item3"  v-if="showTips"> 纺织品设计：针织和编织</p>
										<p class="item-hover item3"  v-if="showTips">纺织设计：纤维艺术</p>
										<p class="item-hover item3"  v-if="showTips">纺织设计：刺绣</p>
										<p class="item-hover item3"  v-if="showTips">纺织品设计</p>
										<p class="item-hover item3"  v-if="showTips">产品与家具设计</p>
										<p class="item-hover item3"  v-if="showTips">媒体与传播</p>
										<p class="item-hover item3"  v-if="showTips">珠宝银器(工业设计)</p>
										<p class="item-hover item3"  v-if="showTips">国际珠宝商务</p>
										<p class="item-hover item3"  v-if="showTips">室内建筑设计</p>
										<p class="item-hover item3"  v-if="showTips">平面传播</p>
										<p class="item-hover item3"  v-if="showTips">宝石与珠宝研究</p>
										<p class="item-hover item3"  v-if="showTips">电影制作</p>
										<p class="item-hover item3"  v-if="showTips">时尚商务与促销</p>
										<p class="item-hover item3"  v-if="showTips">时尚品牌与传播</p>
										<p class="item-hover item3"  v-if="showTips">表演设计</p>
										<p class="item-hover item3"  v-if="showTips">艺术与设计</p>
										<p class="item-hover item3"  v-if="showTips">电影/电视视觉效果设计</p>
										<p class="item-hover item3"  v-if="showTips">视觉传达</p>
										<p class="item-hover item3"  v-if="showTips">模型制作</p>
										<p class="item-hover item3"  v-if="showTips">舞台妆</p>
										<p class="item-hover item3"  v-if="showTips"> 室内建筑与设计</p>
										<p class="item-hover item3"  v-if="showTips">电影制作</p>
										<p class="item-hover item3"  v-if="showTips">时尚传播与营销</p>
										<p class="item-hover item3"  v-if="showTips">创意活动管理</p>
										<p class="item-hover item3"  v-if="showTips">服装与表演设计</p>
										<p class="item-hover item3"  v-if="showTips">商业摄影</p>
										<p class="item-hover item3"  v-if="showTips">艺术与设计历史</p>
										<p class="item-hover item3"  v-if="showTips">动画制作</p>
										<p class="item-hover item3"  v-if="showTips">新形态艺术</p>
										<p class="item-hover item3"  v-if="showTips">当代艺术历史与理论</p>
										<p class="item-hover item3"  v-if="showTips">艺术与技术</p>
										<p class="item-hover item3"  v-if="showTips">视觉展示和展览设计</p>
										<p class="item-hover item3"  v-if="showTips">玩具设计</p>
										<p class="item-hover item3"  v-if="showTips"> 纺织/表面设计</p>
										<p class="item-hover item3"  v-if="showTips"> 摄影与相关媒体</p>
										<p class="item-hover item3"  v-if="showTips">包装设计</p>
										<p class="item-hover item3"  v-if="showTips">纺织物造型</p>
										<p class="item-hover item3"  v-if="showTips">广告与数字设计</p>
										<p class="item-hover item3"  v-if="showTips">用户体验设计</p>
										<p class="item-hover item3"  v-if="showTips"> 服务设计</p>
										<p class="item-hover item3"  v-if="showTips">连环画艺术</p>
										<p class="item-hover item3"  v-if="showTips">移动媒体艺术</p>
										<p class="item-hover item3"  v-if="showTips">珠宝</p>
										<p class="item-hover item3"  v-if="showTips">交互设计与游戏开发</p>
										<p class="item-hover item3"  v-if="showTips">家具设计</p>
										<p class="item-hover item3"  v-if="showTips">影视</p>
										<p class="item-hover item3"  v-if="showTips">时尚营销与管理</p>
										<p class="item-hover item3"  v-if="showTips">时尚</p>
										<p class="item-hover item3"  v-if="showTips"> 美容与香水业务</p>
										<p class="item-hover item3"  v-if="showTips"> 艺术史</p>
										<p class="item-hover item3"  v-if="showTips">建筑史</p>
										<p class="item-hover item3"  v-if="showTips">配饰设计</p>
										<p class="item-hover item3"  v-if="showTips">艺术史、理论和批评</p>
										<p class="item-hover item3"  v-if="showTips">绘画</p>
										<p class="item-hover item3"  v-if="showTips">纤维</p>
										<p class="item-hover item3"  v-if="showTips">游戏设计</p>
										<p class="item-hover item3"  v-if="showTips">人文研究</p>
										<p class="item-hover item3"  v-if="showTips">交互艺术</p>
										<p class="item-hover item3"  v-if="showTips">跨学科雕塑</p>
										<p class="item-hover item3"  v-if="showTips">油画</p>
										<p class="item-hover item3"  v-if="showTips">版画</p>
										<p class="item-hover item3"  v-if="showTips">城市设计</p>
										<p class="item-hover item3"  v-if="showTips">建筑设计</p>
										<p class="item-hover item3"  v-if="showTips">艺术媒体与技术</p>
										<p class="item-hover item3"  v-if="showTips">传达设计</p>
										<p class="item-hover item3"  v-if="showTips">设计与技术</p>
										<p class="item-hover item3"  v-if="showTips">设计历史与实践</p>
										<p class="item-hover item3"  v-if="showTips">漫画</p>
										<p class="item-hover item3"  v-if="showTips">设计</p>
										<p class="item-hover item3"  v-if="showTips">设计学</p>
										<p class="item-hover item3"  v-if="showTips">策划设计与管理</p>
										<p class="item-hover item3"  v-if="showTips">综合设计</p>
										<p class="item-hover item3"  v-if="showTips">艺术设计史</p>
										<p class="item-hover item3"  v-if="showTips">电脑艺术，电脑动画与视觉效果</p>
										<p class="item-hover item3"  v-if="showTips">摄影与录像</p>
										<p class="item-hover item3"  v-if="showTips">视觉批判</p>
										<p class="item-hover item3"  v-if="showTips"> 建筑，室内建筑与景观</p>
										<p class="item-hover item3"  v-if="showTips">纤维与材料研究</p>
										<p class="item-hover item3"  v-if="showTips">视觉传达设计(平面设计)</p>
										<p class="item-hover item3"  v-if="showTips"> 艺术与技术研究</p>
										<p class="item-hover item3"  v-if="showTips">新媒体</p>
										<p class="item-hover item3"  v-if="showTips">录像</p>
										<p class="item-hover item3"  v-if="showTips">视觉研究</p>
										<p class="item-hover item3"  v-if="showTips">纺织</p>
										<p class="item-hover item3"  v-if="showTips">雕塑</p>
										<p class="item-hover item3"  v-if="showTips">版画</p>
										<p class="item-hover item3"  v-if="showTips"> 油画/绘画</p>
										<p class="item-hover item3"  v-if="showTips"> 珠宝首饰/金属工艺</p>
										<p class="item-hover item3"  v-if="showTips">玻璃</p>
										<p class="item-hover item3"  v-if="showTips">家具</p>
										<p class="item-hover item3"  v-if="showTips">社区艺术</p>
										<p class="item-hover item3"  v-if="showTips">陶瓷</p>
										<p class="item-hover item3"  v-if="showTips">动画</p>
										<p class="item-hover item3"  v-if="showTips">艺术</p>
										<p class="item-hover item3"  v-if="showTips">电影与录像</p>
										<p class="item-hover item3"  v-if="showTips"> 摄影与媒体</p>
										<p class="item-hover item3"  v-if="showTips">角色动画</p>
										<p class="item-hover item3"  v-if="showTips">实验动画</p>
										<p class="item-hover item3"  v-if="showTips">数字艺术</p>
										<p class="item-hover item3"  v-if="showTips">摄影</p>
										<p class="item-hover item3"  v-if="showTips">艺术与设计教育学</p>
										<p class="item-hover item3"  v-if="showTips">数字艺术与动画</p>
										<p class="item-hover item3"  v-if="showTips">电影/录像</p>
										<p class="item-hover item3"  v-if="showTips">虚拟现实发展</p>
										<p class="item-hover item3"  v-if="showTips">娱乐设计</p>
										<p class="item-hover item3"  v-if="showTips">动作设计</p>
										<p class="item-hover item3"  v-if="showTips"> 创意写作</p>
										<p class="item-hover item3"  v-if="showTips">电脑动画</p>
										<p class="item-hover item3"  v-if="showTips">艺术与设计业务</p>
										<p class="item-hover item3"  v-if="showTips">交通设计</p>
										<p class="item-hover item3"  v-if="showTips">摄影与图片</p>
									</div>
									<button class="more-cut" @click="showMore" v-if="canShow">更多</button>
									<button class="more-cut" @click="hiddenMore" v-if="canHidden">收起</button>
								</div>
							</div>
							
						</div>
						
						<div class="line"></div>
						<div class="school-wrap">
							<div class="title title30">
								艺术院校
							</div>
							<div class="school-item-wrap">
								<div class="school-item flex cu" @click="comeCollegeInside(tv.id)" v-for="(tv,i) in TVList" :key="i">
									<img :src="tv.attributes.logoimage.data!=null ? BaseURL+tv.attributes.logoimage.data.attributes.url : ''" class="logo-img" alt="">
									<div class="school-info">
										<p class="name">{{tv.attributes.name}} </p>
										<p class="name">{{tv.attributes.name_en}}</p>
										<div class="detail">
											<div class="">
												<p class="detail-left text14R">简称： {{tv.attributes.abbreviation}}</p>
											</div>
											<div class="">
												<p class="detail-right text14R">雅思： {{tv.attributes.IELTS}}</p>
											</div>
											<div class="">
												<p class="detail-left text14R">优势专业： {{tv.attributes.forte}}</p>
											</div>
											<div class="">
												<p class="detail-right text14R">SAT： {{tv.attributes.SAT}}</p>
											</div>
										</div>
									</div>
									<img :src=" tv.attributes.image.data!=null ? BaseURL+tv.attributes.image.data.attributes.url : ''" class="image-img" alt="">
								</div>
							</div>
							<div class="noData text18R" v-if="false">
								暂无数据!
							</div>
						</div>
						<div class="pagination">
							<el-pagination
									:current-page="page"
							    layout="prev, pager, next"
									:page-size="pagesitem"
									@current-change="getPageList"
							    :total="totalitem">
							  </el-pagination>
						</div>
							<!-- <a-pagination size="small" :total="50" /> -->

					</div>
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import {  getCollegeData,getCollegeLimitData,getCollegeLimitNextData,getCollegeLimitNeedData,getCollegeLimitNeedTextData,getCollegeDataCN,getCollegeLimitDataCN,getCollegeLimitNextDataCN,getCollegeLimitNeedDataCN,getCollegeLimitNeedTextDataCN} from "../../api/api.js"
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'College',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				canShow:true,
				canHidden:false,
				showTips:false,
				conShow:true,
				conHidden:false,
				pagesitem:6,
				totalitem:0,
				page:1,
				TVList:[],
				major:'',
				forte:'',
				country:'',
				needText:'',
				BaseURL:process.env.VUE_APP_BASEURL,
				tip:4,
				tipNumber:1,
				isCN:'',
        lan: 1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		mounted() {
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			this.addClass()
			this.btnItem2()
			this.btnItem3()
			this.btnItem1()
			if(language=='en'&&newLanguage==null){
				this.lan = 0
				this.isCN = 'en'
				
				this.getPage()
				this.getCollege()
				
			}else if(language == 'en'&&newLanguage=='cn'){
				// 中文版处理
				// console.log('into this zhongwen')
				this.lan = 1
				this.isCN = 'cn'
				// 获取页码数据
				this.getPageCN()
				// 获取院校数据 - 中文版
				this.getCollegeCN()
				
			}
			
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			// 获取首页院校数据 - 英文版
			getCollege(){
				getCollegeLimitData().then(res=>{
					this.TVList = res.data.data
				})
			},
			// 获取页码数据
			getPage(){
				getCollegeData().then(res=>{
					// this.pagesitem = res.data.meta.page
					this.totalitem = res.data.meta.pagination.total
				})
			},
			// 下一页数据
			getPageList(pager = 1){
				this.page = pager;
				// console.log(this.isCN) 
				const onePage = (this.page - 1)*this.pagesitem
				if(this.isCN == 'en'){
					getCollegeLimitNextData(onePage).then(res=>{
						this.TVList = res.data.data
					})
				}else if(this.isCN=='cn'){
					getCollegeLimitNextDataCN(onePage).then(res=>{
						this.TVList = res.data.data
					})
				}
				
				
			},
			getNeedCollege(){
				if(this.isCN == 'en'){
					getCollegeLimitNeedTextData(this.needText).then(res=>{
						 
						// console.log(res.data.data)
						this.TVList = res.data.data
						this.totalitem = res.data.meta.pagination.total
					})
				}else if(this.isCN=='cn'){
					getCollegeLimitNeedTextDataCN(this.needText).then(res=>{
						 
						// console.log(res.data.data)
						this.TVList = res.data.data
						this.totalitem = res.data.meta.pagination.total
					})
				}
				
				// console.log(this.needText)
			},
			// 获取首页院校数据 - 中文版
			getCollegeCN(){
				getCollegeLimitDataCN().then(res=>{
					this.TVList = res.data.data
				})
			},
			// 获取页码数据
			getPageCN(){
				getCollegeDataCN().then(res=>{
					// this.pagesitem = res.data.meta.page
					this.totalitem = res.data.meta.pagination.total
				})
			},
			comeMyself(text){
				this.$router.push(text);
			},
			
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCollegeInside(id){
				const url = '/planning/CollegeInside?id='+id 
				this.$router.push(url);
			},
			addClass(){
				var lis = document.querySelectorAll(".type-height");
				// console.log(lis[0].classList)
				lis[0].classList.add('.type-short')
			},
			showCon(){
				this.conShow=false
				this.conHidden = true
			},
			hiddenCon(){
				this.conShow=true
				this.conHidden = false
			},
			btnItem3(){
				var lis = document.querySelectorAll(".item3");
				
				lis.forEach(item => {
				    item.onmouseup = () => {
							var clicklis = document.querySelectorAll(".item-click");
							clicklis[2].classList.remove('item-click')
				       //先全部清空选中，再给当前添加选中
							 
							 item.classList.remove('item-click')
							  item.classList.add('item-click')
								
							// item.children[1].children[0].children[0].classList.remove('')
				      
				    }
						item.onclick = () =>{
							var clicklis = document.querySelectorAll(".item-click");
							// console.log(clicklis[2].innerHTML)
							if(clicklis[2].innerHTML == "不限"){
								this.major = ""
							}else{
								this.major = clicklis[2].innerHTML
							}
							console.log('btn3', this.major,this.country,this.forte)
							if(this.isCN == 'en'){
								// 筛选数据 - 三级 专业
								getCollegeLimitNeedData(this.major,this.country,this.forte).then(res=>{
									// console.log(res.data.data)
									this.TVList = res.data.data
									this.totalitem = res.data.meta.pagination.total
								})
							}else if(this.isCN == 'cn'){
								// 筛选数据 - 三级 专业
								getCollegeLimitNeedDataCN(this.major,this.country,this.forte).then(res=>{
									// console.log(res.data.data)
									this.TVList = res.data.data
									this.totalitem = res.data.meta.pagination.total
								})
							}
							
						}
				})
			},
			btnItem2(){
				var lis = document.querySelectorAll(".item2");
				lis.forEach(item => {
				    item.onmouseup = () => {
							var clicklis = document.querySelectorAll(".item-click");
							// console.log()
							clicklis[0].classList.remove('item-click')
				       //先全部清空选中，再给当前添加选中
							 
							 item.classList.remove('item-click')
							  item.classList.add('item-click')
							
				    }
						item.onclick = () =>{
							var clicklis = document.querySelectorAll(".item-click");
							// console.log(clicklis[0].innerHTML)
							if(clicklis[0].innerHTML == "不限"){
								this.country = ""
							}else{
								this.country = clicklis[0].innerHTML
							}
							
							console.log('btn2', this.major,this.country,this.forte)
							if(this.isCN == 'en'){
								// 筛选数据 - 二级 国家
								getCollegeLimitNeedData(this.major,this.country,this.forte).then(res=>{
									// console.log(res.data.data)
									this.TVList = res.data.data
									this.totalitem = res.data.meta.pagination.total
								})
							}else if(this.isCN == 'cn'){
								// 筛选数据 - 二级 国家
								getCollegeLimitNeedDataCN(this.major,this.country,this.forte).then(res=>{
									// console.log(res.data.data)
									this.TVList = res.data.data
									this.totalitem = res.data.meta.pagination.total
								})
							}
							
						}
				})
			},
			btnItem1(){
				var lis = document.querySelectorAll(".item1");
				lis.forEach(item => {
				    item.onmouseup = () => {
							var clicklis = document.querySelectorAll(".item-click");
							// console.log()
							clicklis[1].classList.remove('item-click')
				       //先全部清空选中，再给当前添加选中
							 
							 item.classList.remove('item-click')
							  item.classList.add('item-click')
							
				    }
						item.onclick = () =>{
							var clicklis = document.querySelectorAll(".item-click");
							// console.log(clicklis[1].innerHTML)
							if(clicklis[1].innerHTML == "不限"){
								this.forte = ""
							}else{
								this.forte = clicklis[1].innerHTML
							}
							console.log('btn1', this.major,this.country,this.forte)
							if(this.isCN == 'en'){
								// 筛选数据 - 一级 学历
								getCollegeLimitNeedData(this.major,this.country,this.forte).then(res=>{
									// console.log(res.data.data)
									this.TVList = res.data.data
									this.totalitem = res.data.meta.pagination.total
								})
							}else if(this.isCN == 'cn'){
								// 筛选数据 - 一级 学历
								getCollegeLimitNeedDataCN(this.major,this.country,this.forte).then(res=>{
									// console.log(res.data.data)
									this.TVList = res.data.data
									this.totalitem = res.data.meta.pagination.total
								})
							}
							
						}
				})
			},
			showMore(){
				this.canHidden = true
				this.canShow = false
				this.showTips=true
				var lis = document.querySelectorAll(".type-height");
				lis[0].classList.remove('.type-short')
        this.$nextTick(this.btnItem3)
        // btnItem3()
			},
			hiddenMore(){
				this.canHidden = false
				this.canShow = true
				this.showTips=false
				var lis = document.querySelectorAll(".type-height");
				// console.log(lis[0].classList)
				lis[0].classList.add('.type-short')
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .87rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.search{
		display: flex;
		align-items: center;
	}
	.search input{
		width: 5.5rem;
		height: .34rem;
		border: 1px solid #b3b3b3;
		color: #000;
		touch-action: manipulation;
	}
	.text14R{
		font-size: .14rem;
		font-weight: 400;
		line-height: .22rem;
	}
	.search img{
		width: 1.27rem;
		height: .36rem;
	}
	.type-wrap{
		margin-top: .4rem;
	}
	.country{
		display: flex;
	}
	.text14B {
		font-size: .14rem;
		color: #0e183f;
		font-weight: 700;
		line-height: .23rem;
	}
	.type-wrap .type-search-wrap .type-height{
		margin: 0 0 .1rem .03rem;
		flex: 1;
	}
	.type-height{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		
		overflow: hidden;
	}
	.type-short{
		max-height: .65rem;
	}
	.type-wrap .type-search-wrap .type-height .item-click{
		display: flex;
		align-items: center;
		line-height: .28rem;
		margin: 0 .05rem .05rem 0;
		padding: 0 .09rem;
		border-radius: .05rem;
		cursor: pointer;
		color: #fff;
		background-color: #ea5431;
	}
	.type-wrap .type-search-wrap .type-height .item-hover{
		display: flex;
		align-items: center;
		line-height: .28rem;
		margin: 0 .05rem .05rem 0;
		padding: 0 .09rem;
		border-radius: .05rem;
		cursor: pointer;
	}
	.type-wrap .type-search-wrap .type-height .item-hover:last-child {
	    margin-right: auto;
	}
	.more-cut{
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		background-color: #fff;
		padding: 0 .18rem;
		border: 1px solid #942529;
		color: #942529;
		height: .23rem;
		margin: 0 .05rem .05rem auto;
	}
	.line{
	  margin-bottom: 19px;
	  height: 2px;
	  border-bottom: 1px dashed rgba(16,30,45,.2);
	}
	.item-hover:hover{
		color: #ea5431;
	}
	.school-wrap .title{
		margin-bottom: .4rem;
	}
	.title30 {
		font-size: .3rem;
		line-height: .3rem;
		margin-top: 1rem;
		font-weight: 700;
		color: #0e183f;
	}
	.school-wrap .school-item-wrap .school-item{
		width: 100%;
		height: 1.83rem;
		background-color: rgba(14,24,60,.1);
		margin-bottom: .3rem;
		cursor: pointer;
		display: flex;
	}
	.school-wrap .school-item-wrap .school-item .logo-img{
		width: 1.83rem;
		height: 1.83rem;
		background-color: rgba(14,24,60,.15);
	}
	.school-wrap .school-item-wrap .school-item .school-info{
		margin: .3rem 0 0 .23rem;
		width: 4.98rem;
	}
	.school-wrap .school-item-wrap .school-item .school-info .name{
		font-size: .18rem;
		font-weight: 700;
		color: #0d1740;
		line-height: .23rem;
	}
	.school-wrap .school-item-wrap .school-item .school-info .detail{
		margin-top: .18rem;
		flex-wrap: wrap;
		display: flex;
	}
	.school-wrap .school-item-wrap .school-item .school-info .detail .detail-left {
	    width: 2rem;
	    margin-right: .62rem;
			margin-bottom: .2rem;
	}
	.text14B, .text14R {
	    font-size: .14rem;
	    color: #0e183f;
	}
	.text14R {
	    font-weight: 400;
	    line-height: .22rem;
	}
	.school-wrap .school-item-wrap .school-item .image-img{
	    width: 2.74rem;
	    height: 1.83rem;
	    margin-left: auto;
	}
	.detail-right{
		width: 2rem;
	}
	.pagination{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	input{
		padding: 0;
	}
</style>
