<template>
	<div class="left-nav">
		<div>
			<p v-for="(k,y) in trueItemList" :class="k.active==1 ? 'nav-active':''" @click="comeMyself(k.url)" :key="y">{{k.title}}</p>
<!-- 			<p @click="comeintro">Introduction</p>
			<p @click="comeVisit"> Campus Tour</p>
			<p @click="comeArchitectureAndTeam">Structure and Management Team</p>
			<p @click="comeCo">Our Partners</p> -->
		</div>
	</div>
</template>

<script>
	export default{
		name:'headerList',
		data(){
			return{
				trueItemList:[],
				itemList:[
					[
						
					],
					[
						{id:1,active:0,url:'/about/presidentWords',title:"Principal Message"},
						{id:2,active:0,url:'/about/introduction',title:"Introduction"},
						{id:3,active:0,url:'/about/visitCampus',title:"Campus Tour"},
						{id:4,active:0,url:'/about/architectureAndTeam',title:"Structure and Management Team"},
						{id:5,active:0,url:'/about/co',title:"Our Partners"},
					],
					[
						{id:1,active:0,url:'/teaching/sixCollegesList',title:"Art and Design production direction"},
						{id:2,active:0,url:'/teaching/commercial',title:" Music direction"},
						{id:3,active:0,url:'/teaching/igandAlevel',title:"IGCSE and A Level"},
						{id:4,active:0,url:'/teaching/btec',title:"BTEC"},
					],
					[
						{id:1,active:0,url:'/life/campusNews',title:"Campus News"},
						{id:2,active:0,url:'/life/TVstation',title:"Campus original video"},
						{id:3,active:0,url:'/life/calendar',title:"School calendar"},
					],
					[
						{id:1,active:0,url:'/planning/service',title:"University Counseling Service"},
						// {id:2,active:0,url:'/planning/College',title:"Foreign art schools"},
						{id:2,active:0,url:'/planning/foreignCommercial',title:"Iversea music colleges"},

					],
					[
						// {id:1,active:0,url:'/information/story',title:"SISA Story"},
						{id:1,active:0,url:'/information/rules',title:"Enrollment Brochure"},
						{id:2,active:0,url:'/information/scholarship',title:"Freshman scholarships"},
						// {id:4,active:0,url:'/information/qanda',title:"Q&A"},
						{id:3,active:0,url:'/information/apply',title:"Online Registration"},
						{id:4,active:0,url:'/information/message',title:"Message"}
					],
					[
						{id:1,active:0,url:'/contact/Consultation',title:"Contact and consultation"},
						{id:2,active:0,url:'/contact/recruit',title:"Job postings"}
					],
				],
				itemCNList:[
					[
						
					],
					[
						{id:1,active:0,url:'/about/presidentWords',title:"校长寄语"},
						{id:2,active:0,url:'/about/introduction',title:"辰美介绍"},
						{id:3,active:0,url:'/about/visitCampus',title:"参观校园"},
						{id:4,active:0,url:'/about/architectureAndTeam',title:"辰美架构及管理团队"},
						{id:5,active:0,url:'/about/co',title:"辰美合作单位"},
					],
					[
						{id:1,active:0,url:'/teaching/sixCollegesList',title:"艺术设计与制作方向"},
						{id:2,active:0,url:'/teaching/commercial',title:"音乐方向"},
						{id:3,active:0,url:'/teaching/igandAlevel',title:"IGCSE、ALEVEL学制"},
						{id:4,active:0,url:'/teaching/btec',title:"BTEC学制"},
					],
					[
						{id:1,active:0,url:'/life/campusNews',title:"校园动态"},
						{id:2,active:0,url:'/life/TVstation',title:"校园原创视频"},
						{id:3,active:0,url:'/life/calendar',title:"校历"},
					],
					[
						{id:1,active:0,url:'/planning/service',title:"升学服务"},
						{id:2,active:0,url:'/planning/College',title:"国外艺术院校"},
						{id:3,active:0,url:'/planning/foreignCommercial',title:"国外音乐院校"},
					],
					[
						// {id:1,active:0,url:'/information/story',title:"辰美故事"},
						{id:1,active:0,url:'/information/rules',title:"招生简章"},
						{id:2,active:0,url:'/information/scholarship',title:"新生奖学金"},
						// {id:4,active:0,url:'/information/qanda',title:"Q&A"},
						{id:3,active:0,url:'/information/apply',title:"在线报名"},
						{id:4,active:0,url:'/information/message',title:"留言咨询"}
					],
					[
						{id:1,active:0,url:'/contact/Consultation',title:"联系及咨询方式"},
						{id:2,active:0,url:'/contact/recruit',title:"招聘信息"}
					],
				]
			}
		},
		props: {
		  tip: Number,
			tipNumber:Number,
		},
		mounted() {
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			// console.log(this.tip,this.tipNumber)
			if(language=='en'&&newLanguage==null){
			  this.itemList[this.tip][this.tipNumber].active = 1
				
				this.trueItemList = this.itemList[this.tip]
			}else if(language == 'en'&&newLanguage=='cn'){
			  this.itemCNList[this.tip][this.tipNumber].active = 1
				
				this.trueItemList = this.itemCNList[this.tip]
			}
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},	
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			
		}
	}
</script>

<style>
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		left: .5rem;
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		width: 2.9rem;
		height: .37rem;
		background: #000;
		color: #fff !important;
	}
</style>