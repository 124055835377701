<template>
	<div class="header-list-box">
		<div class="item" v-for="(item,i) in trueHeaderList" :key="i">
			<p @click="comeMyself(item.url)" :class="item.active==1 ? 'active':''">{{item.title}}</p>
			<div class="drop-down-list">
				<div class="drop-down-item" v-for="(k,y) in trueItemList[i]" @click="comeMyself(k.url)" :key="y">{{k.title}}</div>
<!-- 				<div class="drop-down-item" @click="comeintro">{{}}</div>
				<div class="drop-down-item" @click="comeVisit">{{}}</div>
				<div class="drop-down-item" @click="comeArchitectureAndTeam">{{}}</div>
				<div class="drop-down-item" @click="comeCo">{{}}</div> -->
			</div>
		</div>
<!-- 		<div class="active item item1">{{}}</div>
		<div class="item" >
			<p @click="comePresidnt">{{}}</p>
			<div class="drop-down-list">
				<div class="drop-down-item" @click="comePresidnt">{{}}</div>
				<div class="drop-down-item" @click="comeintro">{{}}</div>
				<div class="drop-down-item" @click="comeVisit">{{}}</div>
				<div class="drop-down-item" @click="comeArchitectureAndTeam">{{}}</div>
				<div class="drop-down-item" @click="comeCo">{{}}</div>
			</div>
		</div>
		<div class="item ">
			<p @click="comeTeaching">{{}}</p>
			<div class="drop-down-list">
				<div class="drop-down-item" @click="comeTeaching">{{}}</div>
				<div class="drop-down-item" @click="comeCommercial">{{}}</div>
				<div class="drop-down-item" @click="comeIgandAlevel">{{}}</div>
				<div class="drop-down-item" @click="comeBtec">{{}}</div>
			</div>
		</div>
		<div class="item">
			<p @click="comeCampusNews">{{}}</p>
			<div class="drop-down-list">
				<div class="drop-down-item" @click="comeCampusNews">{{}}</div>
				<div class="drop-down-item" @click="comeTVstation">{{}}</div>
				<div class="drop-down-item" @click="comeCalendar">{{}}</div>
			</div>
		</div>
		<div class="item ">
			<p @click="comeService">{{}}</p>
			<div class="drop-down-list">
				<div class="drop-down-item" @click="comeService">{{}}</div>
				<div class="drop-down-item" @click="comeCollege">{{}}</div>
				<div class="drop-down-item" @click="comeForeignCommercial">{{}}</div>
			</div>
		</div>
		<div class="item ">
			<p @click="comeStory">{{}}</p>
			<div class="drop-down-list">
				<div class="drop-down-item" @click="comeStory">{{}}</div>
				<div class="drop-down-item" @click="comeMyself(`/information/rules`)">{{}}</div>
				<div class="drop-down-item" @click="comeMyself(`/information/scholarship`)">{{}}</div>
				<div class="drop-down-item" @click="comeMyself(`/information/qanda`)">{{}}</div>
				<div class="drop-down-item" @click="comeMyself(`/information/apply`)">{{}}</div>
				<div class="drop-down-item" @click="comeMyself(`/information/message`)">{{}}</div>
			</div>
		</div>
		<div class="item">
			<p @click="comeMyself(`/contact/Consultation`)">{{}}</p>
			<div class="drop-down-list">
				<div class="drop-down-item" @click="comeMyself(`/contact/Consultation`)">{{}}</div>
				<div class="drop-down-item" @click="comeMyself(`/contact/recruit`)">{{}}</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	export default{
		name:'headerList',
		data(){
			return{
				trueHeaderList:[],
				trueItemList:[],
				headerList:[
					{id:0,url:'/',title:"Home",active:0},
					{id:1,url:'/about/presidentWords',title:"About SISA",active:0},
					{id:2,url:'/teaching/sixCollegesList',title:"Teaching System",active:0},
					{id:3,url:'/life/campusNews',title:"What's New",active:0},
					{id:4,url:'/planning/service',title:"University Counselling",active:0},
					{id:5,url:'/information/rules',title:"Admission",active:0},
					{id:6,url:'/contact/Consultation',title:"Contact us",active:0},
				],
				headerCNList:[
					{id:0,url:'/',title:"首页",active:0},
					{id:1,url:'/about/presidentWords',title:"关于辰美",active:0},
					{id:2,url:'/teaching/sixCollegesList',title:"教学体系",active:0},
					{id:3,url:'/life/campusNews',title:"最新资讯",active:0},
					{id:4,url:'/planning/service',title:"升学规划",active:0},
					{id:5,url:'/information/rules',title:"招生信息",active:0},
					{id:6,url:'/contact/Consultation',title:"联系我们",active:0},
				],
				itemList:[
					[
						
					],
					[
						{id:1,url:'/about/presidentWords',title:"Principal Message"},
						{id:2,url:'/about/introduction',title:"Introduction"},
						{id:3,url:'/about/visitCampus',title:"Campus Tour"},
						{id:4,url:'/about/architectureAndTeam',title:"Structure and Management Team"},
						{id:5,url:'/about/co',title:"Our Partners"},
					],
					[
						{id:1,url:'/teaching/sixCollegesList',title:"Art and Design production direction"},
						{id:2,url:'/teaching/commercial',title:" Music direction"},
						{id:3,url:'/teaching/igandAlevel',title:"IGCSE and A Level"},
						{id:4,url:'/teaching/btec',title:"BTEC"},
					],
					[
						{id:1,url:'/life/campusNews',title:"Campus News"},
						{id:2,url:'/life/TVstation',title:"Campus original video"},
						{id:3,url:'/life/calendar',title:"School calendar"},
					],
					[
						{id:1,active:0,url:'/planning/service',title:"University Counseling Service"},
						// {id:2,active:0,url:'/planning/College',title:"Foreign art schools"},
						{id:3,active:0,url:'/planning/foreignCommercial',title:"Iversea music colleges"},
						
					],
					[
						// {id:1,url:'/information/story',title:"SISA Story"},
						{id:2,url:'/information/rules',title:"Enrollment Brochure"},
						{id:3,url:'/information/scholarship',title:"Freshman scholarships"},
						// {id:4,url:'/information/qanda',title:"Q&A"},
						{id:5,url:'/information/apply',title:"Online Registration"},
						{id:6,url:'/information/message',title:"Message"}
					],
					[
						{id:1,url:'/contact/Consultation',title:"Contact and consultation"},
						{id:2,url:'/contact/recruit',title:"Job postings"}
					],
				],
				itemCNList:[
					[
						
					],
					[
						{id:1,url:'/about/presidentWords',title:"校长寄语"},
						{id:2,url:'/about/introduction',title:"辰美介绍"},
						{id:3,url:'/about/visitCampus',title:"参观校园"},
						{id:4,url:'/about/architectureAndTeam',title:"辰美架构及管理团队"},
						{id:5,url:'/about/co',title:"辰美合作单位"},
					],
					[
						{id:1,url:'/teaching/sixCollegesList',title:"艺术设计与制作方向"},
						{id:2,url:'/teaching/commercial',title:"音乐方向"},
						{id:3,url:'/teaching/igandAlevel',title:"IGCSE、ALEVEL学制"},
						{id:4,url:'/teaching/btec',title:"BTEC学制"},
					],
					[
						{id:1,url:'/life/campusNews',title:"校园动态"},
						{id:2,url:'/life/TVstation',title:"校园原创视频"},
						{id:3,url:'/life/calendar',title:"校历"},
					],
					[
						{id:1,active:0,url:'/planning/service',title:"升学服务"},
						{id:2,active:0,url:'/planning/College',title:"国外艺术院校"},
						{id:3,active:0,url:'/planning/foreignCommercial',title:"国外音乐院校"},
					
					],
					[
						// {id:1,url:'/information/story',title:"辰美故事"},
						{id:2,url:'/information/rules',title:"招生简章"},
						{id:3,url:'/information/scholarship',title:"新生奖学金"},
						// {id:4,url:'/information/qanda',title:"Q&A"},
						{id:5,url:'/information/apply',title:"在线报名"},
						{id:6,url:'/information/message',title:"留言咨询"}
					],
					[
						{id:1,url:'/contact/Consultation',title:"联系及咨询方式"},
						{id:2,url:'/contact/recruit',title:"招聘信息"}
					],
				]
			}
		},
		props: {
		  tip: Number,
		
		},
		mounted() {
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			this.headerList[this.tip].active = 1
			this.headerCNList[this.tip].active = 1
			if(language=='en'&&newLanguage==null){
				this.trueHeaderList = this.headerList
				this.trueItemList = this.itemList
			}else if(language == 'en'&&newLanguage=='cn'){
				this.trueHeaderList = this.headerCNList
				this.trueItemList = this.itemCNList
			}
			// console.log(this.trueHeaderList,'dagiudgh', language, newLanguage)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},	
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			
		}
	}
</script>

<style scoped>
	.header-list-box{
		justify-content: space-between;
		display: flex;
	}
	.item{
		position: relative;
	
		margin-right: .44rem;
		padding-bottom: .09rem;
		border-bottom: .05rem solid hsla(0,0%,100%,0);
		white-space: nowrap;
		height: .33rem;
	}
	/* .item1{
		padding-top: .18rem;
		padding-bottom: 0rem;
	} */
	.item:hover .drop-down-list{
		display: block;
		
	}
	.drop-down-list{
		position: absolute;
		top: .47rem;
		left: 0;
		width: 2.03rem;
		background-color: #992b2d;
		z-index: 99;
		display: none;
		font-weight: 500;
	}
	.drop-down-list .drop-down-item{
		color: #fff;
		margin: .15rem 0 .15rem 8px;
    text-overflow: inherit;
    overflow: inherit;
    width: 1.8rem;
    white-space: break-spaces;
	}
	.drop-down-list .drop-down-item:hover{
		color:#dd902d
	}
	.active{
		color: #992b2d;
	}
	.item:hover{
		border-bottom: .05rem solid #992b2d;
	}
</style>