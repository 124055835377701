<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/recruitbg.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<iframe allowtransparency="true" src="https://jinshuju.net/f/OffyKp?background=white&banner=show&embedded=true&inner_redirect=false" style="width: 9.55rem;height:16rem" frameborder="0"></iframe>
					</div>
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'message',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				tip:5,
				tipNumber:3,
        lan:1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		mounted() {
      const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
        // 英文版
				this.lan = 0
			}else if(language == 'en'&&newLanguage=='cn'){
        // 中文版
				this.lan = 1
			}
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeIndex(){
				this.$router.push("/");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .87rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
		width: 9.55rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	
</style>
