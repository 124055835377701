import axios from 'axios'

let name = '//api.sisa.prod.p1.yixininteractive.com'
// var name = '//api.sisa.test.t10.idealead.com'
// var name = 'http://localhost:1337'
export const getPresidentWordsPage = () => {
	 return axios.request({
		method: 'get',
		url:  name + '/api/president-words-pages?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getPresidentWordsPageCN = () => {
	 return axios.request({
		method: 'get',
		url:  name + '/api/president-words-pages?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getCampusImage = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-images?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getCampusVideo = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-videos?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getCampusImageCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-images?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getCampusVideoCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-videos?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getArchitectureTeam = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/architecture-teams?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getArchitectureTeamCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/architecture-teams?filters[language]=cn&sort=sort:desc&populate=*'
	})
}
export const getTeamTeach = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/teaching-teams?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getTeamTeachCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/teaching-teams?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getcoPages = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/co-pages?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getcoPagesCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/co-pages?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getSixColleges = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/six-colleges-pages?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getSixCollegesDetail = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/six-colleges-details?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getSixCollegesCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/six-colleges-pages?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getSixCollegesDetailCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/six-colleges-details?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getIgAndLevel = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/ig-andlevels?filters[language]=en&sort=sort:desc'
	})
}

export const getIgAndLevelCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/ig-andlevels?filters[language]=cn&sort=sort:desc'
	})
}


export const getCampusItem = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-news-items?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getCampusList = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-news-lists?filters[language]=en&sort[0]=sort:desc&sort[1]=fa_date:desc&populate=*'
	})
}

export const getCampusItemCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-news-items?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getCampusListCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-news-lists?filters[language]=cn&sort[0]=sort:desc&sort[1]=fa_date:desc&populate=*'
	})
}

export const getTV = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/tv-station-tvs?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const gettvinterviews = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/tvinterviews?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getrecomdlist= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/recomd-lists?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getTVCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/tv-station-tvs?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const gettvinterviewsCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/tvinterviews?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getrecomdlistCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/recomd-lists?filters[language]=cn&sort=sort:desc&populate=*'
	})
}
export const getRulesImage= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/rules-images?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getRulesItem= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/rules-items?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getRulesImageCN= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/rules-images?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getRulesItemCN= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/rules-items?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getRecruit= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/recruits?filters[language]=en&sort=sort:desc&populate=*'
	})
}
export const getRecruitCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/recruits?filters[language]=cn&sort=sort:desc&populate=*'
	})
}
export const getViedoData= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/viedo-dates?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getViedoDataCN= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/viedo-dates?filters[language]=cn&sort=sort:desc&populate=*'
	})
}

export const getCollegeData= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?filters[language]=en&sort=sort:desc&populate=*'
	})
}

export const getCollegeLimitData= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?pagination[start]=0&pagination[limit]=6&populate=*&sort=sort:desc'
	})
}

export const getCollegeLimitNextData= (onePage) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?pagination[start]='+ onePage +'&pagination[limit]=6&populate=*&sort=sort:desc'
	})
}

export const getCollegeLimitNeedData= (major,country,forte) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?filters[country][$contains]='+ country +'&&filters[major][$contains]=' +major+'&&filters[schoolrecord][$contains]='+forte + '&populate=*&sort=sort:desc'
	})
}

export const getCollegeLimitNeedTextData= (needname) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?filters[name_en][$contains]='+ needname + '&populate=*&sort=sort:desc'
	})
}

export const getCollegeDataCN= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/college-cns?filters[language]=cn&populate=*&sort=sort:desc'
	})
}

export const getCollegeLimitDataCN= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/college-cns?filters[language]=cn&pagination[start]=0&pagination[limit]=6&populate=*&sort=sort:desc'
	})
}

export const getCollegeLimitNextDataCN= (onePage) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/college-cns?filters[language]=cn&pagination[start]='+ onePage +'&pagination[limit]=6+&populate=*&sort=sort:desc'
	})
}

export const getCollegeLimitNeedDataCN= (major,country,forte) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/college-cns?filters[language]=cn&&filters[country][$contains]='+ country +'&&filters[major][$contains]=' +major+'&&filters[schoolrecord][$contains]='+forte + '&populate=*&sort=sort:desc'
	})
}

export const getCollegeLimitNeedTextDataCN= (needname) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/college-cns?filters[language]=cn&&filters[name][$contains]='+ needname + '&populate=*&sort=sort:desc'
	})
}

export const getCollegeLimitNeedIDDataCN= (needname) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/college-cns?filters[id]='+ needname + '&populate=*&sort=sort:desc'
	})
}
export const getCollegeLimitNeedIDData= (needname) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?filters[id]='+ needname + '&populate=*&sort=sort:desc'
	})
}

export const getIndex= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/a-indices?filters[language]=en&populate=*&sort=sort:desc'
	})
}

export const getIndexCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/a-indices?filters[language]=cn&populate=*&sort=sort:desc'
	})
}

export const getShowColums = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/show-columns'
	})
}