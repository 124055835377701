<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/aboutCMbg.8d6047f.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<p class="title1">{{lan?'守正创新，博雅精艺——辰美艺术学校简介':'Integrity and innovation. Learning in Breadth and in Depth  ——SISA introduction'}}<br> {{lan?'An Introduction to SISA — The home of internationally-minded Chinese innovative talents':''}}</p>
						<img class="top-img" src="../../../public/static/img/item01-img2.jpg" alt="">
						<div class="article">
							<p class="title2">
								{{lan?'辰美是一所怎样的学校？':'What kind of school is SISA?'}}
							</p>
							<p class="title3">
								<br>
								{{
                  lan?
                  '辰美是大湾区独树一帜的艺术教育标杆学校，创校以来，取得辉煌的办学成绩：连续四年与羊城晚报教育发展研究院成功举办四届广东省美育论坛，荣获羊城晚报颁发的“课程创新奖”，辰美校长兼校务委员会主席黄秋艳女士荣获“教育领航者”称号；连续四年与南海区教育发展研究中心教学研究室携手打造四届南海区“辰美杯”中小学师生创意设计比赛，为推动南海区创意设计发展做出了杰出贡献；荣获培生爱德思考试局2021年度国际学校大奖、2023年培生爱德思摘星揽月奖；荣获“2022年广东省影响力国际化学校（艺术特色）”、“广东省中小学校长联合会美育实践培训基地”、“广东省国际教育促进会国际艺术专业委员会主任单位”、“广州青年美术家协会教学实践基地”、“佛山市艺术创作院美育基地”等称号。':
                  `Stellart International School of Arts (SISA), founded in September 2020, is a privately-run secondary school specializing in the arts in South China. Funded by Gaw Capital, SISA has the mission of cultivating “Chinese creative and artistic talents with global competencies" and conducts art education that looks towards, leads and creates the future. SISA’s rich and diverse curriculum, small class sizes and personalized teaching & learning will ensure all-rounded development and high-quality growth of its students, providing students with multiple pathways for further studies.`
                  }}
							</p>
							<p class="title2">
								{{lan?'辰美在哪里？':`Where's SISA?`}}
							</p>
							<p class="title3">
								<br>
								{{lan?'辰美地处广州南站商圈，位于三龙湾南海片区科创高地，毗邻广佛佛罗伦萨小镇，交通十分便捷，南海有轨电车1号线林岳北站直达校门口，2分钟与佛山地铁2号线接驳，3站到达广州南站。':'SISA is conveniently located near the Guangzhou South Railway Station. It is situated in the Central Technology District of Sanlong Bay of Nanhai District, neighboring the Foshan Florentia Village. SISA is very accessible, being at a 10-minute driving distance from the Guangzhou South Station. It has direct access to Linyue North Station on Nanhai Tram Line 1. It takes 2 minutes to connect to the Foshan Metro Line 2 and the Guangzhou South Railway Station is just 3 stations away.'}}
							</p>
							<p class="title2">
								{{lan?'辰美的校园环境如何？':'What is SISA’s campus environment like?'}}
							</p>
							<p class="title3">
								<br>
								{{lan?'辰美拥有集开放性、多元化、艺术感为一体的校园环境，完善的专业场室、运动设施、生活配套，使辰美成为学子们学习和成长的梦想校园。学校配备了平面设计工作坊、3D设计工作坊、服装设计工作坊、雕塑大师工作坊等在内的三十几间艺术设计与制作专业教学场室，配备了录音室、音乐制作室、乐器练习室和两个大型音乐表演厅在内的三十几间音乐表演专业教学场室，基汇资本遍及全球的企业为辰美学子提供实习实践基地，辰美给学生创设了一个完美的艺术培养和实践基地。':`Stellart has an open, diverse, and artistic campus environment, well-equipped with professional classrooms, sports and supporting facilities, making SISA an ideal campus for students to learn and grow. The school is equipped with more than 30 professional classrooms for art design and production (including a graphic design studio, 3D design studio, fashion design studio, sculpting studio, etc.) and more than 30 professional classrooms for music performance (including a recording studio, music production studio, musical instrument practice studio and two large music performance halls). Stellart has created a perfect arts development and practice base for students.`}}
							</p>
						</div>
						<div class="idea">
							<p class="title5">{{lan?'辰美的办学理念和愿景':`SISA’s Philosophy and Vision`}}</p>
							<div class="item-wrap">
								<img class="item-img" src="../../../public/static/img/idea.png" alt="">
								<div class="idea-item">
									<div class="idea-item1">
										<p class="title6">{{lan?'辰美愿景':'SISA’s Vision:'}}</p>
										<p class="title7">{{lan?'美育全球':'Connecting the World Through Arts'}}</p>
									</div>
									<div class="idea-item1">
										<p class="title6">{{lan?'辰美使命':'SISA’s Mission:'}}</p>
										<p class="title7">{{lan?'培养具有全球胜任力的中国艺术创新人才':'To nurture the next generation of Chinese creative and artistic talents with global competencies'}}</p>
									</div>
									<div class="idea-item1">
										<p class="title6">{{lan?'辰美价值观':'SISA’s Philosophy:'}}</p>
										<p class="title7">{{lan?'以美育人，向美而行':'To educate people with art, to pursue lives with aesthetics'}}</p>
									</div>
									<div class="idea-item1">
										<p class="title6">{{lan?'辰美校训':'SISA’s Motto:'}}</p>
										<p class="title7">{{lan?'守正创新，博雅精艺':'To innovate with integrity, to pursue knowledge with an open mind and delicacy.'}}</p>
									</div>
								</div>
							</div>
						</div>
						<div class="bottom-warp" v-if="false">
							<div class="bottom-item">
								<p class="big-word">{{lan?'“真”':''}}</p>
								<p class="normal-word">{{lan?'代表的是真理和真我，辰美希望学生能不畏挑战社会对事物已有的认知，敢于自我表达，敢于探索真相；':''}}</p>
							</div>
							<div class="bottom-item">
								<p class="big-word">{{lan?'“美”':''}}</p>
								<p class="normal-word">{{lan?'代表的是审美，辰美希望学生善于欣赏美、发现美和创造美，培养出属于自己的审美方式，注重细节，尊重他人；':''}}</p>
							</div>
							<div class="bottom-item">
								<p class="big-word">{{lan?'“新”':''}}</p>
								<p class="normal-word">{{lan?'代表的是创新和想象力，辰美希望学生能对未来世界充满想象，打破既定的思维模式，勇于思考和创新；':''}}</p>
							</div>
							<div class="bottom-item">
								<p class="big-word">{{lan?'“异”':''}}</p>
								<p class="normal-word">{{lan?'代表的是个性，辰美希望学生不畏惧彰显个性，坚持自己的理念和想法，并能自信地向大众展示自己的独特性。':''}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'introduction',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				tip:1,
				tipNumber:1,
        lan:1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		mounted() {
      const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
				this.lan = 0
			}else if(language == 'en'&&newLanguage=='cn'){
				this.lan = 1
			}
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeIndex(){
				this.$router.push("/");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}

	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .54rem;
		/* left: .5rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.title1{
		line-height: .4rem;
		font-size: .4rem;
		font-weight: 700;
		color: #0e183f;
	}
	.top-img{
		width: 9.56rem;
		/* height: 2.31rem; */
		margin-top: .72rem;
		margin-bottom: .14rem;
	}
	.article .title2{
		padding-top: .36rem;
		line-height: .36rem;
		font-size: .3rem;
		font-weight: 700;
		color: #0e183f;
	}
	.article .title3{
		line-height: .36rem;
		font-size: .18rem;
		font-weight: 400;
		color: #3d4460;
		margin-top: .1rem;
	}
	.article .title4{
		line-height: .36rem;
		font-size: .18rem;
		font-weight: 400;
		color: #3d4460;
		margin-top: .36rem;
	}
	.idea .title5{
		line-height: .36rem;
		font-weight: 700;
		margin-top: .99rem;
		margin-bottom: .47rem;
		font-size: .3rem;
		color: #0e183f;
	}
	.item-wrap{
		display: flex;
		height: 6.01rem;
		position: relative;
	}
	.item-wrap .item-img{
		width: .51rem;
		position: absolute;
		left: -.2rem;
		
	}
	.idea-item1{
		margin-left: .4rem;
		margin-bottom: .3rem;
		width: 9rem;
		border-bottom: 1px solid rgba(14,24,60,.3);
	}
	.idea .title6{
		line-height: .36rem;
		font-weight: 700;
		margin-top: .15rem;
		font-size: .25rem;
		color: #d7a16f;
	}
	.idea .title7{
		padding: .2rem 0 .25rem;
		line-height: .36rem;
		font-size: .2rem;
		font-weight: 400;
		color: #d7a16f;
	}
	.bottom-warp{
		margin-top: .3rem;
	}
	.bottom-item{
		display: flex;
	}
	.big-word{
		line-height: .36rem;
		font-size: .18rem;
		font-weight: 700;
		color: #0e183f;
	}
	.normal-word{
		line-height: .36rem;
		font-size: .18rem;
		font-weight: 400;
		color: #3d4460;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
</style>