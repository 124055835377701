<template>
	<div class="footer">
    <template v-if="lan">
      <img class="footer-logo" src="../../../public/static/img/logo.png" alt="">
      <div class="footer-address">
        <p>网站版权归辰美所有</p>
        <p>广东省佛山市南海区三山新城疏港路26号辰美艺术学校</p>
        <p class="footer-txt"><a href="https://beian.miit.gov.cn/" target="_blank">备案号：粤ICP备19030818号</a></p>
        <p class="footer-phone">
        联系电话：0757-86796295
        </p>
      </div>
      <div class="footer-icon">
        <div class="footer-wx">
          <img class="wx-img" src="../../../public/static/img/footer-wx.png" alt="">
          <p>关注我们</p>
          <img class="wx-code" src="../../../public/static/img/footer-code.png" alt="">
        </div>
      </div>
      <div class="ip-come" @click="detailsRight">
        系统入口
      </div>
      <div class="come-top" :class="{
        'come-top-show': showBtnA,
        'come-top-hidden': hiddenBtn,
      }" @click="comeTop"></div>
      <div class="details-bg" v-if="detailShow">
        <div class="details-box">
          <div class="oa-box">
            <div class="oa">
              <a href="https://sisa.managebac.cn/login" target="_blank">
                <img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg" alt="">
                <p>ManageBac</p>
              </a>
            </div>
          </div>
          <img class="icon-close" src="../../../public/static/img/icon-close.png" alt="" @click="detailsClose">
        </div>
      </div>
      <div class="sidebar">
        <img class="sidebar-img1" src="../../../public/static/img/边栏-在线报名.png" @click="comeMyself(`/information/apply`)" alt="">
        <img class="sidebar-img2" src="../../../public/static/img/边栏-实时资讯.png" @click="comeMyself(`/information/message`)" alt="">
      </div>
    </template>
    <template v-else>
		<img class="footer-logo" src="../../../public/static/img/logo.png" alt="">
      <div class="footer-address">
        <p>© Stellart International School of Arts (SISA) 2023. All rights reserved</p>
        <p>SISA, No.26 Shugang Road, Guicheng,Nanhai District, Foshan City, Guangdong Province</p>
        <p class="footer-txt"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19030818号</a></p>
        <p class="footer-phone">Phone :0757-86796295</p>
      </div>
      <div class="footer-icon">
        <div class="footer-wx">
          <img class="wx-img" src="../../../public/static/img/footer-wx.png" alt="">
          <p>Follow Us</p>
          <img class="wx-code" src="../../../public/static/img/footer-code.png" alt="">
        </div>
      </div>
      <div class="ip-come en" @click="detailsRight">
        System Portal
      </div>
      <div class="come-top" :class="{
        'come-top-show': showBtnA,
        'come-top-hidden': hiddenBtn,
      }" @click="comeTop"></div>
      <div class="details-bg" v-if="detailShow">
        <div class="details-box-en">
          <div class="oa-box">
            <div class="oa">
              <a href="https://sisa.managebac.cn/login" target="_blank">
                <img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg" alt="">
                <p>ManageBac</p>
              </a>
            </div>
          </div>
          <img class="icon-close" src="../../../public/static/img/icon-close.png" alt="" @click="detailsClose">
        </div>
      </div>
      <div class="sidebar">
        <img class="sidebar-img1" src="../../../public/static/img/reference.png" @click="comeMyself(`/information/apply`)" alt="">
        <img class="sidebar-img2" src="../../../public/static/img/online.png" @click="comeMyself(`/information/message`)" alt="">
      </div>
    </template>
	</div>
</template>

<script>
	export default{
		name:'foot',
		data(){
			return{
        detailShow:false,
        showBtnA:false,
        hiddenBtn:true,
        lan: 1
			}
		},
		props: {
			// detailShow:false,
			// showBtnA:false,
			// hiddenBtn:true,
		},
		mounted() {
      const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
        // 英文版
				this.lan = 0
			}else if(language == 'en'&&newLanguage=='cn'){
        // 中文版
				this.lan = 1
			}
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
      showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtnA = true;
						this.hiddenBtn = false;
				} else {
						this.showBtnA = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				var timer = setInterval(function () {
            let osTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            let ispeed = Math.floor(-osTop / 5);
            document.documentElement.scrollTop = document.body.scrollTop =
                osTop + ispeed;
            this.isTop = true;
            if (osTop === 0) {
                clearInterval(timer);
            }
        }, 30);         
			}
		}
	}
</script>

<style scoped>
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt a{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt a:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
  .ip-come.en{
    width: 0.7rem;
    height: 0.7rem;
    line-height: 0.3rem;
		font-size: .18rem;
    transform: scale(0.8);
    transform-origin: right top;
  }
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.sidebar{
		width: 1.08rem;
		height: 2.16rem;
		position: fixed;
		right: 0;
		bottom: 2rem;
		z-index: 99;
		flex-direction: column;
		display: flex;
	}
	.sidebar img{
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
	}
	.need-line{
		top: 3rem;
		left: 0;
		-webkit-line-clamp: 11;
		letter-spacing: -.01rem;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.details-box-en{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.jpg) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.sidebar-img1{
		cursor: pointer;
	}
	.sidebar-img2{
		cursor: pointer;
	}
</style>