<template>
	<div class="info">
		<div class="w info-item">
			<!-- <div class="school-introduce">{{lan?'学校介绍':''}}</div> -->
			<div class="school-introduce">An Introduction to SISA</div>
			<div class="school-img"><img src="../../../public/static/img/item01-img3.png" alt=""></div>
			<div class="school-pic"><img src="../../../public/static/img/item01-img2.jpg" alt=""></div>
			<div class="school-icon"><img src="../../../public/static/img/item01-img1.png" alt=""></div>
			<div class="school-txt">
			
				Stellart International School of Arts (SISA), founded in September 2020, is a privately-run secondary school specializing in the arts in the Guangzhou-Foshan locality. Within a short span of three years, SISA has gained its reputation as a top arts school in the Greater Bay Area, having sent students to prestigious universities globally.
				<br>
				<br>
				In March 2023, SISA has further received the license to provide full upper secondary program with dual progression tracks to both domestic and international institutions, attracting middle school graduates from Guangdong to study music and art and design at the school.
  
			</div>
		</div>
		<div class="school-team w">
			<div class="team-title">
				<div class="title-img">
					<img src="../../../public/static/img/teacherteam.png" alt="">
				</div>
				<!-- <div class="team-txt">
					教师团队
				</div> -->
			</div>
			<div class="teacher">
				
					<!-- Swiper -->
					<div class="swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide ">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/5eb46a467ed1daa6e3a1538ca51a9843.png" alt="">
									</div>
									<p class="teacher-name">
										Ellen Huang
									</p>
									<p class="teacher-work">
										School Principal/Chairman of the SISA School Management Committee
									</p>
									<p class="describe">
										Ms. Huang has been an outstanding school leader within the Nanhai region. She is the Vice President of Guangdong Association for the Promotion of International Education (GDAPIE), and is the Director of Guangdong International Arts Education Professional Committee. She holds a Gold Award for Sino-British School Exchange in South China from the British Council. The Guangzhou Daily named her the "Spokesperson of Guangzhou-Foshan Education". She is a veteran educator with extensive experience establishing many highly-regarded primary, secondary and international schools through the Guangzhou-Foshan area.
									</p>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/767451d11d9f78893d8b03b71bd3750a.png" alt="">
									</div>
									<p class="teacher-name">
										Grace Chen
									</p>
									<p class="teacher-work">
										Honorary Principal
									</p>
									<p class="describe">
										Ms. Chen is a Super Grade Principal in China. She was named as one of the Most Influential International School Principals in China in 2018. She is also the Deputy Chair of Guangdong Province Study Abroad Service Association’s International School Committee, an Adjunct Professor of South China Normal University’s College of Teacher Education, and Founding President of the Affiliated Foreign Language School of South China Normal University.
									</p>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/4ec084b79d36782bc1058975a7d4b9c2.png" alt="">
									</div>
									<p class="teacher-name">
										Roy White
									</p>
									<p class="teacher-work">
										Principal Consultant
									</p>
									<p class="describe">
									Mr. White is a member of the Council of International Schools (CIS). He served as a member of the British Schools Foundation and was the founding principal of a renowned international school in Hong Kong. He has experience as a principal of international schools in the UK, the Netherlands, Northern Europe, and many other countries. CIS highly praises the innovative vision of each school he has led.
									</p>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/8263460627f530a84488dbc5eed8deab.png" alt="">
									</div>
									<p class="teacher-name">
										Howard Zhang
									</p>
									<p class="teacher-work">
										Executive Vice Principal
									</p>
									<p class="describe">
										Mr. Zhang received his Master’s degree from Beijing Foreign Studies University. He serves as the Deputy Director of the Guangdong Youth Artificial Intelligence Council, a subject leader of the Information Technology in Nanhai District, a gold medal coach in Information Technology in Nanhai District, and amongst the very first batch of Maker Mentors in Foshan City. He also participated in the establishment of several private international schools including the Nanhai Foreign Language School.
									</p>
								</div>
							</div>
							<!-- <div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/159fde1da611a42af5d616daf6f6a6d9.png" alt="">
									</div>
									<p class="teacher-name">
										Ted Sze
									</p>
									<p class="teacher-work">
										Vice Principal/Art Consultant
									</p>
									<p class="describe">
										Mr. Sze is a postgraduate in Interior and Spatial Design from Chelsea College of Arts, University of the Arts London (UAL), and an official invigilator and examination marker approved by British Pearson Edexcel. He co-designed the postgraduate preparatory courses of UAL in China and has more than 10 years of educational experience in international art diplomas and art high schools. Over the years, he has helped more than 1,000 students gain admission to esteemed overseas art schools.
									</p>
								</div>
							</div> -->
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/00f058c64ff277f7f6a0b8a5113f684a.png" alt="">
									</div>
									<p class="teacher-name">
										Angela Li
									</p>
									<p class="teacher-work">
										External Relationship Director
									</p>
									<p class="describe">
										Ms. Li graduated from the University of California San Diego with a Bachelor degree in Management Science (Latin Honors) and double minors in Business and Psychology. She also earned a certificate in marketing strategy from Cornell University. She worked in the consulting industry in the United States and the venture capital industry in Hong Kong. She has rich educational resources from universities in the United Kingdom, the United States, and Hong Kong.	
									</p>
								</div>
							</div>
							<!-- <div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220801/0b7a97e239bb433f2b33839ccda82d14.jpg" alt="">
									</div>
									<p class="teacher-name">
										Martin D. Bills
									</p>
									<p class="teacher-work">
										Dean of Music
									</p>
									<p class="describe">
										Mr. Bills is a veteran music educator and composer. Mr. Bills had worked with various theatres and music groups in the UK and his composition works are published worldwide. He served as the head of jazz in British universities and taught music composition and appreciation in many universities including Leeds Conservatoire.
									</p>
								</div>
							</div> -->
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20210719/fc1300635e31c3b9dc6af82d08f5d65b.png" alt="">
									</div>
									<p class="teacher-name">
										Edison Yang
									</p>
									<p class="teacher-work">
										Director of Teaching and Research/Director of Business
									</p>
									<p class="describe">
										Mr. Yang graduated from Curtin University in Australia and the University of Hong Kong with a postgraduate degree in accounting and finance. As an Australian certified accountant, he worked at an international financial institution. Before joining SISA, he taught in universities in China and Australia, and served on internal curriculum audits and as Vice Dean of Teaching Supervision. He holds the Australian higher education teacher qualification, the International Baccalaureate Teacher Certificate, and the English Teaching Certificate of the Cambridge Assessment International Education.
									</p>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/8285f45fed7b8da89f06044dc00c20fe.png" alt="">
									</div>
									<p class="teacher-name">
										Brian Reverman
									</p>
									<p class="teacher-work">
										Art Consultant
									</p>
									<p class="describe">
										Mr. Reverman earned a BFA (Bachelor of Fine Arts) from the University of Dayton and a MFA (Master of Fine Arts) from Ohio State University. He is an artist and educator with over 30 years of experience, author of the "Practical Guides for the Idealistic Art Teacher" book series, and Producer of ISB Art History Channel on YouTube. He acts as the Visual Arts consultant for Inspire Citizens, and as Administrator of the Asian Region Art Educators organization (Asian Region Art Teachers Union).	
									</p>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="teacher-introduce">
									<div class="teacher-img">
										<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/9da91f58cd585a177ce895edadd68b79.png" alt="">
									</div>
									<p class="teacher-name">
										Colin Kay
									</p>
									<p class="teacher-work">
										Educational Consultant
									</p>
									<p class="describe">
										Mr. Kay graduated from the University of Cambridge and holds an MBA in Educational Leadership. He is a member of the Royal Society of Arts and was a UK high school principal for many years. Currently, he is an educational consultant at the Bristol Academies Trust and an associate lecturer at Bath Spa University on an educational leadership postgraduate course.	
									</p>
								</div>
							</div>
				
						</div>
						
						<div class="ture-left-botton"></div>
						<div class="ture-right-button"></div>
					</div>	
			</div>
			<img class="more-img" src="../../../public/static/img/more-black1.png" @click="comeMyself(`/about/architectureAndTeam`)" alt="">
		</div>	
		<div class="object w">
			<div class="object-box">
				<img class="object-icon" src="../../../public/static/img/item03-img1.png" alt="">
				<img class="object-title-icon" src="../../../public/static/img/item03-img3.png" alt="">
				<img class="object-teacher-img" src="../../../public/static/img/item03-img2.jpg" alt="">
				<!-- <div class="object-title">
					课程介绍
				</div> -->
				<div class="object-content">
					SISA’s rich and diversified curriculum, small class sizes and personalized teaching support ensures all-round development and high-quality growth for students, while providing multiple paths to higher education. SISA students may take the National College Entrance Examination (Gaokao) to enter Project 211, Project 985, Double First-Class universities, professional music and art academies in China. SISA students can also choose to take the Vocational High School College Entrance Examination (Gaozhi Gaokao, “3+X”) for admission into professional art and music academies or vocational colleges. Furthermore, students can use the internationally recognized qualification, art portfolios and language scores to apply to renowned arts schools in the United States, England, Australia, Japan, Southeast Asia and other countries and regions.        
				</div>
			</div>
			<div class="school-object-list">
				<div class="sisa-object" @click="comeMyself(`/teaching/sixCollegesList`)">
					<img class="sisa-img" src="../../../public/static/img/item03-img4.png" alt="">
					<div class="sisa">
						<div class="sisa-title">
							Art and Design
						</div>
						<!-- <div class="sisa-content">艺术学院</div> -->
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
				<div class="sisa-object" @click="comeMyself(`/teaching/commercial`)">
					<img class="sisa-img" src="../../../public/static/img/item03-img5.png" alt="">
					<div class="sisa">
						<div class="sisa-title">Music Performance</div>
						<!-- <div class="sisa-content">商学院</div> -->
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
				<div class="sisa-object" @click="comeMyself(`/planning/service`)">
					<img class="sisa-img" src="../../../public/static/img/item03-img6.png" alt="">
					<div class="sisa">
						<div class="sisa-title">UNIVERSITY COUNSELING</div>
						<!-- <div class="sisa-content">升学</div> -->
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
			</div>
			<div class="object-more">
				<img class="object-more-img" src="../../../public/static/img/more-white1.png" @click="comeMyself(`/teaching/sixColleges`)" alt="">
			</div>
		</div>
		<div class="school-style w">
			<div class="school-style-box">
				<img class="style-img" src="../../../public/static/img/item04-img2.png" alt="">
				<img class="style-title-icon" src="../../../public/static/img/item04-img3.png" alt="">
				<img class="style-icon" src="../../../public/static/img/item04-img1.png" alt="">
				<!-- <div class="school-style-title">校园风采</div> -->
				<div class="school-style-txt need-line">
					SISA has an open, diverse, and artistic campus environment, well-equipped with studio classrooms, sports and supporting facilities, making SISA a dream campus for our students to learn and grow. The school is equipped with more than 30 studio classrooms for art design and production (including graphic design studio, 3D design studio, fashion design studio, sculpting studio, etc.) and more than 30 classrooms for music performance (including a recording studio, music production studio, musical instrument practice rooms and two large music performance halls). Stellart has created a perfect arts development and practice base for students.
				</div>
			</div>
			<div class="school-object-list" v-if="false">
				<div class="sisa-object" @click="comeMyself(`/information/rules`)">
					<img class="sisa-img" src="../../../public/static/img/item04-img4.png" alt="">
					<div class="sisa">
						<div class="sisa-title">
							Admissions and Prospect
						</div>
						<div class="sisa-content">招生简章</div>
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
				<div class="sisa-object" @click="comeMyself(`/life/campusNews`)">
					<img class="sisa-img" src="../../../public/static/img/item04-img5.png" alt="">
					<div class="sisa">
						<div class="sisa-title">News</div>
						<div class="sisa-content">校园动态</div>
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
				<div class="sisa-object" @click="comeMyself(`/life/TVstation`)">
					<img class="sisa-img" src="../../../public/static/img/item03-img6.png" alt="">
					<div class="sisa">
						<div class="sisa-title">Videos</div>
						<div class="sisa-content">原创视频</div>
					</div>
					<div class="sisa-mask">
						
					</div>
				</div>
			</div>
			<div class="object-more" @click="comeMyself(`/life/campusNews`)">
				<img class="object-more-img" src="../../../public/static/img/more-white1.png" alt="">
			</div>
		</div>
		<div class="greet-link">
			<!-- <p class="greet-title">辰美欢迎您</p> -->
			<img class="greet-title-icon" src="../../../public/static/img/item05-img1.png" alt="">
			<p class="greet-txt">
				
		SISA is just waiting for you! Click on the link below
		<br />Learn about the information art of sisa's enrollment introduction, admission method, registration, etc.
	
			</p>
			<img class="greet-message" src="../../../public/static/img/icon-more2G1.png" @click="comeMyself(`/information/rules`)" alt="">
			<div class="greet-my">
				<img src="../../../public/static/img/icon-more3G1.png" @click="comeMyself(`/contact/Consultation`)" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from "../../api/swiper.min.js"
	export default{
		name:'contentLan',
		data(){
			return{
				active:0,
			}
		},
		mounted() {
			this.swiperCon()
		},
		methods:{
			swiperCon(){
				var swiper = new Swiper('.swiper-container', {
				      slidesPerView: 6,
				      spaceBetween: 30,
					  // centeredSlides: true,
					  // loop: true,
				      pagination: {
				        el: '.swiper-pagination',
				        clickable: true,
				      },
							navigation: {
							  nextEl: ".ture-right-button",
							  prevEl: ".ture-left-botton",
								disabledClass: 'my-button-disabled',
							},
							on:{
								transitionEnd:()=>{
									this.active = swiper.activeIndex;
									// console.log(this.active)
								}
							}
				    });
						 var lis = document.querySelectorAll(".swiper-slide");
						 var list = document.getElementsByClassName("swiper-container ")[0];						 
						 lis.forEach(item => {
						     item.onmouseover = () => {
									 var x = list.childNodes[0].childNodes[this.active];
						        //先全部清空选中，再给当前添加选中
						        lis.forEach(item => item.classList.remove('swiper-slide-active'));
										// console.log(x)
										// console.log(item.classList)
						        item.classList.add('swiper-slide-active');
						     }
						 })
						 lis.forEach(item => {
						     lis.forEach(item => {
						         item.onmouseout = () => {
												var x = list.childNodes[0].childNodes[this.active];
						            //先全部清空选中，再给当前添加选中
						            lis.forEach(item => item.classList.remove('swiper-slide-active'));
												x.classList.add('swiper-slide-active');
						         }
						     })
						 })
					// swiper.progress;
					// var pe = document.getElementById('progressEffect');
					// pe.onchange = function(){ 
					//     effect = this.value
					// 	swiper.update();	
					// }
			},
		}
	}
</script>

<style scoped>
	.w{
		width: 14.41rem;
		margin: 0 auto;
	}
	.info{
		width: 100%;
		height: auto;
	}
	.info-item{
		position: relative;
		width: 14.41rem;
		height: 6.22rem;
		background-color: white;
		margin-top: 2.5rem;
		margin-bottom: 1.27rem;
	}
	.school-introduce{
		position: absolute;
		left: 0;
		font-weight: 400;
		color: #3e3a39;
		top: .02rem;
		font-size: .3rem;
		line-height: .4rem;
	}
	.school-img{
		position: absolute;
		width: 5.08rem;
		position: absolute;
		left: .02rem;
		top: .58rem;
		z-index: 2;
	}
	.school-pic{
		width: 10.15rem;
		height: 5.7rem;
		position: absolute;
		right: 0;
		bottom: .52rem;
		z-index: 1;
	}
	.school-img img{
		width: 100%;
		height: 100%;
	}
	.school-pic img{
		width: 100%;
		height: 100%;
	}
	.school-txt{
		position: absolute;
		width: 3.6rem;
		font-size: .16rem;
		line-height: .28rem;
		letter-spacing: -.01rem;
		text-align: justify;
		top: 3.05rem;
		left: 0;
		font-weight: 400;
		color: #3e3a39;
	}
	.school-icon{
		width: 1.05rem;
		height: auto;
		position: absolute;
		right: -.52rem;
		bottom: 0;
		z-index: 2;
	}
	.team-title{
		width: 100%;
		margin-bottom: .35rem;
	}
	.school-team{
		height: 8.66rem;
		margin-bottom: 4.38rem;
		position: relative;
	}
	.title-img img{
		display: block;
		margin: 0 auto;
		width: 3.68rem;
	
	}
	.team-txt{
		font-size: .4rem;
		padding: 0;
		margin-top: .1rem;
		text-align: center;
	}
	.teacher{
		margin: 0 .34rem;
		padding: .57rem .41rem 0;
		overflow: hidden;
	}
	.teacher-introduce{
		width: 100%;
		height: 100%;
	}
	.teacher-name{
		font-size: .16rem;
		font-weight: 700;
		padding: 0;
		color: #1f2c5c;
		opacity: 1;
	}
	.teacher-work{
		/* font-size: .16rem; */
		font-size: .12rem;
		font-weight: 700;
		padding: 0;
		color: #1f2c5c;
		opacity: 1;
	}
	.teacher .describe{
		font-size: .12rem;
		line-height: .24rem;
		width: 2.44rem;
		margin: .06rem -.4rem;
		height: auto;
		font-weight: 400;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 20;
		-webkit-box-orient: vertical;
		text-align: justify;
		opacity: 0;
		transition: all .4s;
		-webkit-transition: all .4s;
		border-top: 1px solid #bab9b9;
		border-bottom: 1px solid #bab9b9;
		padding: 2px 0;
	}
	/* .teacher-introduce:hover .describe{
		opacity: 1;
	} */
	/* .teacher-introduce:hover .teacher-img img{
		padding: .15rem;
		border-radius: 50%;
		cursor: pointer;
		z-index: 10;
		border: .01rem solid #939ab1;
		transition: 1s;
		margin-bottom: 30px;
		transform: scale(1.5);
	} */
	.ture-right-button{
		    background: url(../../../public/static/img/icon-right.png) no-repeat top/100% auto;
		    width: .39rem;
		    height: 1.14rem;
		    position: absolute;
		    top: 2.05rem;
		    right: -.1rem;
	}
	.teacher .ture-left-botton{
		background: url(../../../public/static/img/icon-left.png) no-repeat top/100% auto;
		    width: .39rem;
		    height: 1.14rem;
		    position: absolute;
		    top: 2.05rem;
		    left: -.1rem;
	
	}
	.swiper {
	    width: 100%;
	    /* height: 100%; */
			overflow: hidden; 
	}
	.banner .swiper-slide {
	    text-align: center;
	    font-size: 18px;
	    background: #fff;
	    /* Center slide text vertically */
	    display: -webkit-box;
	    display: -ms-flexbox;
	/*    display: -webkit-flex;
	    display: flex; */
	    -webkit-box-pack: center;
	    -ms-flex-pack: center;
	    -webkit-justify-content: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    -webkit-align-items: center;
	    align-items: center;
	}
	.banner .swiper-wrapper{
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
	}
	.banner .swiper-slide img {
	    width: 100%;
	        height: 9.21rem;
	        margin-top: 1.23rem;
	        display: flex;
	}
	    .swiper-container {
	      width: 100%;
	      height: auto;
				/* padding:90px 0; */
	      margin-left: auto;
	      margin-right: auto;
				/* overflow: hidden; */
	    }
	  .swiper-container  .swiper-slide {
	      text-align: center;
	      font-size: 18px;
	      /* background: #fff; */
	    	background: transparent;
	/* 			width: 121.975px !important;
				margin-right: 42.83px !important; */
	      /* Center slide text vertically */
	       display: -webkit-box;
	            display: -ms-flexbox;
	            display: -webkit-flex;
	            display: flex;
	            -webkit-box-pack: center;
	            -ms-flex-pack: center;
	            -webkit-justify-content: center;
	            justify-content: center;
	            -webkit-box-align: center;
	            -ms-flex-align: center;
	       /*     -webkit-align-items: center;
	            align-items: center; */
	    }
		.teacher  img{
			width: 1.33rem;
			height: 1.33rem;
			border-radius: 50%;
			/* transition: all 0.5s; */
	
			/* align-items: center; */
	
		}
		.more-img{
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 8rem;
			width: 1.22rem;
			margin: auto;
			display: block;
			cursor: pointer;
		}
		.swiper-slide-active .teacher-introduce .teacher-img img{
			padding: .15rem;
			border-radius: 50%;
			cursor: pointer;
			z-index: 10;
			border: .01rem solid #939ab1;
			/* border: 1px solid #000; */
			/* opacity: 0.2; */
			transition: 0.6s;
			margin-bottom: 30px;
			transform: scale(1.5);
			position: relative;
			z-index: 10;
		}
		.swiper-slide-active .describe{
			opacity: 1;
		}
		.my-button-disabled{
			opacity: 0;
		}
		.object-box{
			width: 14.41rem;
			height: 6.65rem;
			margin-bottom: 1.19rem;
			position: relative;
		}
		.object-icon{
			width: 1.05rem;
			height: auto;
			position: absolute;
			left: -.52rem;
			bottom: 0;
			z-index: 2;
		}
		.object-teacher-img{
			width: 9.19rem;
			height: 6.13rem;
			position: absolute;
			left: 0;
			bottom: .52rem;
			z-index: 1;
		}
		.object-title-icon{
			width: 7.22rem;
			position: absolute;
			right: .02rem;
			top: .75rem;
			z-index: 2;
		}
		.object-title{
			position: absolute;
			right: 0;
			top: .18rem;
			font-size: .4rem;
			line-height: .4rem;
			font-weight: 400;
	    color: #fff;
		}
		.object-content{
			position: absolute;
			right: 0;
			font-weight: 400;
			color: #fff;
			width: 4.6rem;
			font-size: .16rem;
			line-height: .28rem;
			text-align: justify;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			top: 3.3rem;
		}
		.sisa-object{
			cursor: pointer;
			position: relative;
		}
		.school-object-list{
			display: flex;
			justify-content: space-between;
		}
		.sisa-img{
			width: 4.59rem!important;
			height: 4.59rem!important;
		}
		.sisa{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			height: 1rem;
			z-index: 3;
			align-items: center;
			display: flex;
			flex-direction: column;
		}
		.sisa-title{
			font-weight: 700;
			font-size: .28rem;
			line-height: .46rem;
			border-bottom: 2px solid #fff;
		}
		.sisa-content{
			font-weight: 400;
			font-size: .2rem;
			line-height: .28rem;
			margin-top: .27rem;
		}
		.sisa-title,.sisa-content{
			color: #fff;
			transition: all .3s;
			-webkit-transition: all .3s;
		}
		.sisa-mask{
			position: absolute;
			top: 0;
			width: 4.59rem;
			height: 4.59rem;
			opacity: 0;
			background-color: rgba(0,0,0,.4);
			z-index: 2;
		}
		.sisa-object:hover .sisa-mask{
			transition: 0.6s;
			opacity: 1;
		}
		.sisa-object:hover .sisa-content{
			transition: 0.6s;
			font-size: .27rem;
		}
		.sisa-object:hover .sisa-title{
			transition: 0.6s;
			font-size: .32rem;
		}
		.object-more{
			margin-top: .89rem;
			width: 1.22rem;
			position: relative;
			left: 44%;
		}
		.object-more-img{
			position: absolute;
			width: 1.22rem;
			margin: auto;
			display: block;
			cursor: pointer;
		}
		.object{
			width: 14.41rem;
			margin: auto auto 3.04rem;
		}
		.school-style-box{
			width: 14.41rem;
			height: 6.22rem;
			margin-bottom: 1.19rem;
			position: relative;
		}
		.style-img{
			width: 10.14rem;
			height: 5.7rem;
			position: absolute;
			right: 0;
			bottom: .52rem;
			z-index: 1;
		}
		.style-title-icon{
			width: 8.89rem;
			position: absolute;
			left: .02rem;
			top: .59rem;
			z-index: 2;
		}
		.style-icon{
			width: 1.05rem;
			height: auto;
			position: absolute;
			right: -.52rem;
			bottom: 0;
			z-index: 2;
		}
		.school-style-title{
			top: .01rem;
			left: 0;
			position: absolute;
			right: 0;
			font-weight: 400;
			color: #fff;
			font-size: .4rem;
			line-height: .4rem;
		}
		.school-style-txt{
			position: absolute;
			right: 0;
			font-weight: 400;
			color: #fff;
			top: 3rem;
			left: 0;
			-webkit-line-clamp: 15;
			letter-spacing: -.01rem;
			width: 3.6rem;
			font-size: .16rem;
			line-height: .28rem;
			text-align: justify;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
		}
		.school-style{
			margin-bottom: 7.68rem;
		}
		.greet-link{
			padding-bottom: 7.77rem;
		}
		.greet-title{
			font-size: .4rem;
			line-height: .4rem;
			font-weight: 400;
			color: #3e3a39;
			margin-bottom: .21rem;
			text-align: center;
		}
		.greet-title-icon{
			width: 4.54rem;
			height: auto;
			display: block;
			margin: auto auto .9rem;
		}
		
		.greet-message{
			width: 1.22rem;
			left: 7.8rem;
			position: absolute;
			margin: auto;
			display: block;
			cursor: pointer;
		}
		.greet-my{
			left: 10.18rem;
			width: 1.22rem;
			cursor: pointer;
			position: relative;
		}
		.greet-my img{
			position: absolute;
			width: 1.22rem;
			margin: auto;
			display: block;
			cursor: pointer;
		}

</style>
<style scoped>
	.greet-txt{
			font-size: .2rem;
			font-weight: 400;
			color: #000;
			line-height: .28rem;
			text-align: center;
			margin-bottom: .45rem;
		}
</style>