<template>
	<div class="headerlan " :class="[ifCN==0 ? 'header-lan': 'header-lanCN']" style="cursor: pointer;" >
		<!-- <span  @click="comeMyself(`/indexCN`)">•&nbsp;中文</span>&nbsp;/&nbsp;<span class="active">ENG</span> -->
		<span class="cnspan"  :class="[ifCN==0 ? '': 'active']"  @click="changeLanguage()">•&nbsp;中文</span>&nbsp;/&nbsp;<span class="enspan" :class="[ifCN==0 ? 'active': '']" @click="changeLanguageEN()">ENG</span>
	</div>
</template>

<script>
	export default{
		name:'headerLan',
		data(){
			return{
				ifCN:0,
			}
		},
		mounted() {
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage')
			// this.headerList[this.tip].active = 1
			if(language=='en'&&newLanguage==null){
				this.ifCN=0
			}else if(language == 'en'&&newLanguage=='cn'){
				this.ifCN=1
			}
		},
		methods:{
			changeLanguage(){
				window.localStorage.setItem("NewLanguage",'cn')
				location.reload();
			},
			changeLanguageEN(){
				window.localStorage.setItem("language",'en')
				window.localStorage.removeItem('NewLanguage')
				location.reload();
			}
		}
	}
</script>

<style>
	.header-lanCN{
		padding-left: 6.46rem;
		margin-top: .35rem;
		/* margin-top: .8rem; */
		margin-bottom: 0.12rem;
		position: relative;
	}
	.header-lan{
		padding-left: 7.66rem;
		margin-top: .35rem;
		margin-bottom: 0.12rem;
		position: relative;
	}
	.active{
		color: #992b2d;
	}
</style>