<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/planningbg.c0f4821.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<p class="title50">
							{{lan?'辰美升学指导服务':`University Counseling Service`}}
							<br>
							{{lan?'University Counseling Service':``}}
						</p>
						<img src="../../../public/static/img/service-img1.jpg" class="img" alt="">
						<p class="title22">{{lan?'1、课程咨询':'1、Course consultation'}}</p>
						<p class="text22R">
							{{lan?'了解学生留学意向，如国家、理想院校及意向专业等。':`Understand the student's intention to study abroad, such as the country, the ideal university and the intended major.`}}
							<br>
							<br>
						</p>
						<p class="title22">{{lan?'2、教学评估':`2、Academic evaluation`}}</p>
						<p class="text22R">
							{{lan?'评估学生专业基础与能力，为学生定制预科的生涯规划。':`Evaluate students' professional foundation and ability, and customize the preparatory career plan for students.`}}
							<br>
							<br>
						</p>
						<p class="title22">{{lan?'3、专业分科授课':`3、Professional guidance`}}</p>
						<p class="text22R">
							{{lan?'专业导师进行一对一辅导，并按照学生实际学习情况进行课程微调。':`Provide one-to-one guidance and fine-tune the study plan according to the student's actual learning situation.`}}
							<br>
							<br>
						</p>
						<p class="title22">{{lan?'4、海外面试官评估':`4、Assessment by overseas interviewers`}}</p>
						<p class="text22R">
							{{lan?'不定期安排英、美国家顶尖艺术和商科院校官方面试官为学生进行作品集评估。':`From time to time, admission officers from top art and business universities in the United Kingdom and the United States are arranged to evaluate students' portfolios.`}}
							<br>
							<br>
						</p>
						<p class="title22">{{lan?'5、作品集整理排版':`5、Portfolio Assessment`}}</p>
						<p class="text22R">
							{{lan?'将针对作品集内容及排版情况进行审核，指导学生进行排版调整修改，保证学生作品集以最佳状态呈现及递交。':`The content and layout of the portfolio will be reviewed, and students will be guided to adjust and modify the layout to ensure that the portfolio of students is presented and submitted in the best light.`}}
							<br>
							<br>
						</p>
						<p class="title22">{{lan?'6、文书及申请指导':`6、Paperwork and application guidance`}}</p>
						<p class="text22R">
							{{lan?'提供文书及相关指导：如个人陈述、创作自述、学习计划书及模拟面试等。':`Provide documentation and guidance such as personal statement, creative statement, study plan and mock interview.`}}
							<br>
							<br>
						</p>
						<p class="title22">{{lan?'7、海外留学缓助':`7、Overseas study assistance`}}</p>
						<p class="text22R">
							{{lan?'辰美升学中心持续跟进学生在国外留学情况， 及时解答和帮助学生在生活和学习上的问题。':`The Center continues to keep in touch with students studying abroad, providing timely support.`}}
						</p>

						<p class="title34">{{lan?'国内升学方向':`Domestic study pathway`}}</p>

						<p class="title26">{{lan?'升学路径一':`Pathway one`}}</p>
						<p class="text22R">
							{{lan?'参加普通高考，进入国内211、985、双一流、音乐艺术专业院校。':`Participate in the general college entrance examination, enter the domestic 211, 985, double first-class, music and art academies.`}}
						</p>
						<p class="title26">{{lan?'升学路径二':`Pathway two`}}</p>
						<p class="text22R">
							{{lan?'参加高职高考（3+x，即语数英 + 专业考试），进入艺术、音乐专业院校或高职院校':`Take the higher vocational college entrance examination (3+x, that is, language, mathematics and English + professional examination), to enter the art or music academies or vocational colleges`}}
						</p>

						<p class="title34">{{lan?'国际升学方向':`International study pathway`}}</p>

						<p class="title26">
							{{lan?'升学路径一（英美方向）：':`Study Pathway 1 (UK and US):`}}
							<br>
							{{lan?'专业: 艺术设计与制作、音乐表演各细分专业':`Major: Art design and production, music performance each subdivision professional`}}
						</p>
						<p class="text22R">
							{{lan?'为学生提供满足英美艺术、音乐名校留学需要的作品集课程、英语课程、绘画基础、创意思的、专属升学规划，进入英国伦教艺术大学、皇家音乐学院、美国艺术中心设计学院、帕森斯设计学院、伯克利音乐学院等海外音乐和艺术院校。':`Provide students with portfolio courses, English courses, basic painting, creative and exclusive further study plans, and enter London University of the Arts, Royal College of Music, Art Center College of Design, Parsons School of Design, Berklee College of Music and other overseas music and art colleges.`}}
						</p>
						<p class="title26">
							{{lan?'升学路径二（日本方向）：':`Study Pathway 2 (Japanese direction) :`}}
							<br>
							{{lan?'专业: 服装设计、动漫设计、产品设计':`Major: Fashion design, animation design, product design`}}
						</p>
						<p class="text22R">
							{{lan?'为学生提供满足日本艺术留学需要的艺术课程、日语课程、绘画基础、创意思维、专属升学规划，进入东京艺术大学、武藏野艺大学、多摩美术大学等日本艺术院校。':`We provide students with art courses, Japanese language courses, basic painting, creative thinking, and personalized further study plans, to enter Japanese art schools such as Tokyo University of the Arts, Musashino University, and Tama Art University.`}}
						</p>
					</div>
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'service',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				tip:4,
				tipNumber:0,
        lan: 1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		mounted() {
      const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
        // 英文版
				this.lan = 0
			}else if(language == 'en'&&newLanguage=='cn'){
        // 中文版
				this.lan = 1
			}
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeIndex(){
				this.$router.push("/");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .87rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.title50 {
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .22rem;
	}
	.title34 {
		font-size: .34rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .22rem;
		margin-top: 0.7rem;
	}
	.title26 {
		font-size: .26rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		/* margin-bottom: .22rem; */
		margin-top: 0.22rem;
	}
	.mt {
		margin-top: 0.15rem;
	}
	.img{
    width: 100%;
    height: auto;
    margin: .6rem 0;
	}
	.text22R, .title22{
		line-height: .32rem;
	}
	.title22 {
		font-size: .22rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .32rem;
	}
	.text22R {
		font-size: .22rem;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
</style>
