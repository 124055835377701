<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/contactbg.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
            <p class="title50">
							{{lan?'联系及咨询方式':`Contact and consultation`}}
							<br>
							{{lan?'Contact and consultation':``}}
						</p>
						<img src="../../../public/static/img/brief-img2.jpg" class="img" alt="">
						<p class="text18R">
								{{lan?'辰美艺术学校始创于2020年9月，原名为辰美国际艺术教育，于2023年3月正式更名为“佛山市辰美艺术中等职业学校”，是广佛首所具有国际化特色的民办全日制中等艺术学校。辰美由实力雄厚的全球顶级基金公司——基汇资本主办，是佛山市南海区重点招商引资教育项目。辰美以培养“具有全球胜任力的中国艺术创新人才”为使命，以“守正创新，博雅精艺”为校训，办面向未来、引领未来、成就未来的艺术教育。辰美丰富多元的课程体系、小班制个性化的教学支持，保障学生全面发展、高质量成长的同时，为学生提供多元的升学路径。':`Stellart International School of Arts (SISA), founded in September 2020, is a privately-run secondary school specializing in the arts in the Guangzhou-Foshan locality. Funded by Gaw Capital, SISA has the mission of cultivating “Chinese creative and artistic talents with global competencies" and conducts art education that looks towards, leads and creates the future. SISA’s rich and diverse curriculum, small class sizes and personalized teaching & learning will ensure all-rounded development and high-quality growth of its students, providing students with multiple pathways for further studies.`}}
						</p>
						<P class="text18R">
							<br>
							Stellart is a global art education platform for everyone, authorized by Cambridge Assessment International Education (CAIE) and Pearson Edexcel, offering IGCSE, A-LEVEL and NCUK IFY pre-university programs. 
						</P>
						<p class="title50">{{lan?'联系方式':`Contact information`}}</p>
						<p class="text18R">
							
							{{lan?'1、辰美艺术学校招生办前台联系电话：0757-86796295':`(1) SISA Admission Office reception phone: 0757-86796295`}}
							<br>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{lan?'招生办开放时间：每天 9:30-17:30（春节除外）':`Admission Office opening hours: 9:30-17:30 daily (except Spring Festival)`}}
							<br>
							{{lan?'2、微信公众号：辰美艺术（ID：sisacn）':`(2) Wechat public number: SISA (ID: sisacn)`}}
							<br>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{lan?'广东省佛山市南海区三山新城疏港路26号辰美艺术学校':`Stellart International School of Arts, 26 Shugang Road, Sanshan New Town, Nanhai District, Foshan City, Guangdong Province`}}
							<br>
							{{lan?'3、辰美艺术教育招生办老师联系方式':`(3) Contact information of the teacher of the Admission Office of SISA`}}
							<br>
							<!-- {{lan?'3、辰美艺术教育招生办老师联系方式':`(3) Contact information of the teacher of the Admission Office of SISA`}} -->
						</p>
						<div class="box-3 flex flex-hsb">
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img10.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'席老师-Dylan Xi':`Mr. Dylan Xi`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile：`}}13927252170</p>
									<p class="text14R">{{lan?'邮箱：':`Mail: `}}
									Dylan.xi@sisa.cn</p>
								</div>
							</div>
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img11.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'张老师-Flora Zhang':`Ms. Flora Zhang`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile：`}}13927252209</p>
									<p class="text14R">{{lan?'邮箱：':`Mail: `}}
									Flora.zhang@sisa.cn</p>
								</div>
							</div>
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img12.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'陈老师-Joe Chen':`Mr. Joe Chen`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile：`}}13927255044</p>
									<p class="text14R">{{lan?'邮箱：':`Mail: `}}
									Joe.chen@sisa.cn</p>
								</div>
							</div>

							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img101.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'李老师-Terry Li':`Terry Li`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile: `}}18664531177</p>
									<p class="text14R">{{lan?'邮箱：':`Email: `}}terry.li@sisa.cn</p>
								</div>
							</div>
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img111.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'桑老师-Sam Sang':`Sam Sang`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile: `}}13686597665</p>
									<p class="text14R">{{lan?'邮箱：':`Email: `}}Shenghua.sang@sisa.cn</p>
								</div>
							</div>
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img121.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'谢老师-Sergio Xie':`Sergio Xie`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile: `}}13828069803</p>
									<p class="text14R">{{lan?'邮箱：':`Email: `}}Sergio.Xie@sisa.cn</p>
								</div>
							</div>
						</div>
						<p class="text18R box-4">
							{{lan?'4、业务咨询：angela.li@sisa.cn':`(4) Business consulting: angela.li@sisa.cn`}}
						</p>
						<p class="title50">{{lan?'咨询方式':`Consultation method`}}</p>
						<p class="text18R">
							
							{{lan?'1、现场咨询：亲临辰美招生办公室；':`(1) On-site consultation: visit SISA Admission Office in person;`}}
							<br>
							{{lan?'2、网上咨询：登录学校网站报名（www.sisa.cn）或关注学校官方微信“辰美艺术”，在线留言咨询。':`(2) Online consultation: register on the school’s website (www.sisa.cn) or follow the official WeChat of the school (sisacn) and leave an online message for consultation.`}}
							<br>
						</p>
					</div>
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'Consultation',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				tip:6,
				tipNumber:0,
        lan: 1
			}
		},
		components: {
		  headerList,
			headerLan,
			foot,
			leftNav,
		},
		mounted() {
      const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
        // 英文版
				this.lan = 0
			}else if(language == 'en'&&newLanguage=='cn'){
        // 中文版
				this.lan = 1
			}
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeIndex(){
				this.$router.push("/");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .87rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.img{
		width: 100%;
		height: auto;
		margin-bottom: .7rem;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R {
		font-size: .18rem;
		text-align: justify;
	}
	.title50 {
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .22rem;
	}
	.title50{
	  margin: .97rem 0 .64rem;
	}
	.box-3{
	  /* margin-top: .51rem; */
	}
	.box-4{
	  margin-top: .2rem;
	}
	.flex-hbet, .flex-hsb {
		-moz-justify-content: space-between;
		-ms-justify-content: space-between;
		-o-justify-content: space-between;
		justify-content: space-between;
	}
	.flex {
		display: flex;
	}
	.box-3{width: 100%;display: block;
		overflow: auto;}
	.box-3 .box2-item{ width:  3.1rem; float: left; margin-top: 0.2rem;}
	.box2-item img {
		width: 1.1rem;
		height: 1.1rem;
	}
	.flex-v {
		-moz-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}
	.box2-item .text16B{
	  margin-top: .43rem;
	}
	.text16B {
		font-size: .16rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .22rem;
	}
	.text14B, .text14R {
		font-size: .14rem;
		color: #0e183f;
	}
	.text14R {
		font-weight: 400;
		line-height: .22rem;
	}
</style>
