<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/aboutCMbg.8d6047f.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<div v-for="(item,i) in list" :key="i">
							<div class="info-new">
								<div class="ps-img">
									<img class="pswt-img" src="../../../public/static/img/president-words-bg.png" alt="">
									<img class="pswt-img2" :src="item.attributes.image.data!=null ? BaseURL+item.attributes.image.data.attributes.url : '' " alt="">
										<!-- <img  :src="img.attributes.image.data!=null ? BaseURL+img.attributes.image.data.attributes.url : ''" alt=""> -->
								</div>
								<div class="single-info">
									<div class="name-wrap">
										<p class="name">{{item.attributes.name}}</p>
										<p class="split-line2" v-if="item.attributes.name"></p>
										<p class="name">{{item.attributes.name_en}}</p>
									</div>
									<p class="job">{{item.attributes.introduce}}</p>
									<div class="line"></div>
									<p class="sim-info">{{lan?'简介：':'Introduction:'}}</p>
									<p class="sim-content">{{item.attributes.description}}</p>
									<div class="cont-title">
										<img class="psw-icon" src="../../../public/static/img/校长寄语.png" alt="">
										<p class="res-sim-info">{{item.attributes.title}}</p>
									</div>
									<div class="line"></div>
									<div class="article-wrap">
										<div class="article" v-html="item.attributes.content"></div>
									</div>
								</div>
							</div>
							<div class="line res-line" v-if="i!=2">
								
							</div>
						</div>

					</div>
				</div>
				
			</div>
		</div>
		<foot></foot>
		
	</div>

</template>

<script>
	import {  getPresidentWordsPage,getPresidentWordsPageCN } from "../../api/api.js"
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'presidentWords',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				list:[],
				RoyList:[],
				BaseURL:process.env.VUE_APP_BASEURL,
				contentData:[],
				tip:1,
				tipNumber:0,
        lan: 1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		created() {
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
				this.getDetail()
        this.lan = 0
			}else if(language == 'en'&&newLanguage=='cn'){
				this.getCNDetail()
        this.lan = 1
			}
			
		},
		mounted() {
			// console.log(process.env.VUE_APP_BASEURL,'da')
			window.addEventListener("scroll", this.showbtn, true)
		},
		
		methods:{
			getDetail(){
				getPresidentWordsPage().then(res=>{
					// console.log(res.data)
					this.list = res.data.data
					 this.RoyList =this.list[0].attributes.content
					 this.RoyList  = this.RoyList.replace(/<br \/>/g, "");
					// this.contentData = this.list
					// console.log(this.RoyList )
				})
			},
			getCNDetail(){
				getPresidentWordsPageCN().then(res=>{
					// console.log(res.data)
					this.list = res.data.data
					 this.RoyList =this.list[0].attributes.content
					 this.RoyList  = this.RoyList.replace(/<br \/>/g, "");
					// this.contentData = this.list
					console.log(this.RoyList )
				})
			},
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeIndex(){
				this.$router.push("/");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}

	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.info-new{
		display: flex;
	}
	.info-new .ps-img{
		width: 1.75rem;
		height: 1.74rem;
		margin-right: .34rem;
		position: relative;
	}
	.info-new .pswt-img{
		width: 1.75rem;
		height: 1.74rem;
	}
	.info-new .pswt-img2{
		position: absolute;
		object-fit: cover;
		width: 1.6rem;
		height: 1.74rem;
		top: 0;
		right: 0;
	}
	.name-wrap{
		align-items: center;
		display: flex;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.name{
		font-size: .3rem;
		font-weight: 700;
		line-height: .32rem;
		margin: 0;
		padding: 0;
		color: #0e183f;
		text-align: left;
	}
	.job{
		margin-top: .09rem;
		margin-bottom: .14rem;
		line-height: .22rem;
		font-size: .22rem;
		font-weight: 400;
		font-style: italic;
		color: #0e183f;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.line{
		height: 1px;
		background: rgba(30,44,92,.3);
	}
	.sim-info{
		margin-top: .4rem;
		font-size: .24rem;
		font-weight: 700;
		color: #0e183f;
	}
	.sim-content{
		margin-bottom: .77rem;
		font-size: .18rem;
		font-weight: 300;
		color: #0e183f;
		line-height: .32rem;
		text-align: justify;
		font-style: italic;
	}
	.cont-title{
		align-items: center;
		display: flex;
	}
	.psw-icon{
		width: .23rem;
		height: .23rem;
		margin-right: .15rem;
	}
	.res-sim-info{
		font-size: .24rem;
		font-weight: 700;
		color: #0e183f;
		margin: 0;
	}
	.article{
		font-size: .2rem;
		font-weight: 400;
		line-height: .42rem;
		color: #0e183f;
		margin-top: .32rem;
		white-space: pre-wrap;
	}
	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.res-line {
		margin-top: 1rem;
		margin-bottom: .98rem;
	}
	.line {
		height: 1px;
		background: rgba(30,44,92,.3);
	}
</style>
