<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
    <div class="words">
			<img class="words-img" src="../../../public/static/img/contactbg.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<p class="title50">
							{{lan?'辰美艺术学校':`SISA faculty recruitment notice`}}
							<br>
						</p>
						<p class="title50">						
							{{lan?'教职工招聘启事':``}}
							<br>
						</p>
						<p class="title30 title-lineS">
							{{lan?'↓招聘详情请扫以下二维码↓':`↓ Recruitment details please scan the following QR code ↓`}}
						</p>
						<p><img src="../../../public/static/img/zhaoping.png" style="width: 2.5rem;" alt=""></p>
						<br>
						<p class="title30 title-lineS">
							{{lan?'学校简介':`School profile`}}
						</p>
						<p class="text18R">
							
							{{lan?'辰美艺术学校始创于2020年9月，原名为辰美国际艺术教育，于2023年3月正式更名为“佛山市辰美艺术中等职业学校”，是广佛首所具有国际化特色的民办全日制中等艺术学校。辰美由实力雄厚的全球顶级基金公司——基汇资本主办，是佛山市南海区重点招商引资教育项目。辰美以培养“具有全球胜任力的中国艺术创新人才”为使命，以“守正创新，博雅精技”为校训，办面向未来、引领未来、成就未来的艺术教育。辰美丰富多元的课程体系、小班制个性化的教学支持，保障学生全面发展、高质量成长的同时，为学生提供多元的升学路径。':`Stellart International School of Arts (SISA), founded in September 2020, is a privately-run secondary school specializing in the arts in the Guangzhou-Foshan locality. Funded by Gaw Capital, SISA has the mission of cultivating “Chinese creative and artistic talents with global competencies" and conducts art education that looks towards, leads and creates the future. SISA’s rich and diverse curriculum, small class sizes and personalized teaching & learning will ensure all-rounded development and high-quality growth of its students, providing students with multiple pathways for further studies.`}}
							<br>
							<br>
							{{lan?'由于发展需要，现面向全社会招聘美术、音乐、语文、数学、英语、物理、体育等学科老师及行政人员、教辅人员等职员若干名。':`We welcome passionate educators and staff of all talents to join us. We are now recruiting a number of teachers, administrative staff, teaching assistants and other subjects such as art, music, Chinese, mathematics, English, physics and physical education.`}}
						</p>
						<p class="title30 title-lineS">{{lan?'招聘岗位':`Recruitment Positions`}}</p>
						<div class="table">
							<div class="flex table-title">
								<div class="table-left">
									<p class="title22">{{lan?'岗位':`Recruitment position`}}</p>
								</div>
								<div class="table-right">
									<p class="title22">{{lan?'岗位职责及任职要求':`Job duties and requirements`}}</p>
								</div>
							</div>
							<div class="flex table-line" v-for="(item,i) in List" :key="i">
								<div class="table-left flex flex-vc flex-hc">
									<p class="text22R">
										{{item.attributes.position}}
										<br>
										<span class="text18R">
										{{item.attributes.type}}
										</span>
									</p>
									
								</div>
								<div class="table-right">
									<p class="text18R">{{item.attributes.description}}</p>
								</div>
							</div>
							
						</div>
						<p class="title22 line36">{{lan?'招聘程序':`Recruitment procedure`}}</p>
						<p class="text18R line36">
							
							{{lan?'1、请把简历发送至指定邮箱，标题格式：应聘职位+姓名+毕业院校+应聘学科（如有）；':`(1) Please send your resume to the designated email address with the title format: Position + name + university + subject (if any);`}}
							<br>
							{{lan?'2、通知符合条件的应聘者进行面试，择优录取；':`(2) Notify qualified candidates for interview and select the best;`}}
							<br>
							<br>
						</p>
						<p class="title22 line36">{{lan?'薪酬福利':`Compensation and welfare`}}</p>
						<p class="text18R line36">
							
							{{lan?'1、薪酬待遇优厚，具体面议；':`(1) Generous remuneration, specific negotiable;`}}
							<br>
							{{lan?'2、享有五险一金、带薪寒暑假、不定期团建奖励、绩效奖金、省外或国外学习机会；':`(2) Enjoy social insurance benefits, paid winter and summer vacations, occasional team building rewards, performance bonuses, study opportunities outside the province or abroad;`}}
							<br>
							<br>
						</p>
						<p class="title22 line36">{{lan?'联系方式':`Contact information`}}</p>
						<p class="text18R line36">
							
							{{lan?'简历投递：zoey.chen@sisa.cn':`Please send your resume to zoey.chen@sisa.cn`}}
							<br>
							{{lan?'联系人：陈老师':`Contact: Ms. Chen`}}
							<br>
							{{lan?'联系电话：0757-81209791':`Contact number: 0757-81209791`}}
							<br>
							{{lan?'地址：佛山市南海区桂城街道三山新城疏港路28号富罗恩斯广场辰美艺术教育（佛罗伦萨小镇对面）':`Address: Stellart International School of Arts, No.26 Shugang Road, Sanshan New Town, Guicheng Street, Nanhai District, Foshan City`}}
							<br>
						</p>
					</div>
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import {  getRecruitCN,getRecruit } from "../../api/api.js"
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'recruit',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				List:[],
				tip:6,
				tipNumber:1,
        lan: 1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
			
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
				this.lan = 0
				this.getDetailEN()
			}else if(language == 'en'&&newLanguage=='cn'){	
				this.lan = 1
				this.getDetail()
			}
		},
		methods:{
			getDetail(){
				getRecruitCN().then(res=>{
					this.List = res.data.data
				})
			},
			getDetailEN(){
				getRecruit().then(res=>{
					this.List = res.data.data
				})
			},
			comeMyself(text){
				this.$router.push(text);
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeIndex(){
				this.$router.push("/index");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .87rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.title50 {
    font-size: .5rem;
    line-height: .5rem;
    font-weight: 700;
    color: #1e2c5c;
    margin-bottom: .22rem;
	}
	.title30, .title40 {
		font-weight: 700;
		color: #0e183f;
	}
	.title30, .title30M {
		font-size: .3rem;
		line-height: .3rem;
		margin-top: 1rem;
	}
	.title-lineS {
		border-bottom: 1px solid #0e183f;
		padding-bottom: .06rem;
		display: inline-block;
	}
	.title30 {
	  margin-bottom: .6rem;
	}
	.text18R {
		font-size: .18rem;
		text-align: justify;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.table{
		width: 100%;
		border: 1px solid #0e183f;
		border-bottom: none;
		margin-bottom: 1.1rem;
	}
	.table-line, .table-title {
	  border-bottom: 1px solid #0e183f;
	}
	.flex {
	  display: flex;
	}
	.table-left{
		width: 2.73rem;
		border-right: 1px solid #0e183f;
	}
	.title22 {
		font-size: .22rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .32rem;
	}
	.table-title .title22{
		text-align: center;
		line-height: .74rem;
	}
	.table-right{
	  flex: 1;
	}
	.table-line .table-left {
	  position: relative;
	}
	.table-left {
		width: 2.73rem;
		border-right: 1px solid #0e183f;
	}
	.flex-vc {
		-moz-align-items: center;
		-ms-align-items: center;
		-o-align-items: center;
		align-items: center;
	}
	.flex-hc {
		-moz-justify-content: center;
		-ms-justify-content: center;
		-o-justify-content: center;
		justify-content: center;
	}
	.table-line .table-left .text22R {
	  text-align: center;
	}
	.text22R {
		font-size: .22rem;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.table-right{
	  flex: 1;
	}
	.table-right .text18R {
	  margin: .45rem .29rem;
	}
	.table-line .table-left:after {
		content: "";
		background: url(../../../public/static/img/icon-sanjiao.png) repeat-y top/100% auto;
		width: .18rem;
		height: .18rem;
		position: absolute;
		left: -1px;
		top: 0;
	}
</style>
