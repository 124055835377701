<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/container-banner.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<div class="news">
							<div class="title">
								<img src="../../../public/static/img/最新动态.png" alt="图片加载中...">
								<span class="char">{{lan?'最新动态':'Latest news'}}</span>
							</div>
							<div class="picture" @click="comeUrl(item.attributes.url)"  v-for="(item,i) in item" :key="i">
								<img src="../../../public/static/img/最新角标.png" class="img3" alt="">
								<img src="../../../public/static/img/最新动态右侧.png" class="img1" alt="">
								<img :src="item.attributes.image.data!=null ? BaseURL+item.attributes.image.data.attributes.url : '' " class="img2" alt="">
								<p class="picture-title">{{item.attributes.title}}</p>
								<p class="picture-context" v-html="item.attributes.description"></p>
								<div class="picture-time">
									<img src="../../../public/static/img/时间-s.png" alt="">
									<span class="date">{{item.attributes.fa_date}}</span>
									<span class="more">{{lan?'查看更多':'View More'}}></span>
								</div>
							</div>
						</div>
						<div class="list">
							<div class="title">
								<img src="../../../public/static/img/最新动态.png" class="icon-new" alt="">
								<span class="char">{{lan?'动态列表':'News List'}}</span>
							
							</div>
							<div class="list-item">
								<!-- <div @click="comeUrl(pic.attributes.url)" :class="pic.attributes.isShow==0 ? 'miss':''" class="item pic-item"  v-for="(pic,i) in picList" :key="i"> -->
								<div @click="comeUrl(pic.attributes.url)" class="item pic-item"  v-for="(pic,i) in picList" :key="i">
									<template v-if="pic.emptyList">
										<div class="item_empty"></div>
									</template>
									<div class="item-con" v-else-if="showList(i)">
										<img :src="pic.attributes.image.data!=null ? BaseURL+pic.attributes.image.data.attributes.url : '' " class="pic" alt="">
										<div class="info">
											<div class="title2">
												<img src="../../../public/static/img/视频主题icon.png" class="title-icon" alt="">
												<div class="title-context">
													{{pic.attributes.title}}
												</div>
											</div>
											<div class="description">
												{{pic.attributes.description}}
											</div>
											<div class="picture-time">
												<div class="img-wrap">
													<img src="../../../public/static/img/时间.png" class="img4" alt="">
													<img src="../../../public/static/img/时间-s.png" class="img5" alt="">
												</div>
												<span class="date">{{pic.attributes.fa_date}}</span>
												<span class="more">{{lan?'阅读更多':'View More'}}></span>
											</div>
										</div>
									</div>
									
								</div>
							</div>
							<div class="data-more" @click="showVideoMore" v-if="moreVideoShow">
								<span>{{lan?'查看更多':'View more'}}</span>
								<img src="../../../public/static/img/查看更多.png" alt="">
							</div>
							<div class="data-more" @click="showVideoMore" v-if="!moreVideoShow">
								<span>{{lan?'收起':'Retract'}}</span>
								<img class="down-img" src="../../../public/static/img/查看更多.png" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import {  getCampusList,getCampusItem,getCampusListCN,getCampusItemCN } from "../../api/api.js"
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'campusNews',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				moreVideoShow:true,
				item:[],
				picList:[
					
				],
				BaseURL:process.env.VUE_APP_BASEURL,
				tip:3,
				tipNumber:0,
				listLen: 8,
        lan: 1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
				this.lan = 0
				this.getItem()
				this.getpage()
			}else if(language == 'en'&&newLanguage=='cn'){
				this.lan = 1
				this.getItemCN()
				this.getpageCN()
			}
			// this.hiddenPic()
			
		},
		methods:{
			comeUrl(e){
				// window.location.href = e
				window.open(e)
			},
			getItem(){
				getCampusItem().then(res=>{
					
					this.item = res.data.data
				})
			},
			getpage(){
				getCampusList().then(res=>{
					
					this.picList = res.data.data
					let len = this.picList.length % 3
					if(len){
						for (let index = 0; index < 3-len; index++) {
							this.picList.push({emptyList: true})
						}
					}
				})
			},
			getItemCN(){
				getCampusItemCN().then(res=>{
					
					this.item = res.data.data
				})
			},
			getpageCN(){
				getCampusListCN().then(res=>{
					
					this.picList = res.data.data
					let len = this.picList.length % 3
					if(len){
						for (let index = 0; index < 3-len; index++) {
							this.picList.push({emptyList: true})
						}
					}
				})
			},
			comeMyself(text){
				this.$router.push(text);
			},
			
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			hiddenPic(){
				var lis = document.querySelectorAll(".pic-item");
				console.log(lis[1])
				for(var i = 0;i<=lis.length-1;i++){
					if(i>=9){
						lis[i].classList.add('miss')
					}
				}
			},
			showList(index){
				if(this.moreVideoShow) {
					if (index>this.listLen) return false
					return true
				} else {
					return true
				}
			},
			showVideoMore(){
				if(this.moreVideoShow){
					this.moreVideoShow = false
					// var lis = document.querySelectorAll(".pic-item");
					// console.log(lis[1])
					// for(var i = 0;i<=lis.length-1;i++){
					// 	if(i>=9){
					// 		lis[i].classList.remove('miss')
					// 	}
					// }
					// this.videoShow = true
				}else{
					this.moreVideoShow = true
					// var lis = document.querySelectorAll(".pic-item");
					// console.log(lis[1])
					// for(var i = 0;i<=lis.length-1;i++){
					// 	if(i>=9){
					// 		lis[i].classList.add('miss')
					// 	}
					// }
				}
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .87rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.data-more{
		cursor: pointer;
		height: .24rem;
		margin-top: .11rem;
		align-items: center;
		justify-content: center;
		display: flex;
	}
	.data-more span{
		font-size: .16rem;
		font-weight: 500;
		color: #d7a16f;
		line-height: .24rem;
		margin-right: .05rem;
		
	}
	.down-img{
		transform: rotate(180deg);
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.news .title{
		margin-bottom: .4rem;
		align-items: center;
		display: flex;
	}
	.news .title img{
		width: .25rem;
		height: .25rem;
		margin-right: .08rem;
	}
	.news .title .char{
		font-weight: 500;
		font-size: .3rem;
		color: #1e2c5c;
	}
	.picture{
		position: relative;
		width: 9.56rem;
		height: 3.49rem;
		box-shadow: 3px 3px 6px rgba(189,190,193,.5);
		cursor: pointer;
	}
	.news .img3{
		position: absolute;
		z-index: 1;
		width: .9rem;
	}
	.news .img1{
		position: absolute;
		height: 3.49rem;
		z-index: 1;
		right: 0;
		width: auto;
	}
	.news .img2{
		width: 5.92rem;
		position: absolute;
		height: 3.49rem;
	}
	.picture .picture-title{
		position: absolute;
		padding: 0;
		right: .42rem;
		z-index: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		font-size: .2rem;
		font-weight: 700;
		line-height: .3rem;
		margin-top: .3rem;
		width: 2.79rem;
		-webkit-line-clamp: 2;
	}
	.picture .picture-context{
		position: absolute;
		padding: 0;
		right: .42rem;
		z-index: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		font-size: .14rem;
		font-weight: 400;
		line-height: .24rem;
		margin-top: 1.29rem;
		width: 2.76rem;
		height: auto;
		-webkit-line-clamp: 6;
	}
	.picture .picture-time{
		position: absolute;
		margin-top: 3.06rem;
		padding: 0;
		right: .42rem;
		width: 2.76rem;
		height: .24rem;
		z-index: 1;
		white-space: nowrap;
		align-items: center;
		display: flex;
	}
	.picture .picture-time .date{
		font-weight: 400;
		line-height: .24rem;
		font-size: 12px;
		color: #1e2c5c;
		margin-left: .04rem;
	}
	.picture .picture-time .more{
		font-weight: 400;
		line-height: .24rem;
		font-size: 12px;
		color: #d7a16f;
		margin-left: auto;
	}
	.list .title{
		margin: 1.02rem 0 .36rem;
	}
	.list .title .icon-new{
		width: .25rem;
		height: .25rem;
		margin-right: .08rem;
	}
	.list .title .char{
		font-weight: 500;
		font-size: .3rem;
		color: #1e2c5c;
	}
	.list-item{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.list-item .item {
		width: 3.02rem;
		height: unset;
    margin-right: 0;
    margin-bottom: 0;
    padding-bottom: 0;
	}
	.list-item .item-con {
		/* height: 3.5rem; */
		display: flex;
		width: 3.02rem;
		margin-bottom: .55rem;
		margin-right: 0;
		overflow: hidden;
		cursor: pointer;
		border-radius: 0 0 .2rem 0;
		flex-direction: column;
	}
	.list-item .item_empty {
		width: 3.02rem;
	}
	.list-item .item .pic{
		width: 3.02rem;
		height: 1.79rem;
		object-fit: cover;
	}
	.list-item .item .info{
		margin: 0;
		padding: 0;
		border-radius: 0 0 .2rem 0;
	}
	.list-item .title2{
		display: flex;
		border-bottom: 1px solid #bfbfbf;
		align-items: center;
	}
.list-item .item .info .title2 .title-icon{
	width: .12rem;
	height: .187rem;
	margin-right: .09rem;
	margin-left: .16rem;
	display: flex;
}
.list-item .title2 .title-context{
	width: 2.23rem;
	color: #1e2c5c;
	font-weight: 700;
	line-height: .5rem;
	font-size: .2rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.list-item .item .info .description{
	padding: 0;
	margin: .14rem 0 .2rem .37rem;
	width: 2.19rem;
	font-size: .14rem;
	font-weight: 400;
	color: #1e2c5c;
	line-height: .24rem;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	position: relative;
	max-height: .43rem;
	-ms-content: "...";
	content: "...";
}
.item .info .picture-time{
	display: flex;
	align-items: center;
	margin: 0 .35rem .21rem .36rem;
	white-space: nowrap;
}
.item .info .picture-time .img-wrap{
	position: relative;
	width: 11px;
	height: .24rem;
	margin-bottom: 0;
}
.item .info .picture-time .img-wrap .img4{
	position: absolute;
	opacity: 1;
	width: 11px;
	top: 0;
	bottom: 0;
	margin: auto;
}
.item .info .picture-time .img-wrap .img5{
	position: absolute;
	opacity: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}
.item .info .picture-time .date{
	font-weight: 400;
	line-height: .24rem;
	font-size: 12px;
	color: #1e2c5c;
	margin-left: .15rem;
}
.item .info .picture-time .more{
	font-weight: 400;
	line-height: .24rem;
	font-size: 12px;
	color: #d7a16f;
	margin-left: auto;
}
.list-item .item:hover .info{
	background-color: #1e2c5c;
	
}
.list-item .item:hover .info .description{
	color: #ffffff;
}
.list-item .item:hover{
	border-bottom: 0;
}
.list-item .item:hover .title2 .title-context{
	color: #ffffff;
}
.item:hover .info .picture-time .img-wrap .img5{
	opacity: 1;
}
.item:hover .item .info .picture-time .img-wrap .img4{
	opacity: 0;
}
.item:hover .info .picture-time .date{
	color: #ffffff;
}
.miss{
	display: none !important;
}
</style>
