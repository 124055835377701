<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/recruitbg.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<p class="title50">
							{{lan?'辰美2024招生简章':`SISA 2024 Admission Brochure`}}
							<br>
							SISA 2024 Admission Brochure
						</p>
						<img src="../../../public/static/img/brief-img1.jpg" class="img" alt="">
						<p class="text18R text0">
							
							{{lan?'辰美艺术学校':'SISA Art School'}}
							<br>
							{{lan?'广佛首所具有国际化特色的':'Guangfo first with international characteristics'}}
            
							<br>
							{{lan?'民办全日制中等艺术学校':'Private full-time secondary art school'}}
            
							<br>
							{{lan?'国内、国际名校双轨升学':'Double track study in domestic and international universities'}}
            
							<br>
							Stellart International School of Arts            
							<br>
						</p>
						<div class="img-4 flex flex-hsb flex-hw" >
							<img v-for="(img,i) in imgList" :key="i" :src="img.attributes.image.data!=null ? BaseURL+img.attributes.image.data.attributes.url : '' " alt="">
						</div>
						<p class="title30 title-lineS" style="margin-bottom: 0.2rem;">
							{{lan?'招生计划及招生对象':`Enrollment plan and enrollment target`}}
							<br>
							Admissions and Prospects
						</p>
						<div class="" style="margin-bottom: 0.3rem; font-size: 0.3rem">
							{{lan?'招生人数：240人':'Enrollment: 240 students'}}<br>
              {{lan?'开设专业：':'Majors'}}<br>
              {{lan?'●艺术设计（美术/设计）':'● Art Design '}}<br>
              {{lan?'●音乐（含音乐表演/传媒）':'● Music performance majors'}}<br>
			  {{lan?'报读上述专业学生可选择如下升学方向：':''}}<br>
			  {{lan?'1、国内985、211综合名校或艺术/音乐专业名校':''}}<br>
			  {{lan?'2、英、美、澳、加、新加坡等国家和中国港澳地区世界名校':''}}<br>
			  {{lan?'3、意大利公立大学（大学免学费）':''}}<br>
			  {{lan?'4、日本综合名校或艺术/音乐专业名校':''}}

						</div>
						<div class="box-4 flex flex-hsb flex-hw" v-if="false">
							<div class="box-itrm" v-for="(team,i) in teamList" :key="i">
								<img :src="team.attributes.image.data!=null ? BaseURL+team.attributes.image.data.attributes.url : '' " alt="">
								<p class="title22">{{team.attributes.title}}</p>
								<p class="text18R">nums：{{team.attributes.nums}}</p>
								<p class="text18R">object：{{team.attributes.object}}</p>
							</div>
						</div>
						<p class="title30 title-lineS">{{lan?'报名须知':'Registration notes'}}</p>
						<p class="title22">{{lan?'招生对象：初中毕业生和完成高一学业的学生':'Enrollment target: junior high school graduates'}}</p>
						<p class="text18R text1" v-if="false">
							{{lan?'从即日起接受报名【周一至周日 9:00-17:30】':``}}
							<br>
							<br>
            </p>
						<p class="title22">{{lan?'招生地域：面向佛山市和广东省招生':'Enrollment area: Enrollment for Foshan City and Guangdong Province'}}</p>
						<!-- <p class="text18R text1">{{lan?'①网上报名：在线提交报名表格并确认网站弹出“报名成功”窗口':``}}</p>
						<p class="text18R text1">{{lan?'②微信报名：关注学校官方微信 ，在下方菜单栏选择[线上报名] - [在线报名] 填写信息；':``}}</p>
						<p class="text18R text1">
							{{lan?'③现场报名：亲临辰美艺术教育招生办公室报名 / 在参加辰美课程说明会和其他讲座活动时与辰美现场工作人员报名。':``}}
							<br>
							<br> -->
            <!-- </p> -->
						<p class="title22">{{lan?'入学要求：':'Admission requirements:'}}</p>
						<p class="text18R text1">{{lan?'1.通过佛山市中考平台报名的学生按中考录取要求录取；':'1. Students who register through the Foshan Entrance Examination platform are admitted according to the entrance examination admission requirements;'}}</p>
						<p class="text18R text1">{{lan?'2.参加学校组织的自主招生入学测试的学生，学科成绩(语文、数学、英语)和专业成绩 (音乐专业或艺术设计与制作专业) 均须达到学校录取要求。':`2. Students who participate in the independent admission test organized by the school must meet the admission requirements of the school in terms of academic scores (Chinese, mathematics, English) and professional scores (music or art design and production).`}}</p>
						<!-- <p class="text18R text1">{{lan?'笔试：英语测试（60分钟）':``}}</p>
						<p class="text18R text1">{{lan?'商科生的入学考试：':``}}</p>
						<p class="text18R text1">{{lan?'笔试：英语测试（60分钟）+ 数学考试（60分钟）':``}}</p>
						<p class="text18R text1">{{lan?'面试：招生官面试与学术面试（30分钟）':``}}</p>
						<p class="text18R text1">
							{{lan?'注：请与学校招生办老师联系安排笔试、面试。
							通过入学测试获得录取的学生请凭录取通知书在规定期限内完成学位注册，并根据招生老师的指引进行选课及办理新生入学准备事宜。逾期未注册者不予保留学位。':``}} -->
							<!-- <br>
							<br> -->
							<p class="title22">{{lan?'收费标准':'Standard fees'}}</p>
							<p class="text18R text1">{{lan?'学费：18.8 万元/学年（注：学校将按中考分数和专业测试分数分档收费，收费从8.8万至18.8万不等，并为特别优秀的学生提供额外奖学金支持，具体请咨询招生办老师）':`Tuition: RMB 188,000 / academic year (Note: The school will charge fees according to the scores of the senior high school entrance examination and professional test, ranging from 88,000 to 188,000 yuan, and provide additional scholarship support for particularly outstanding students. Please consult the admission Office for details).`}}</p>
							<p class="text18R text1">{{lan?'●住宿费：1万元/学年 （四人套间学生公寓）':`● Accommodation fee: 10,000 Yuan/academic year (4-person suite)`}}</p>
							<p class="text18R text1">{{lan?'●伙食费、校服费按实收取':`● Meals and uniforms are charged according to the actual cost`}}</p>
            <!-- </p> -->
			<br>
						<p class="title22">{{lan?'入学测试预约及联系方式':`Admission test reservation and contact information`}}</p>
						<div class="box-3 flex flex-hsb">
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img10.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'席老师-Dylan Xi':`Mr. Dylan Xi`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile: `}}13927252170</p>
									<p class="text14R">{{lan?'邮箱：':`Email: `}}
									Dylan.xi@sisa.cn</p>
								</div>
							</div>
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img11.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'张老师-Flora Zhang':`Ms. Flora Zhang`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile: `}}13927252209</p>
									<p class="text14R">{{lan?'邮箱：':`Email: `}}
									Flora.zhang@sisa.cn</p>
								</div>
							</div>
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img12.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'陈老师-Joe Chen':`Mr. Joe Chen`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile: `}}13927255044</p>
									<p class="text14R">{{lan?'邮箱：':`Email: `}}
									Joe.chen@sisa.cn</p>
								</div>
							</div>

							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img101.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'李老师-Terry Li':`Terry Li`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile: `}}18664531177</p>
									<p class="text14R">{{lan?'邮箱：':`Email: `}}terry.li@sisa.cn</p>
								</div>
							</div>
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img111.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'桑老师-Sam Sang':`Sam Sang`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile: `}}13686597665</p>
									<p class="text14R">{{lan?'邮箱：':`Email: `}}Shenghua.sang@sisa.cn</p>
								</div>
							</div>
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img121.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">{{lan?'谢老师-Sergio Xie':`Sergio Xie`}}</p>
									<p class="text14R">{{lan?'手机：':`Mobile: `}}13828069803</p>
									<p class="text14R">{{lan?'邮箱：':`Email: `}}Sergio.Xie@sisa.cn</p>
								</div>
							</div>
						</div>
						<p class="title22">
							<br>
							{{lan?'注册学位':`Registered degree`}}
						</p>
						<p class="text18R text1">{{lan?'通过入学测试获得录取的学生请凭录取通知书在规定期限内完成学位注册，并根据注册官的指引进行选课及办理新生入学准备事宜。逾期未注册者不予保留学位。':`Students who have been admitted through the admission test should complete degree registration within the prescribed time limit with the admission letter, and select courses and prepare for new students according to the guidelines of the registrar. Those who fail to register after the deadline will not retain their degree.`}}</p>
					</div> 
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import {  getRulesImage,getRulesItem,getRulesImageCN,getRulesItemCN } from "../../api/api.js"
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'rules',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				imgList:[],
				teamList:[
				],
				BaseURL:process.env.VUE_APP_BASEURL,
				tip:5,
				tipNumber:0,
        lan: 1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
				this.lan = 0
				this.getImage()
				this.getList()
				
			}else if(language == 'en'&&newLanguage=='cn'){
				this.lan = 1
				this.getImageCN()
				this.getListCN()
				
			}
			
		},
		methods:{
			getImage(){
				getRulesImage().then(res=>{
					this.imgList = res.data.data
				})
			},
			getList(){
				getRulesItem().then(res=>{
					this.teamList = res.data.data
				})
			},
			getImageCN(){
				getRulesImageCN().then(res=>{
					this.imgList = res.data.data
				})
			},
			getListCN(){
				getRulesItemCN().then(res=>{
					this.teamList = res.data.data
				})
			},
			comeMyself(text){
				this.$router.push(text);
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeIndex(){
				this.$router.push("/");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .87rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.title50{
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .22rem;
	}
	.img{
		width: 100%;
		height: auto;
		margin: .6rem 0;
	}
	.text18R {
		font-size: .18rem;
		text-align: justify;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.text0 {
		font-style: italic;
		line-height: .36rem;
	}
	.img-4 {
		
		width: 100%;
		margin-top: .54rem;
		margin-bottom: -.1rem;
	}
	.flex {
		display: flex;
	}
	.flex-hbet, .flex-hsb {
		-moz-justify-content: space-between;
		-ms-justify-content: space-between;
		-o-justify-content: space-between;
		justify-content: space-between;
	}
	.flex-hw {
		-o-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.img-4 img {
		width: 4.69rem;
		height: 2.69rem;
		margin-bottom: .1rem;
	}
	.title30, .title40 {
		font-weight: 700;
		color: #0e183f;
	}
	.title30, .title30M {
		font-size: .3rem;
		line-height: .3rem;
		margin-top: 1rem;
	}
	.title-lineS {
		border-bottom: 1px solid #0e183f;
		padding-bottom: .06rem;
		display: inline-block;
	}
	.title30 {
		margin-bottom: .55rem;
	}
	.box-4{
		width: 100%;
		margin-bottom: -.6rem;
	}
	.box-itrm{
		width: 4.72rem;
		margin-bottom: .6rem;
	}
	.box-itrm img {
		width: 4.72rem;
		height: 2.67rem;
		margin-bottom: .2rem;
		object-fit: cover;
	}
	.title22 {
		font-size: .22rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R {
		font-size: .18rem;
		text-align: justify;
	}
	.box-3{width: 100%;display: block;
		overflow: auto;}
	.box-3 .box2-item{ width:  3.1rem; float: left; margin-top: 0.2rem;}
	.box2-item img{
		width: 1.1rem;
		height: 1.1rem;
	}
	.flex-v {
		-moz-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}
	.box2-item .text16B {
	  margin-top: .43rem;
	}
	.text16B {
		font-size: .16rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .22rem;
	}
	.text14B, .text14R {
		font-size: .14rem;
		color: #0e183f;
	}
	.text14R {
		font-weight: 400;
		line-height: .22rem;
	}
</style>
