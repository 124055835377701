<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/container-banner.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<div class="video">
							<video :src="truevideo" @play="play" @ended="audioEnded" controls="controls"></video>
							<img :src="trueImg"  v-if="videoSHow" class="img1" alt="">
							<div v-if="videoSHow"  class="zhebi"></div>
							<img src="../../../public/static/img/icon-player.png" v-if="videoSHow" class="icon-player"  alt="">
						</div>
						<p class="video-title">{{List.title}}</p>
						<p class="video-title2"></p>
						<div class="flex flex-vc title">
							<img src="../../../public/static/img/icon-videtitle.png" class="icon-videtitle" alt="">
							<p>校园视频观看</p>
						</div>
						<div class="video-list flex flex-hsb">
							<div class="video-box" @click="comeUrl(item.attributes.url)" v-for="(item,i) in inList" :key="i">
								<div class="video-img">
									<img :src="item.attributes.image.data!=null ? BaseURL+item.attributes.image.data.attributes.url : ''" class="img1" alt="">
									<div class="player-bg"></div>
									<img src="../../../public/static/img/icon-player.png" class="icon-player" alt="">
								</div>
								<div class="video-text">
									<div class="line flex flex-vc">
										<img src="../../../public/static/img/视频主题icon.png" class="title-icon" alt="">
										<p class="text1">{{item.attributes.title}}</p>
									</div>
									<p class="text2"></p>
								</div>
							</div>
						</div>
						<div class="data-more" @click="showTVMore" >
							<span v-if="!moreTVShow">收起</span>
							<span v-if="moreTVShow">查看更多</span>
							<img src="../../../public/static/img/查看更多.png" class="tvdown" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import {  getViedoData,gettvinterviews,getViedoDataCN,gettvinterviewsCN } from "../../api/api.js"
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'TVstation',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				moreTVShow:true,
				List:[],
				inList:[],
				trueImg:'',
				BaseURL:process.env.VUE_APP_BASEURL,
				tip:3,
				tipNumber:1,
				truevideo:'',
				videoSHow:true,
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			
			if(language=='en'&&newLanguage==null){
			
				this.getViedo()
				this.getinterviews()
			}else if(language == 'en'&&newLanguage=='cn'){	
				this.getViedoCN()
				this.getinterviewsCN()
			}
			
		},
		methods:{
			comeUrl(e){
				if(e==""){
					console.log('没设置')
				}else{
					window.location.href = e
				}
				
			},
	
			play(){
				this.videoSHow = false
			},
			audioEnded(){
				this.videoSHow = true
			},
			getViedo(){
				getViedoData().then(res=>{
					// BaseURL+List.image.data.attributes.url
					this.List = res.data.data[0].attributes
					this.trueImg = this.BaseURL + res.data.data[0].attributes.image.data.attributes.url
					this.truevideo = this.BaseURL + res.data.data[0].attributes.video.data.attributes.url
					console.log(this.List)
				})
			},
			getinterviews(){
				gettvinterviews().then(res=>{
					this.inList = res.data.data
				})
			},
			getViedoCN(){
				getViedoDataCN().then(res=>{
					// BaseURL+List.image.data.attributes.url
					this.List = res.data.data[0].attributes
					this.trueImg = this.BaseURL + res.data.data[0].attributes.image.data.attributes.url
					console.log(this.List.image.data.attributes.url)
				})
			},
			getinterviewsCN(){
				gettvinterviewsCN().then(res=>{
					this.inList = res.data.data
				})
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeIndex(){
				this.$router.push("/");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showTVMore(){
				if(this.moreTVShow){
					this.moreTVShow = false
					var lis = document.querySelectorAll(".tvdown");
					console.log(lis[0].style.transform)
					lis[0].classList.add('tvrote')
					// lis[0].style.transform="180deg";
					// console.log(lis[0].style.transform)
					// this.videoShow = true
				}else{
					this.moreTVShow = true
					var lis = document.querySelectorAll(".tvdown");
						lis[0].classList.remove('tvrote')
				}
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .87rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.video, .video video{
		width: 100%;
	}
	.video{
	  position: relative;
	}
	.video .img1 {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
	}
	.video .icon-player, .video .img1 {
		-webkit-pointer-events: none;
		-moz-pointer-events: none;
		-ms-pointer-events: none;
		-o-pointer-events: none;
		pointer-events: none;
	}
	.video .zhebi{
		width: 100%;
		height: 100%;
		position: absolute;
		background: rgba(0,0,0,.5);
		left: 0;
		top: 0;
		z-index: 3;
		-webkit-pointer-events: none;
		-moz-pointer-events: none;
		-ms-pointer-events: none;
		-o-pointer-events: none;
		pointer-events: none;
	}
	.icon-player {
		width: .54rem;
		height: .54rem;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 3;
	}
	.video .icon-player, .video .img1 {
		-webkit-pointer-events: none;
		-moz-pointer-events: none;
		-ms-pointer-events: none;
		-o-pointer-events: none;
		pointer-events: none;
	}
	.video .icon-player {
		width: 1.27rem;
		height: 1.27rem;
		z-index: 4;
	}
	.icon-player, .player-bg {
		position: absolute;
		left: 0;
		top: 0;
	}
	.video-title, .video-title2{
		color: #1e2c5c;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.video-title {
		font-size: .3rem;
		line-height: .35rem;
		font-weight: 500;
		margin: .3rem 0 .1rem;
		white-space: nowrap;
	}
	.video-title2{
		font-size: .18rem;
		line-height: .18rem;
		font-weight: 400;
		padding-bottom: .43rem;
		border-bottom: 1px solid hsla(0,0%,49%,.8);
		margin-bottom: .44rem;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.video-title, .video-title2 {
		color: #1e2c5c;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.title {
		margin-bottom: .36rem;
	}
	.flex-vc {
		-moz-align-items: center;
		-ms-align-items: center;
		-o-align-items: center;
		align-items: center;
	}
	.flex {
		display: flex;
	}
	.icon-videtitle {
		width: .25rem;
		height: .23rem;
	}
	.icon-videtitle {
		width: .25rem;
		height: auto;
		margin-right: .08rem;
	}
	.title p {
		font-size: .3rem;
		line-height: .35rem;
		font-weight: 500;
		color: #1e2c5c;
	}
	.video-list {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		flex-wrap: wrap;
	}
	.flex-hbet, .flex-hsb {
		-moz-justify-content: space-between;
		-ms-justify-content: space-between;
		-o-justify-content: space-between;
		justify-content: space-between;
	}
	.video-box, .video-box-none {
		width: 2.26rem;
		height: 2.32rem;
	}
	.video-box {
		overflow: hidden;
		border-radius: 0 0 .2rem 0;
		cursor: pointer;
		margin-bottom: .2rem;
	}
	.video-img, .video-img .img1 {
		width: 2.26rem;
		height: 1.34rem;
	}
	.video-img {
		position: relative;
	}
	.video-img .img1 {
		display: flex;
	}
	.video-img, .video-img .img1 {
		width: 2.26rem;
		height: 1.34rem;
	}
	.icon-player, .player-bg {
		position: absolute;
		left: 0;
		top: 0;
	}
	.player-bg {
		width: 2.26rem;
		height: 1.34rem;
		background-color: rgba(0,0,0,.6);
		z-index: 2;
	}
	.video-text {
		width: 100%;
		height: .98rem;
		transition: all .3s;
		-webkit-transition: all .3s;
	}
	.video-text .line {
		height: .39rem;
		border-bottom: 1px solid #bfbfbf;
	}
	.flex-vc {
		-moz-align-items: center;
		-ms-align-items: center;
		-o-align-items: center;
		align-items: center;
	}
	.title-icon {
		width: .09rem;
		height: .14rem;
		display: flex;
		margin-left: .12rem;
		margin-right: .07rem;
	}
	.video-box .text1, .video-box .text2 {
		color: #1e2c5c;
		overflow: hidden;
		text-overflow: ellipsis;
		transition: all .3s;
		-webkit-transition: all .3s;
	}
	.video-box .text1 {
		flex: 1;
		margin-right: .12rem;
		font-size: .15rem;
		font-weight: 700;
		line-height: .39rem;
		white-space: nowrap;
	}
	.video-box .text2 {
		font-size: 12px;
		font-weight: 400;
		line-height: .21rem;
		width: 1.71rem;
		margin-left: .27rem;
		margin-top: .1rem;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.video-box:hover .video-text{
		background-color: #1e2c5c;
	}
	.video-box:hover .video-text .text1,	.video-box:hover .video-text .text2{
		color: #ffffff;
	}
	.data-more{
		cursor: pointer;
		height: .24rem;
		margin-top: .11rem;
		align-items: center;
		justify-content: center;
		display: flex;
		transition: all .3s;
	}
	.data-more span{
		font-size: .16rem;
		font-weight: 500;
		color: #d7a16f;
		line-height: .24rem;
		margin-right: .05rem;
		
	}
	.down-img{
		
		transform: rotate(180deg);
	}
	.tvdown{
		transition: all .9s;
		transform: rotate(0);
	}
	.tvcandown{
		transition: all .9s;
		transform: rotate(0);
	}
	.data-more .tvrote{
		transition: all .9s;
		transform: rotate(180deg);
	}
</style>
