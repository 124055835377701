<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/aboutCMbg.8d6047f.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<p class="title">{{lan?'校园图片参观':''}} <br> VIRTUAL CAMPUS TOUR</p>
						<div class="img-box">
							<div :class="img.attributes.isShow==0 ? 'miss':''" class="hiddenImg" v-for="(img,i) in imageList" :key="i">
								<figure @click="showImagePreview(BaseURL+img.attributes.image.data.attributes.url)" tabindex="0" class=" visitCampus-c4" :class="img.attributes.flag==3 ?  'visitCampus-img':''||img.attributes.flag==1 ? 'visitCampus-img1 ':''|| img.attributes.flag==2 ? 'visitCampus-img2 ':''"  >
									
									<!-- <img  :src="img.attributes.image.data.attributes.url" alt=""> -->
									<img  :src="img.attributes.image.data!=null ? BaseURL+img.attributes.image.data.attributes.url : ''" alt="">
									<figcaption>
										<div class="c4-reveal-up">
											<p class="c4-reveal-up-text">{{img.attributes.title}}</p>
										</div>
									</figcaption>
								</figure>
							</div>

						</div>
						<div class="data-more" @click="showYouMore" v-if="moreShow">
							<span>{{lan?'查看更多':'View more'}}</span>
							<img src="../../../public/static/img/查看更多.png" alt="">
						</div>
						<div class="data-more" @click="showYouMore" v-if="!moreShow">
							<span>{{lan?'收起':'Retract'}}</span>
							<img class="down-img" src="../../../public/static/img/查看更多.png" alt="">
						</div>
						<p class="title2">{{lan?'校园视频观看':'Campus Videos'}}</p>
						<div class="video-list">
							<div class="video-look" @click="comeUrl(video.attributes.url,video.attributes.fullUrl)" :key="i"  v-for="(video,i) in videoList">
								<div v-if="video.emptyList">
								</div>
								<div class="video-box" v-else-if="showList(i)">
									<div class="video-img">
										<!-- 1 -->
										<img class="img1" :src="video.attributes.image.data!=null ? BaseURL+video.attributes.image.data.attributes.url :'' " alt="">
										<div class="player-bg">
											
										</div>
										<img class="icon-player" src="../../../public/static/img/icon-player.png" alt="">
									</div>
									<div class="video-text">
										<div class="line">
											<img class="title-icon" src="../../../public/static/img/视频主题icon.png" alt="">
											<!-- 3 -->
											<p class="text1">{{video.attributes.title}}</p>
											
										</div>
										<p class="text2">{{video.attributes.description}}</p>
									</div>
								</div>
							</div>
						</div>
						<div class="data-more" @click="showVideoMore" v-if="moreVideoShow">
							<span>{{lan?'查看更多':'View more'}}</span>
							<img src="../../../public/static/img/查看更多.png" alt="">
						</div>
						<div class="data-more" @click="showVideoMore" v-if="!moreVideoShow">
							<span>{{lan?'收起':'Retract'}}</span>
							<img class="down-img" src="../../../public/static/img/查看更多.png" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<foot></foot>
		
	</div>
</template>

<script>
	import {  getCampusImage,getCampusVideo,getCampusVideoCN,getCampusImageCN } from "../../api/api.js"
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'visitCampus',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				moreShow:true,
				showMore:false,
				detailShow:false,
				imageList:[],
				videoList:[],
				BaseURL:process.env.VUE_APP_BASEURL,	
				videoShow:false,
				moreVideoShow:true,
				tip:1,
				tipNumber:2,
        lan: 1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
				this.getImage()
				this.getVideo()
        this.lan = 0
			}else if(language == 'en'&&newLanguage=='cn'){
				this.getImageCN()
				this.getVideoCN()
        this.lan = 1
			}
			
			this.addAnimation()
			this.hiddenVideo()
		},
		methods:{
			getImage(){
				getCampusImage().then(res=>{
	
					this.imageList = res.data.data
					// console.log(this.imageList[0].attributes.image.data.attributes.url,'da')
				})
			},
			getImageCN(){
				getCampusImageCN().then(res=>{
				
					this.imageList = res.data.data
					// console.log(this.imageList[0].attributes.image.data.attributes.url,'da')
				})
			},
			comeUrl(e,f){
				// debugger
				// if (f == '' || f == null) {
				// 	this.$router.push("/life/TVPlayerNew?video="+e)
				// } else {
				// 	window.location.href = f
				// }
				if (e) {
					this.$router.push("/life/TVPlayerNew?video="+e)
				} else if(f) {
					window.location.href = f
				}
				
			},
			getVideo(){
				getCampusVideo().then(res=>{
					// console.log(res.data.data)
					this.videoList = res.data.data
					// this.videoList = [...res.data.data, ...res.data.data, ...res.data.data]
					let len = this.videoList.length % 4
					if(len){
						for (let index = 0; index < 4-len; index++) {
							this.videoList.push({emptyList: true})
						}
					}
					// console.log(this.videoList, 'video list')
				})
			},
			getVideoCN(){
				getCampusVideoCN().then(res=>{
					// console.log(res.data.data)
					this.videoList = res.data.data
					let len = this.videoList.length % 4
					if(len){
						for (let index = 0; index < 4-len; index++) {
							this.videoList.push({emptyList: true})
						}
					}
					// console.log(this.videoList, 'video list')
				})
			},
			comeMyself(text){
				this.$router.push(text);
			},
			
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			showList(index){
				if(this.moreVideoShow) {
					if (index>3) return false
					return true
				} else {
					return true
				}
			},	
			showImagePreview(img){
				console.log(img)
				const image = new Image();
				image.src = img;
				image.onload = () => {
					// 创建弹出层
				const previewContainer = document.createElement('div');
				previewContainer.style.position = 'fixed';
				const scroll1 = document.documentElement.scrollTop
				// previewContainer.style.top = scroll1 + 'px';
				previewContainer.style.top = 0;
				previewContainer.style.zIndex=10000;
				previewContainer.style.bottom = 0;
				previewContainer.style.left = 0;
				previewContainer.style.right = 0;
				const trans = document.getElementsByTagName('body')[0].style.transform
				const sp = trans.split('(')[1]
				const scale = sp.split(')')[0]
				// console.log(scale)
				// const height = document.getElementsByTagName('html')[0].clientHeight
				const height = window.innerHeight / scale
				const width = window.innerWidth
				// console.log('hehehe',document.getElementsByTagName('html')[0].clientHeight)
				// console.log(height)
				previewContainer.style.height = height+ 'px';
				// previewContainer.style.width = width + 'px'
				previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)';
				previewContainer.style.display = 'flex';
				previewContainer.style.justifyContent = 'center';
				previewContainer.style.alignItems = 'center';
				document.body.appendChild(previewContainer);
				// 在弹出层中添加图片
				const previewImage = document.createElement('img');
				previewImage.src =img;
				previewImage.style.maxWidth = '50%';
				previewImage.style.maxHeight = '50%';
				previewImage.style.position = 'absolute';
				let scroll = (document.documentElement.scrollTop+100) 
				console.log(scroll)
				previewImage.style.top = '30%';
				previewContainer.appendChild(previewImage);
				let m = function(e){e.preventDefault();};
				document.body.style.overflow='hidden';
				document.addEventListener("touchmove",m,{ passive:false });
				// 点击弹出层，关闭预览
				previewContainer.addEventListener('click', () => {
					document.body.removeChild(previewContainer);
					let m =function(e){e.preventDefault();};
					document.body.style.overflow='';//出现滚动条
					document.removeEventListener("touchmove",m,{ passive:true });
				});
				};
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
					let osTop =
							document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
							osTop + ispeed;
					this.isTop = true;
					if (osTop === 0) {
							clearInterval(timer);
					}
			}, 30);         
			},
			addAnimation(){
				var lis = document.querySelectorAll(".visitCampus-c4");
				// var list = document.querySelector(".visitCampus-c4").querySelectorAll(".c4-reveal-up-text");
				// console.log(lis)
				lis.forEach(item => {
				    item.onmouseover = () => {
				       //先全部清空选中，再给当前添加选中
							 // console.log(item,'dasdhoa')
							item.children[1].children[0].children[0].classList.remove('c4-reveal-up-text1')
				      item.children[1].children[0].children[0].classList.add('c4-reveal-up-text');
				    }
				})
				lis.forEach(item => {
				    item.onmouseleave = () => {
				       //先全部清空选中，再给当前添加选中
				       item.children[1].children[0].children[0].classList.remove('c4-reveal-up-text')
								// console.log(item.children[1].children[0].children)
				       item.children[1].children[0].children[0].classList.add('c4-reveal-up-text1');
				    }
				})
			},
			
			showYouMore(){
				
				if(this.moreShow){
					this.moreShow = false
					this.showMore = true
					var lis = document.querySelectorAll(".hiddenImg");
					for(var i = 0;i<=lis.length-1;i++){
						if(i>4){
							lis[i].classList.remove('miss')
						}
					}
					
				}else{
					this.moreShow = true
					this.showMore = false
					var lis = document.querySelectorAll(".hiddenImg");
					for(var i = 0;i<=lis.length-1;i++){
						if(i>4){
							lis[i].classList.add('miss')
						}
					}
				}
			},
			hiddenVideo(){
				var lis = document.querySelectorAll(".video-look");
				// console.log(lis[1])
				for(var i = 0;i<=lis.length-1;i++){
					if(i>3){
						lis[i].classList.add('miss')
					}
				}
			},
			showVideoMore(){
				if(this.moreVideoShow){
					this.moreVideoShow = false
					// var lis = document.querySelectorAll(".video-look");
					// // console.log(lis[1])
					// for(var i = 0;i<=lis.length-1;i++){
					// 	if(i>3){
					// 		lis[i].classList.remove('miss')
					// 	}
					// }
					// this.videoShow = true
				}else{
					this.moreVideoShow = true
					// var lis = document.querySelectorAll(".video-look");
					// // console.log(lis[1])
					// for(var i = 0;i<=lis.length-1;i++){
					// 	if(i>3){
					// 		lis[i].classList.add('miss')
					// 	}
					// }
				}
			}
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .5rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.title{
		font-size: .4rem;
		line-height: .4rem;
		margin-bottom: .54rem;
		font-weight: 700;
		color: #1e2c5c;
	}
	.img-box{
		flex-wrap: wrap;
		justify-content: space-between;
		display: flex;
	}
	.visitCampus-img{
		width: 9.56rem;
		height: 2.98rem;
		margin-bottom: .16rem;
		background-color: rgba(30,44,92,.7);
		cursor: pointer;
	}
	.visitCampus-img1{
		width: 5.58rem;
		height: 2.98rem;
		margin-bottom: .16rem;
		background-color: rgba(30,44,92,.7);
		cursor: pointer;
	}
	.visitCampus-img2{
		width: 3.82rem;
		height: 2.98rem;
		margin-bottom: .16rem;
		background-color: rgba(30,44,92,.7);
		cursor: pointer;
	}
	.visitCampus-c4{
		--text-color: #fff;
		--primary-color: #00b4db;
		--secondary-color: #0083b0;
		--padding: 1em;
		--transition-duration: 600ms;
		--border-margin: 15px;
		--border-width: 3px;
		--border-color: #fff;
		--border-radius: 3px;
		--image-opacity: 0.25;
		display: inline-flex;
		position: relative;
		box-sizing: border-box;
		overflow: hidden;
		margin: 0;
		padding: 0;
		color: #fff;
		color: var(--text-color);
		border-radius: 3px;
		border-radius: var(--border-radius);
	}
	.visitCampus-c4 img{
		object-fit: cover;
		max-width: 100%;
		width: 100%;
		height: 100%;
	}
	.visitCampus-c4 figcaption{
		/* padding: calc(var(--padding)*2); */
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 0.5em;
		/* padding: calc(var(--padding)); */
		/* width: 100%; */
		z-index: 1;
	}
	.visitCampus-c4:hover figcaption{
		background-color: rgb(122,129,157,.7);
		transition: 1s;
	}
	.c4-reveal-up-text{
		margin-top: 1rem;
		font-size: .25rem;
		font-weight: 700;
		color: #fff;
		opacity: 0;
		text-align: center;
		transition: all 1s;
	}
	.c4-reveal-up-text1{
		margin-top: 1rem;
		font-size: .25rem;
		font-weight: 700;
		color: #fff;
		opacity: 0;
		text-align: center;
		animation: smallbag 0.5s linear forwards;
	}
	.visitCampus-c4:hover .c4-reveal-up-text{
		animation: bigbag 0.5s linear forwards;
	}
	.data-more{
		cursor: pointer;
		height: .24rem;
		margin-top: .11rem;
		align-items: center;
		justify-content: center;
		display: flex;
	}
	.data-more span{
		font-size: .16rem;
		font-weight: 500;
		color: #d7a16f;
		line-height: .24rem;
		margin-right: .05rem;
		
	}
	
	.down-img{
		transform: rotate(180deg);
	}
	.title2{
		font-size: .4rem;
		line-height: .4rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-top: .58rem;
		margin-bottom: .42rem;
	}
	.video-list{
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		flex-wrap: wrap;
		justify-content: space-between;
		display: flex;
	}
	.video-look {
    width: 2.26rem;
	}
	.video-box{
		width: 2.26rem;
		height: 2.32rem;
		overflow: hidden;
		border-radius: 0 0 .2rem 0;
		cursor: pointer;
		margin-bottom: .2rem;
		/* margin-right: .2rem; */
	}
	.video-img{
		position: relative;
	}
	.video-img .img1{
		display: flex;
	}
	.player-bg{
		position: absolute;
		left: 0;
		top: 0;
		width: 2.26rem;
		height: 1.34rem;
		background-color: rgba(0,0,0,.6);
		z-index: 2;
	}
	.icon-player{
		position: absolute;
		left: 0;
		top: 0;
		width: .54rem;
		height: .54rem;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 3;
	}
	.video-img,.video-img .img1{
		width: 2.26rem;
		height: 1.34rem;
	}
	.video-text{
		width: 100%;
		height: .98rem;
		transition: all .3s;
		-webkit-transition: all .3s;
	}
	.line{
		height: .39rem;
		border-bottom: 1px solid #bfbfbf;
		align-items: center;
		display: flex;
	}
	.title-icon{
		width: .09rem;
		height: .14rem;
		display: flex;
		margin-left: .12rem;
		margin-right: .07rem;
	}
	.video-box .text1,.video-box .text2{
		color: #1e2c5c;
		overflow: hidden;
		text-overflow: ellipsis;
		transition: all .3s;
		-webkit-transition: all .3s;
	}
	.miss{
		display: none !important;
	}
	.video-box .text1{
		flex: 1;
		margin-right: .12rem;
		font-size: .15rem;
		font-weight: 700;
		line-height: .39rem;
		white-space: nowrap;
	}
	.video-box .text2{
		font-size: 12px;
		font-weight: 400;
		line-height: .21rem;
		width: 1.71rem;
		margin-left: .27rem;
		margin-top: .1rem;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.video-box:hover .video-text{
		background-color: #1e2c5c;
	}
	.video-box:hover .text1{
		color: #ffffff;
	}
	.video-box:hover .text2{
		color: #ffffff;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	@keyframes bigbag {
	  0% {
			opacity: 0.1;
	    margin-top: 0.8rem;
	  }
		10%{
			opacity: 0.2;
			margin-top: 0.8rem;
		}
		20%{
			opacity: 0.3;
			margin-top: 0.8rem;
		}
		30%{
			opacity: 0.4;
			margin-top: 0.7rem;
		}
	  40% {
			opacity: 0.5;
	    margin-top: 0.6rem;
	  }
		50%{
			opacity: 0.6;
			margin-top: 0.5rem;
		}
		60%{
			opacity: 0.7;
			margin-top: 0.4rem;
		}
		70%{
			opacity: 0.8;
			margin-top: 0.3rem;
		}
		80%{
			opacity: 0.9;
			margin-top: 0.2rem;
		}
		90%{
			opacity: 1;
			margin-top: 0.1rem;
		}
	  100% {
			opacity: 1;
			margin-top: 0rem;
	  }
	}
	@keyframes smallbag {
	  0% {
			opacity: 1;
	    margin-top: 0rem;
	  }
		10%{
			opacity: 0.9;
			margin-top: 0.1rem;
		}
	  20% {
			opacity: 0.8;
	    margin-top: 0.2rem;
	  }
		30%{
			opacity: 0.7;
			margin-top: 0.3rem;
		}
		40%{
			opacity: 0.6;
			margin-top: 0.4rem;
		}
	  50% {
			opacity: 0.5;
			margin-top: 0.5rem;
	  }
		60%{
			opacity: 0.4;
			margin-top: 0.6rem;
		}
		70%{
			opacity: 0.3;
			margin-top: 0.7rem;
		}
		80%{
			opacity: 0.2;
			margin-top: 0.8rem;
		}
		90%{
			opacity: 0.1;
			margin-top: 0.8rem;
		}
		100%{
			opacity: 0;
			margin-top: 0.8rem;
		}
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
</style>
