<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<headerLan></headerLan>
					<div class="header-list">
						<headerList  :tip="tip"></headerList>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/aboutCMbg.8d6047f.png" alt="">
			<div class="event">
				<leftNav :tip="tip" :tipNumber="tipNumber"></leftNav>
				<div class="mid">
					<div class="content">
						<p class="title1">{{lan?'国际一流的教师团队':'SISA structure and management team'}} <br> A world-class teaching team</p>
						<div class="teamTop-img">
							<img class="teamTop-img" src="../../../public/static/img/teamTop.png" alt="">	
						</div>
						<div class="topImg-title">
							{{lan?'“辰美致力于打造世界一流水准的百年艺术名校，名校的标准，首先要有大师。”':'"sisa is committed to building a world-class century old art school, and the standard for a prestigious school is to have a master first"'}}
						</div>
						<div class="context">
							<p class="words">
								{{lan?'辰美拥有一流的国际化师资团队，师生比例1:8，教师队伍由国内外著名教育专家、名校长、资深骨干教师和优秀外籍教师、名校海归教师组成。超过50%的艺术与音乐专业教师毕业于海外名校。 ':'SISA has a first-class international teaching team with a student-teacher ratio of 1:8. The teaching team comprises of top quality educators, principals, senior teachers, foreign teachers and local teachers. More than 50% of art and music teachers graduated from reputable overseas universities.'}}
								<br>
								<br>
								Stellart benefits from a team of educators who are passionate for the arts, business and education. They are driven by the enthusiasm and commitment in teaching, and strive to deliver high standard education to our students.
							</p>
							<p class="title2">{{lan?'辰美组建的全球一流师资团队包括:':'Stellart’s world-class teaching team includes:'}}</p>
							<p class="words">
								{{lan?'全球一流的大师顾问团队;':'Top world-class advisory panel;'}}
								<br>
								{{lan?'全国一流的名校长专家团队;':'Top national principals and expert consultants;'}}
								<br>
								{{lan?'国际一流的学术校长和中方执行校长管理团队;':'Top world-class academic principals and senior management team;'}}
								<br>
							</p>
							<p class="title2">
								{{lan?'优秀的海归中教：':'Excellent Chinese returnee teachers:'}}
							</p>
							<p class="words">{{lan?'毕业于海外顶级艺术类院校和综合类院校，中西合璧，双语教学，更能为学生传授如何更好适应海外学习生活的妙招。':'Graduated from top overseas art academies and colleges, combining the best of Chinese and the West. Bilingual instruction, practical tips to share on adaptation to overseas studies.'}}</p>
							<p class="title2">{{lan?'经验丰富的中籍教师：':'Experienced Chinese local teachers:'}}</p>
							<p class="words">{{lan?'毕业于国内顶尖师范类院校，有多年的教学和管理经验，基础扎实，在培养学生中国文化自信的同时，能带领学生在国内外考试中取得优异成绩。':`Graduated from the most elite normal universities in China, with many years of experience in teaching and administration. The teachers have a rock-solid foundation, leading students to be culturally confident in their Chinese heritage, and also achieve great results in domestic and overseas examinations.`}}</p>
						</div>
						<!-- <div v-html="teamText"></div> -->
						<div class="teams-wrap">
							<p class="title3">
							{{lan?'	辰美管理团队介绍':``}}
								<br>
								Introduction of Management Team 
							</p>
							<div class="flex ">
								<div :class="team.attributes.isShow==0 ? 'miss':''" class="img-box teams-look" v-for="(team,i) in teamList" :key="i">
									<div class="img-item" @click="showYouDetail(i)">
										<figure class="c4-izmir">
											<div class="c4-mask">
												
											</div>
											<img class="people-img" :src="team.attributes.image.data!=null ? BaseURL+team.attributes.image.data.attributes.url : ''" alt="">
											<div class="img-info">
												<p class="name">{{team.attributes.name}}</p>
												<p :class="classCN==1 ? 'job1':'job'">{{team.attributes.position}}</p>
												<div class="info-flex" style="margin-top: 0.15rem;">
													<img class="imgHover-img" src="../../../public/static/img/icon-details.png" alt="">
													<p class="imgHover-p">{{lan?'点击查看详情':'Click to view details'}}</p>
												</div>							
											</div>
											<figcaption>
												<div class="c4-reveal-up">
													<div class="name-d">{{team.attributes.name}}</div>
													<div class="job-d">{{team.attributes.position}}</div>
												</div>
											</figcaption>
										</figure>
									</div>
									
								</div>
							</div>
							<div class="show-m">
								<div class="data-more" @click="showVideoMore" v-if="moreVideoShow">
									<span>{{lan?'查看更多':'View more'}}</span>
									<img src="../../../public/static/img/查看更多.png" alt="">
								</div>
								<div class="data-more" @click="showVideoMore" v-if="!moreVideoShow">
									<span>{{lan?'收起':'Retract'}}</span>
									<img class="down-img" src="../../../public/static/img/查看更多.png" alt="">
								</div>
							</div>
						</div>
					
					</div>
					
					
				</div>
			</div>
			
			<div v-if="showDetailBtn">
				<div class="details-bg">
					<div class="vtc">
						<div class="details-box1">
							<img :src="teamDetail.attributes.image.data!=null ? BaseURL+teamDetail.attributes.image.data.attributes.url : ''" class="img1" alt="">
							<div class="text1 flex flex-vc">
								<p>{{teamDetail.attributes.name}}</p>
								<div class="shu">
									
								</div>
								<p>{{teamDetail.attributes.position}}</p>

							</div>
							<p class="text2">{{teamDetail.attributes.detail_title}}</p>
							<div class="line-s">
								
							</div>
							<p class="text3">		
								{{teamDetail.attributes.detail_description}}
							</p>
							<img src="../../../public/static/img/architectureAndTeam-img.jpg" class="details-bottom" alt="">
							<img src="../../../public/static/img/icon-close.png" class="icon-close" @click="hiddenDetailBtn" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import {  getArchitectureTeam,getArchitectureTeamCN, getTeamTeachCN, getTeamTeach } from "../../api/api.js"
	import headerList from '../commponet/headerList.vue'
	import headerLan from '../commponet/headerLan.vue'
	import leftNav from '../commponet/leftNav.vue'
	import foot from '../commponet/foot.vue'
	export default{
		name:'introduction',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				showId:0,
				showDetailBtn:false,
				moreVideoShow:true,
				teamList:[
				],
				teamDetail:[],
				BaseURL:process.env.VUE_APP_BASEURL,
				tip:1,
				tipNumber:3,
				classCN:0,
				teamText: '',
				teamBack: {},
        lan: 1
			}
		},
		components: {
		  headerList,
			headerLan,
			leftNav,
      foot
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
			const language =  localStorage.getItem('language') || 'en'
			const newLanguage =  localStorage.getItem('NewLanguage') 
			if(language=='en'&&newLanguage==null){
        this.lan = 0
				this.getTeam()
				this.getTeamTeach()
				this.classCN = 0
			}else if(language == 'en'&&newLanguage=='cn'){
        this.lan = 1
				this.getTeamCN()
				this.getTeamTeachCN()
				this.classCN = 1
			}
			
		},
		methods:{
			getTeam(){
				getArchitectureTeam().then(res=>{
					
					this.teamList = res.data.data
					console.log(this.imageList,'da')
				})
			},
			getTeamCN(){
				getArchitectureTeamCN().then(res=>{
					
					this.teamList = res.data.data
					console.log(this.imageList,'da')
				})
			},
			getTeamTeach(){
				getTeamTeach().then(res=>{
					
					this.teamBack = res.data.data
					console.log(this.teamText,'da')
				})
			},
			getTeamTeachCN(){
				getTeamTeachCN().then(res=>{
					this.teamBack = res.data.data
					if(this.teamBack.length>0){
						this.teamText = this.teamBack[0].attributes.content

					}
					console.log(this.teamText,'da')
				})
			},
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeIndex(){
				this.$router.push("/");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showYouDetail(id){
				 const showId = id
				console.log(this.teamList)
				this.teamDetail = this.teamList[showId]
				console.log(this.teamDetail)
				this.showDetailBtn = true
			},
			hiddenDetailBtn(){
				this.showDetailBtn = false
			},
			
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},

			showVideoMore(){
				if(this.moreVideoShow){
					this.moreVideoShow = false
					var lis = document.querySelectorAll(".img-box");
					console.log(lis[1])
					for(var i = 0;i<=lis.length-1;i++){
						if(i>7){
							lis[i].classList.remove('miss')
						}
					}
					// this.videoShow = true
				}else{
					this.moreVideoShow = true
					var lis = document.querySelectorAll(".img-box");
					console.log(lis[1])
					for(var i = 0;i<=lis.length-1;i++){
						if(i>7){
							lis[i].classList.add('miss')
						}
					}
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}

	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		/* width: 1.37rem; */
		top: .70rem;
		/* left: .5rem; */
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		/* width: 1.99rem; */
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.title1{
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .46rem;
	}
	.teamTop-img{
		width: 9.56rem;
		height: 3.25rem;
		max-height: 3.25rem;
		overflow: hidden;
	}
	.topImg-title{
		font-size: .18rem;
		line-height: .36rem;
		font-weight: 400;
		color: #000;
		margin-top: .31rem;
		text-align: center;
		font-style: italic;
	}
	.context{
		margin-top: .59rem;
		margin-bottom: .98rem;
	}
	.context .words{
		font-size: .18rem;
		line-height: .36rem;
		font-weight: 400;
		color: #3d4460;
		margin-bottom: .4rem;
	}
	.title2{
		font-size: .22rem;
		line-height: .36rem;
		font-weight: 700;
		color: #0e183f;
	}
	.title3{
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .59rem;
	}
	.img-box{
		float: left;
		margin-right: .4rem;
	}
	.img-item{
		margin-bottom: .52rem;
		position: relative;
	}
	.c4-izmir{
		--text-color: #fff;
		--primary-color: #00b4db;
		--secondary-color: #0083b0;
		--padding: 1em;
		--transition-duration: 600ms;
		--border-margin: 15px;
		--border-width: 3px;
		--border-color: #fff;
		--border-radius: 3px;
		--image-opacity: 0.25;
		display: inline-flex;
		position: relative;
		box-sizing: border-box;
		overflow: hidden;
		margin: 0;
		padding: 0;
		color: #fff;
		color: var(--text-color);
		border-radius: 3px;
		border-radius: var(--border-radius);
		cursor: pointer;
		background-color: #fff;
		--image-opacity: 0;
		--padding: unset;
		transition: all .6s;
	}
	.c4-izmir img{
		-o-object-fit: cover;
		object-fit: cover;
		max-width: 100%;
		width: 100%;
		height: 100%;
	}
	
	.people-img{
		width: 1.96rem !important;
		height: 2.54rem !important;
	}
	.img-item .img-info{
		position: absolute;
		bottom: 0;
		z-index: 9;
		width: 1.962rem;
		height: .58rem;
		left: 0;
		background-color: #1e2c5c;
		flex-direction: column;
		align-items: center;
		display: flex;
	}
	.img-item .img-info .name{
		font-size: .19rem;
		font-weight: 500;
		line-height: .2rem;
		color: #fff;
		margin-top: .07rem;
	}
	.img-item .img-info .job{
		font-size: .14rem;
		font-weight: 400;
		line-height: .15rem;
		color: #fff;
		margin-bottom: 0.1rem;
		margin-top: .08rem;
		white-space: nowrap;
		width: 90%;
		text-overflow: ellipsis;
		/* overflow: hidden; */
	}
	.img-item .img-info .job1{
		font-size: .14rem;
		font-weight: 400;
		line-height: .15rem;
		color: #fff;
		margin-top: .08rem;
		/* overflow: hidden; */
	}
	.img-item:hover .name{
		display: none;
	}
	.img-item:hover .job{
		display: none;
	}
	.img-item:hover .imgHover-img{
		display: block;
		left: 0;
	}
	.img-item:hover .imgHover-p{
		display: block;
		left: 0;
	}
	.info-flex{
		align-items: center;
		display: flex;
		justify-content: center;
	}
	.imgHover-img{
		width: .13rem !important;
		height: .14rem !important;
		/* display: none !important; */
		margin-right: 6px !important; 
	}
	.imgHover-p{
		font-size: .14rem;
		font-weight: 300;
		color: #fff;
		line-height: .3rem;
		opacity: .8;
		display: none;
	}
	.img-item:hover .c4-mask{
		opacity: 1;
	}
	.c4-mask{
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #1e2c5c;
		opacity: 0;
		transition: .5s;
	}
	.c4-izmir figcaption{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 2em;
		padding: calc(var(--padding)*2);
		width: 100%;
		z-index: 1;
	}
	.img-item:hover .name-d{
		margin-top: 0rem;
		
	}
	.img-item:hover .c4-reveal-up{
		opacity: 1;
	}
	.c4-reveal-up{
		position: relative;
		overflow: hidden;
		height: .8rem;
		opacity: 0;
		transition: 1s;
	}
	.job-d , .name-d{
		
		color: #fff;
		line-height: .38rem;
	}
	.name-d{
		margin-top: 1rem;
		font-size: .22rem;
		font-weight: 500;
		transition: 1s;
	}
	.job-d{

		font-size: .16rem;
		font-weight: 400;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.vtc {
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 7.85rem;
		height: 5.42rem;
	}
	.details-box1{
		background: #fff;
		border: 0;
		position: relative;
		width: 7.85rem;
		height: 5.42rem;
		margin: auto;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	.details-box1 .img1{
		width: 1.58rem;
		height: auto;
		position: absolute;
		left: 0;
		top: 0;
	}
	.details-box1 .text1{
		display: flex;
		align-items: center;
		font-size: .3rem;
		font-weight: 500;
		color: #1e2c5c;
		line-height: .3rem;
		position: absolute;
		top: .58rem;
		left: 2.13rem;
	}
	.details-box1 .shu{
		width: 1px;
		height: .2rem;
		background-color: rgba(0,0,0,.5);
		margin: 0 .18rem;
	}
	.details-box1 .text2{
		font-size: .16rem;
		font-weight: 300;
		color: #000;
		line-height: .25rem;
		opacity: .8;
		position: absolute;
		top: 1.15rem;
		left: 2.13rem;
		width: 5.2rem;
		text-align: left;
	}
	.details-box1 .line-s {
		width: 35px;
		height: 1px;
		background-color: rgba(0,0,0,.5);
		position: absolute;
		top: 2.19rem;
		left: 2.13rem;
	}
	.details-box1 .text3 {
		font-size: .18rem;
		font-weight: 400;
		color: #000;
		line-height: .36rem;
		opacity: .8;
		text-align: justify;
		position: absolute;
		top: 2.75rem;
		left: 2.13rem;
		width: 5.2rem;
		height: 1.8rem;
		overflow-y: auto;
	}
	.details-bottom{
		width: 100%;
		height: auto;
		bottom: 0;
		left: 0;
		position: absolute;
	}
	.icon-close{
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		bottom: -1.1rem;
		top: 5.5rem;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.miss{
		display: none !important;
	}
	.data-more{
		cursor: pointer;
		height: .24rem;
		margin-top: .11rem;
		align-items: center;
		justify-content: center;
		/* flex-direction: column; */
		display: flex;
	}
	.data-more span{
		/* width: 2rem; */
		font-size: .16rem;
		font-weight: 500;
		color: #d7a16f;
		line-height: .24rem;
		margin-right: .05rem;
		
	}
	.data-more img {
		transition: all .3s;
		-webkit-transition: all .3s;
		width: .18rem;
		height: auto;
		float: left;
	}
	.flex{
		flex-wrap: wrap;
		justify-content: flex-start;
		/* margin-right: 2; */
		display: flex;
	}
	.down-img{
		transform: rotate(180deg);
	}
/* 	.show-m{
		width: 90%;
		height: .1rem;
		display: block;
	} */
</style>
